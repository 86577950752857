import React, { Component } from "react";
import { Input, Segment, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./show.module.scss";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import Partial404 from "../errors/partials/404";
import Partial500 from "../errors/partials/500";
import Notification from "../../assets/components/notification";
import NotificationActions from "../../store/actions/notification";
import ValidationActions from "../../store/actions/validation";
import Currency from "../../models/Currency";
import CenterLoader from "../../assets/components/CenterLoader";

class CurrenciesShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            loadingCurrency: true,
            error: null,
            notification: null,
            currency: new Currency(),
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }
    }

    componentDidMount() {
        this.getCurrency();
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className="has-error">
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleNotifications() {
        if (this.props.notification !== null) {
            return (
                <Notification
                    type={this.props.notification.type}
                    message={this.props.notification.text}
                />
            );
        }
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    handleNewAdminValidationErrors(error) {
        if (error.response.status === 422) {
            this.setState({
                validationErrorsNewAdmin: error.response.data.errors,
            });
        }
    }

    getCurrency() {
        const { id } = this.props.match.params;
        Currency.getById(id)
            .then((currency) => {
                this.setState({ loadingCurrency: false, currency });
            })
            .catch((error) => {
                this.setState({ error: error.response.status });
                this.notification(
                    "error",
                    "Failed to load currency, please try again"
                );
            });
    }

    onChangeInput(event) {
        const currency = this.state.currency;
        currency[event.target.name] = event.target.value;
        this.setState({ currency });
    }

    save() {
        this.setState({ saving: true });
        this.state.currency
            .update()
            .then((response) => {
                this.setState({
                    saving: false,
                    currency: new Currency(response.data.data),
                });
                this.notification("success", "Currency saved");
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save currency");
                this.handleValidationErrors(error);
            });
    }

    loaded() {
        return this.state.loadingCurrency === false;
    }

    offerDetailsTab() {
        return (
            <div className={styles.mainSegment}>
                <h3>Details</h3>
                <p>Edit the currency.</p>
                <Segment vertical>
                    {this.errorsFor("name")}
                    <label className="with-input">
                        Name <b>*</b>
                    </label>
                    <Input
                        fluid
                        name="name"
                        value={this.state.currency.name || ""}
                        onChange={this.onChangeInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("symbol")}
                    <label className="with-input">
                        Symbol <b>*</b>
                    </label>
                    <Input
                        fluid
                        name="symbol"
                        value={this.state.currency.symbol || ""}
                        onChange={this.onChangeInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("icon")}
                    <label className="with-input">Icon name</label>
                    <Input
                        fluid
                        name="icon"
                        value={this.state.currency.icon || ""}
                        onChange={this.onChangeInput.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    <Button
                        size="large"
                        floated="right"
                        className="theme"
                        primary
                        onClick={this.save.bind(this)}
                        disabled={this.state.saving}
                        loading={this.state.saving}
                    >
                        Save
                    </Button>
                </Segment>
            </div>
        );
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        if (this.loaded() === false) {
            return <CenterLoader />;
        }

        return (
            <div className="container">
                <div className="row skinny">
                    <div className="col">
                        <Tabs
                            defaultActiveKey="details"
                            id="uncontrolled-tab-example"
                        >
                            <Tab eventKey="details" title="Details">
                                {this.offerDetailsTab()}
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.handleNotifications()}
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(CurrenciesShow);
