import React, { Component } from "react";
import {
  Input,
  Segment,
  Form,
  Checkbox,
  Select,
  TextArea,
  Modal,
  Label,
  Button,
  Icon,
} from "semantic-ui-react";
import Piece from "../../models/Piece";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./show.module.scss";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import Partial404 from "../errors/partials/404";
import Partial500 from "../errors/partials/500";
import Notification from "../../assets/components/notification";
import NotificationActions from "../../store/actions/notification";
import ValidationActions from "../../store/actions/validation";
import EventSeries from "../../models/EventSeries";
import HtmlEditor from "../../assets/components/HtmlEditor";
import CenterLoader from "../../assets/components/CenterLoader";
import ImageCropper from "../../assets/components/ImageCropper";
const config = require("../../config");

class EventsSeriesShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      loadingEventSeries: true,
      error: null,
      notification: null,
      eventSeries: new EventSeries(),
    };

    if (this.props.user.has("MASHIE_ADMIN") === false) {
      this.props.history.push("/members");
    }
  }

  componentDidMount() {
    this.getEventSeries();
  }

  componentWillUnmount() {
    this.props.dispatch(NotificationActions.remove());
  }

  errorsFor(field) {
    if (this.props.validation !== null) {
      if (typeof this.props.validation[field] !== "undefined") {
        return (
          <span className="has-error"> {this.props.validation[field][0]} </span>
        );
      }
    }
  }

  displayError() {
    if (this.state.error === 500) {
      return <Partial500 />;
    }

    if (this.state.error === 404) {
      return <Partial404 />;
    }
  }

  notification(type, text) {
    this.props.dispatch(NotificationActions.create({ type, text }));
  }

  handleNotifications() {
    if (this.props.notification !== null) {
      return (
        <Notification
          type={this.props.notification.type}
          message={this.props.notification.text}
        />
      );
    }
  }

  handleValidationErrors(error) {
    if (error.response.status === 422) {
      this.props.dispatch(ValidationActions.create(error.response.data.errors));
    }
  }

  handleNewAdminValidationErrors(error) {
    if (error.response.status === 422) {
      this.setState({ validationErrorsNewAdmin: error.response.data.errors });
    }
  }

  getEventSeries() {
    const { id } = this.props.match.params;
    EventSeries.getById(id)
      .then((eventSeries) => {
        this.setState({ loadingEventSeries: false, eventSeries });
      })
      .catch((error) => {
        this.setState({ error: error.response?.status });
        this.notification(
          "error",
          "Failed to load event series, please try again"
        );
      });
  }

  onChangeName(value) {
    const { eventSeries } = this.state;
    eventSeries.name = value;
    this.setState({ eventSeries });
  }

  onChangeIsHidden() {
    const { eventSeries } = this.state;
    eventSeries.isHidden = !eventSeries.isHidden;
    this.setState({ eventSeries });
  }

  save() {
    this.state.eventSeries
      .update()
      .then((response) => {
        this.notification("success", "Event series saved");
      })
      .catch((error) => {
        this.notification("error", "Failed to save event series");
        this.handleValidationErrors(error);
      });
  }

  delete() {
    const confirm = window.confirm(
      "Are you sure you want to remove this event series?"
    );
    if (confirm === true) {
      this.state.eventSeries
        .delete()
        .then((response) => {
          this.notification("success", "Partner offer deleted");
          this.props.history.push("/events/series");
        })
        .catch((error) => {
          console.log("error", error);
          this.notification("error", "Failed to delete partner offer");
        });
    }
  }

  changeImage(event) {
    const { target } = event;
    const { eventSeries } = this.state;
    const fileReader = new FileReader();

    if (typeof target.files[0] !== "undefined") {
      fileReader.readAsDataURL(target.files[0]);
      fileReader.onload = async () => {
        const response = await eventSeries.updateImage(fileReader.result);
        eventSeries.image = response.image;
        this.setState({ eventSeries });
        this.notification("success", "Image saved");
      };
    }
  }

  addImage() {
    return (
      <div className="row">
        <div className="col">
          <Segment vertical>
            <input
              type="file"
              id="newImageFileInput"
              className="hidden"
              onChange={this.changeImage.bind(this)}
            />
            <Button
              primary
              className="theme center"
              onClick={() =>
                document.getElementById("newImageFileInput").click()
              }
            >
              Select image
            </Button>
          </Segment>
        </div>
      </div>
    );
  }

  loaded() {
    return this.state.loadingEventSeries === false;
  }

  showImage() {
    const image =
      this.state.eventSeries.image || this.state.eventSeries.newImage;
    if (typeof image === "undefined") {
      return <p>Please select an image for this event series</p>;
    }

    return (
      <img
        className={styles.eventImagePreview}
        src={this.state.eventSeries.imageUrl}
      />
    );
  }

  offerDetailsTab() {
    return (
      <div className={styles.mainSegment}>
        <h3>Details</h3>
        <p>Edit the event series details.</p>
        <Segment vertical>
          {this.errorsFor("name")}
          <label className="with-input">
            Name <b>*</b>
          </label>
          <Input
            fluid
            name="name"
            value={this.state.eventSeries.name || ""}
            onChange={({ target }) => this.onChangeName(target.value)}
          />
        </Segment>
        <Segment vertical>
          <h2>Event series image</h2>
          {this.errorsFor("newImage")}
          {this.showImage()}
          {this.addImage()}
        </Segment>
        <Segment vertical>
          <label className="with-input">Hidden</label>
          <p>Checking this toggle will hide this series from the filter list</p>
          <Checkbox
            toggle
            checked={this.state.eventSeries.isHidden}
            onChange={this.onChangeIsHidden.bind(this)}
          />
        </Segment>
        <Segment vertical>
          <p>Deleted series are recoverable at a later date</p>
          <Button
            primary
            onClick={() => {
              this.delete();
            }}
            className="red"
          >
            Delete Series
          </Button>
        </Segment>
        <Segment vertical>
          <Button
            size="large"
            floated="right"
            className="theme"
            primary
            onClick={this.save.bind(this)}
            disabled={this.state.saving}
            loading={this.state.saving}
          >
            Save
          </Button>
        </Segment>
      </div>
    );
  }

  body() {
    if (this.state.error) {
      return this.displayError();
    }

    if (this.loaded() === false) {
      return <CenterLoader />;
    }

    return (
      <div className="container">
        <div className="row skinny">
          <div className="col">
            <h2>Edit event series</h2>
            <Tabs defaultActiveKey="details" id="uncontrolled-tab-example">
              <Tab eventKey="details" title="Details">
                {this.offerDetailsTab()}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="d-flex main">
          <Header history={this.props.history} />
          {this.handleNotifications()}
          {this.body()}
        </div>
        <Footer />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    notification: state.notification,
    validation: state.validation,
  };
}

export default connect(mapStateToProps)(EventsSeriesShow);
