import React, { Component } from 'react';
import { Input, Segment, Button } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import styles from './create.module.scss';
import Header from '../../assets/components/header';
import Footer from '../../assets/components/footer';
import Partial404 from '../errors/partials/404';
import Partial500 from '../errors/partials/500';
import Currency from '../../models/Currency';
import Notification from '../../assets/components/notification';
import NotificationActions from '../../store/actions/notification';
import ValidationActions from '../../store/actions/validation';
import CenterLoader from '../../assets/components/CenterLoader';
const config = require('../../config');

class CurrenciesCreate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			saving: false,
			error: null,
			currency: new Currency(),
		};

		if (this.props.user.has('MASHIE_ADMIN') === false) {
			this.props.history.push('/members');
		}
	}

	displayError() {
		if (this.state.error === 500) {
			return  <Partial500 />;
		}

		if (this.state.error === 404) {
			return  <Partial404 />;
		}
	}

	componentWillUnmount() {
		this.props.dispatch(NotificationActions.remove());
	}

	notification(type, text) {
		this.props.dispatch(NotificationActions.create({type, text}))
	}

	handleNotifications() {
		if (this.props.notification !== null) {
			return <Notification type={this.props.notification.type} message={this.props.notification.text} />
		}
	}

	handleValidationErrors(error) {
		if (error.response.status === 422) {
			this.props.dispatch(ValidationActions.create(error.response.data.errors))
		}
	}

	errorsFor(field) {
		if (this.props.validation !== null) {
			if (typeof this.props.validation[field] !== 'undefined') {
				return <span className="has-error"> { this.props.validation[field][0] } </span>;
			}
		}
	}

	onChangeInput(event) {
		const currency = this.state.currency;
		currency[event.target.name] = event.target.value;
		this.setState({currency});
	}

	store() {
		this.setState({saving: true});
		this.state.currency.store()
			.then(response => {
				this.notification('success', 'Currency saved');
				this.props.history.push('/currencies');
			})
			.catch(error => {
				this.setState({saving: false});
				this.notification('error', 'Failed to save currency');
				this.handleValidationErrors(error);
			});
	}

	currencyDetailsTab() {
		return (
			<div className={styles.mainSegment}>
				<h3>Details</h3>
				<p>Edit the currency.</p>
				<Segment vertical>
					{ this.errorsFor('name') }
					<label className="with-input">Name <b>*</b></label>
					<Input fluid name="name" value={ this.state.currency.name || '' } onChange={this.onChangeInput.bind(this)} />
				</Segment>
                <Segment vertical>
					{ this.errorsFor('symbol') }
					<label className="with-input">Symbol <b>*</b></label>
					<Input fluid name="symbol" value={ this.state.currency.symbol || '' } onChange={this.onChangeInput.bind(this)} />
				</Segment>
                <Segment vertical>
					{ this.errorsFor('icon') }
					<label className="with-input">Icon name</label>
					<Input fluid name="icon" value={ this.state.currency.icon || '' } onChange={this.onChangeInput.bind(this)} />
				</Segment>
			</div>
		);
	}

	body() {
        if (this.state.error) {
            return this.displayError();
        }

        return (
            <div className="container skinny">
                <div className="row skinny">
                    <div className="col">
                        <h2>Create currency</h2>
                        <Tabs defaultActiveKey="details">
                            <Tab eventKey="details" title="Details">
                                { this.currencyDetailsTab() }
                            </Tab>
                        </Tabs>
                        <Button size="large" floated="right" className="theme" primary onClick={this.store.bind(this)} disabled={this.state.saving} loading={this.state.saving}>
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        );
	}

	render() {
		return (
			<>
				<div className="d-flex main">
					<Header history={this.props.history}/>
					{ this.handleNotifications() }
					{ this.body() }
				</div>
				<Footer />
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
		notification: state.notification,
		validation: state.validation
	};
}

export default connect(mapStateToProps)(CurrenciesCreate);
