import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./show.module.scss";
import Header from "../../../assets/components/header";
import Footer from "../../../assets/components/footer";
import FourBallClubCourses from "../../../models/FourBallClubCourses";
import FourBallClubCourseGroups from "../../../models/FourBallClubCourseGroups";
import FourBallBooking from "../../../models/FourBallBooking";
import Partial404 from "../../errors/partials/404";
import Partial500 from "../../errors/partials/500";
import Notification from "../../../assets/components/notification";
import ClubCourseDetails from "../../../assets/components/fourBallClubCourseDetails";
import ClubCoursePrices from "../../../assets/components/fourBallClubCoursePrices";
import Role from "../../../models/Role";
import GolfCourse from "../../../models/GolfCourse";
import NotificationActions from "../../../store/actions/notification";
import ValidationActions from "../../../store/actions/validation";
import CenterLoader from "../../../assets/components/CenterLoader";
import Calendar from "../../../assets/components/fourBall/Calendar";

class Show extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            error: null,
            loadingRoles: true,
            loadingClubCourses: true,
            loadingClubCourseGroups: true,
            loadingClubCourse: true,
            roles: [],
            clubCourses: [],
            clubCourseGroups: [],
            clubCourse: new FourBallClubCourses(),
            calendarBookings: [],
        };
    }

    componentWillUnmount() {
        this.props.dispatch(ValidationActions.remove());
        this.props.dispatch(NotificationActions.remove());
    }

    componentDidMount() {
        this.getClubCourse();
        this.getRoles();
        this.getClubCourses();
        this.getClubCourseGroups();
        this.getCalendarBookings();
    }

    getClubCourse() {
        FourBallClubCourses.show(this.props.match.params.id)
            .then((clubCourse) => {
                this.setState({ clubCourse });
                this.setState({ loadingClubCourse: false });
                this.getRoles();
            })
            .catch((error) => {
                this.setState({
                    error: error.response.status,
                    loadingClubCourse: false,
                });
                this.notification(
                    "error",
                    "Failed to load club course group, please try again"
                );
            });
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleNotifications() {
        if (this.props.notification !== null) {
            return (
                <Notification
                    type={this.props.notification.type}
                    message={this.props.notification.text}
                />
            );
        }
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    getRoles() {
        if (this.state.loadingClubCourse === false) {
            Role.getRoles()
                .then((roles) => {
                    const exclude = [
                        "MASHIE_ADMIN",
                        "ENTITY_ADMIN",
                        "APP_USER",
                        "ENTITY_CONTACT",
                    ];
                    roles = roles.data.data.filter(
                        (role) => exclude.includes(role.key) === false
                    );
                    this.setState({ loadingRoles: false, roles: roles });
                })
                .catch((error) => {
                    this.setState({
                        loadingRoles: false,
                        error: error.response.status,
                    });
                    this.notification(
                        "error",
                        "Failed to user, please try again"
                    );
                });
        }
    }

    getClubCourses() {
        GolfCourse.list()
            .then((courses) => {
                this.setState({
                    loadingClubCourses: false,
                    clubCourses: courses,
                });
            })
            .catch((error) => {
                this.setState({
                    loadingClubCourses: false,
                    error: error.response.status,
                });
                this.notification("error", "Failed to load, please try again");
            });
    }

    getCalendarBookings(month = new Date().getMonth()) {
        FourBallBooking.getCalendarBookings({
            month,
            course: this.props.match.params.id,
        }).then((calendarBookings) => this.setState({ calendarBookings }));
    }

    getClubCourseGroups() {
        FourBallClubCourseGroups.list()
            .then((clubCourseGroups) => {
                this.setState({
                    loadingClubCourseGroups: false,
                    clubCourseGroups,
                });
            })
            .catch((error) => {
                this.setState({
                    loadingClubCourseGroups: false,
                    error: error.response.status,
                });
                this.notification("error", "Failed to load, please try again");
            });
    }

    displayError() {
        if (this.state.error === 404) {
            return <Partial404 />;
        }

        return <Partial500 />;
    }

    update(clubCourse) {
        this.setState({ clubCourse });
    }

    save() {
        this.setState({ saving: true });
        this.state.clubCourse
            .update()
            .then((response) => {
                this.notification("success", "Club course created");
                this.setState({ saving: false });
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to create club course");
                this.handleValidationErrors(error);
            });
    }

    loaded() {
        return (
            this.state.loadingRoles === false &&
            this.state.loadingClubCourses === false &&
            this.state.loadingClubCourseGroups === false &&
            this.state.loadingClubCourse === false
        );
    }

    detailsTab() {
        return (
            <div className={styles.mainSegment}>
                <h3>Club course details</h3>
                <p>Set the club courses' details</p>
                <ClubCourseDetails
                    roles={this.state.roles}
                    clubCourse={this.state.clubCourse}
                    clubCourses={this.state.clubCourses}
                    clubCourseGroups={this.state.clubCourseGroups}
                    update={this.update.bind(this)}
                    save={this.save.bind(this)}
                    saving={this.state.saving}
                />
            </div>
        );
    }

    pricesTab() {
        return (
            <div className={styles.mainSegment}>
                <h3>Club course FOURBALL pricing</h3>
                <p>
                    Set the prices each member type will have to pay to purchase
                    an additional fourball.
                </p>
                <p>
                    Setting the price to '0' will still show the club course to
                    this member type, but they will be unable to purchase
                    additional credits for it.
                </p>
                <p>
                    Any price above '0' will be available for purchase at that
                    rate.
                </p>
                <p>
                    If you want to hide this club course from the role type
                    entirely, instead, please add it to the "Membership type
                    block list" on the "Details" tab.
                </p>
                <p>
                    Setting the credit expiry dates to '0' will mean that
                    credits that are purchased will not expire.
                </p>
                <p>
                    Setting the credit expiry dates to anything greater than '0'
                    will mean that credits that are purchased will expire (n)
                    days after they are purchased.
                </p>
                <ClubCoursePrices
                    roles={this.state.roles}
                    update={this.update.bind(this)}
                    save={this.save.bind(this)}
                    saving={this.state.saving}
                    clubCourse={this.state.clubCourse}
                />
            </div>
        );
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        if (this.loaded() === false) {
            return <CenterLoader />;
        }

        return (
            <div className="container skinny">
                <h2>FOURBALL Club Course</h2>
                <div className="row">
                    <div className="col">
                        <Tabs
                            defaultActiveKey="details"
                            id="uncontrolled-tab-example"
                        >
                            <Tab eventKey="details" title="Details">
                                {this.detailsTab()}
                            </Tab>
                            <Tab eventKey="prices" title="FOURBALL prices">
                                {this.pricesTab()}
                            </Tab>
                            <Tab eventKey="calendar" title="Calendar">
                                <div className="pt-3">
                                    <Calendar
                                        bookings={this.state.calendarBookings}
                                        onChangeMonth={this.getCalendarBookings.bind(
                                            this
                                        )}
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.handleNotifications()}
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Show);
