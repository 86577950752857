import React, { Component } from "react";
import {
    Input,
    Segment,
    Select,
    Label,
    Dropdown,
    Button,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./show.module.scss";
import { Tabs, Tab } from "react-bootstrap";
import Header from "../../../assets/components/header";
import Footer from "../../../assets/components/footer";
import Partial404 from "../../errors/partials/404";
import Partial500 from "../../errors/partials/500";
import Notification from "../../../assets/components/notification";
import NotificationActions from "../../../store/actions/notification";
import ValidationActions from "../../../store/actions/validation";
import FourBallCredit from "../../../models/FourBallCredits";
import User from "../../../models/User";
import CenterLoader from "../../../assets/components/CenterLoader";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

class Show extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            loadingFourBallCredit: true,
            error: null,
            fourBallCredit: new FourBallCredit(),
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }

        this.statusColours = {
            additional: "#007ab5",
            included: "#ffc53b",
            unused: "#00b5ad",
            used: "#7d2525",
            paid: "#00b5ad",
            unpaid: "#7d2525",
            free: "#00b5ad",
            expired: "#007ab5",
        };
    }

    componentDidMount() {
        this.getFourBallCredit();
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className="has-error">
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleNotifications() {
        if (this.props.notification !== null) {
            return (
                <Notification
                    type={this.props.notification.type}
                    message={this.props.notification.text}
                />
            );
        }
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    getFourBallCredit() {
        const { id } = this.props.match.params;
        FourBallCredit.show(id)
            .then((fourBallCredit) => {
                fourBallCredit.user = new User(fourBallCredit.user);
                this.setState({ loadingFourBallCredit: false, fourBallCredit });
            })
            .catch((error) => {
                this.setState({
                    error: error.response.status,
                    loadingFourBallCredit: false,
                });
                this.notification(
                    "error",
                    "Failed to entity, please try again"
                );
            });
    }

    loaded() {
        return this.state.loadingFourBallCredit === false;
    }

    save() {
        this.setState({ saving: true });
        this.state.fourBallCredit
            .update()
            .then((_) => {
                this.setState({ saving: false });
                this.notification("success", "FOURBALL credit saved");
                this.props.dispatch(ValidationActions.remove());
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save FOURBALL credit");
                this.handleValidationErrors(error);
            });
    }

    clubContacts(club) {
        return club.contacts.map((contact, index) => {
            return (
                <div className={styles.contact} key={index}>
                    <p>
                        <b>Name:</b> {contact.name}
                    </p>
                    <p>
                        <b>Position:</b> {contact.position}
                    </p>
                    <p>
                        <b>email:</b> {contact.email}
                    </p>
                    <p>
                        <b>phone:</b> {contact.phone}
                    </p>
                </div>
            );
        });
    }

    onChangeExpiresAt(selectedDay) {
        const { fourBallCredit } = this.state;
        fourBallCredit.expiresAt = selectedDay;
        this.setState({ fourBallCredit });
    }

    detailsTab() {
        return (
            <>
                {this.showCreditDetails()}
                <Segment vertical className="text-right">
                    <Button
                        primary
                        className="theme"
                        loading={this.state.saving}
                        onClick={this.save.bind(this)}
                    >
                        Save FOURBALL credit
                    </Button>
                </Segment>
            </>
        );
    }

    DatePickerInput = React.forwardRef((props, ref) => {
        return <Input ref={ref} {...props} />;
    });

    showCorrectCurrency() {
        if (
            !this.state.fourBallCredit.fourBallClubCourse.clubCourse.club
                ?.currency?.icon
        ) {
            return "gbp";
        }

        return this.state.fourBallCredit.fourBallClubCourse.clubCourse.club
            .currency.icon;
    }

    onChangeFourBallCreditPrice(event) {
        const { fourBallCredit } = this.state;
        fourBallCredit.price = parseInt(event.target.value, 10);
        this.setState({ fourBallCredit });
    }

    onChangeInvoiceNumber(value) {
        const { fourBallCredit } = this.state;
        fourBallCredit.invoiceNumber = value;
        this.setState({ fourBallCredit });
    }

    onChangeCreditType(_, props) {
        const { value } = props;
        const { fourBallCredit } = this.state;
        fourBallCredit.type = value;

        this.setState({ fourBallCredit });
    }

    clearExpiresAt() {
        const { fourBallCredit } = this.state;
        fourBallCredit.expiresAt = undefined;

        this.setState({ fourBallCredit });
    }

    fourBallCreditTypeOptions() {
        return [
            {
                key: 0,
                text: "Included (free / part of membership)",
                value: "included",
            },
            { key: 1, text: "Additional (paid for)", value: "additional" },
        ];
    }

    showCreditDetails() {
        return (
            <Segment vertical>
                <div className="row skinny">
                    <div className="col">
                        <h3>FOURBALL credit details</h3>
                        {this.errorsFor("showCreditDetails")}
                        <Segment vertical>
                            <label className="with-input">Expiry Date</label>
                            <DayPickerInput
                                name="test"
                                placeholder="Select date"
                                formatDate={formatDate}
                                parseDate={parseDate}
                                component={this.DatePickerInput}
                                format="DD/MM/YYYY"
                                value={
                                    this.state.fourBallCredit.expiresAt
                                        ? moment(
                                              this.state.fourBallCredit
                                                  .expiresAt
                                          ).format("DD/MM/YYYY")
                                        : ""
                                }
                                onDayChange={this.onChangeExpiresAt.bind(this)}
                            />
                            <Button
                                key="clearExpiresAt"
                                primary
                                className="theme ml-1"
                                onClick={this.clearExpiresAt.bind(this)}
                            >
                                Clear expires at
                            </Button>
                        </Segment>
                        <Segment vertical>
                            <label className="with-input">FOURBALL Price</label>
                            <Input
                                icon={this.showCorrectCurrency()}
                                iconPosition="left"
                                placeholder="Price"
                                value={this.state.fourBallCredit.price}
                                onChange={this.onChangeFourBallCreditPrice.bind(
                                    this
                                )}
                            />
                        </Segment>
                        <Segment vertical>
                            <label className="with-input">Invoice number</label>
                            <Input
                                placeholder="Invoice number"
                                value={
                                    this.state.fourBallCredit.invoiceNumber ||
                                    ""
                                }
                                onChange={({ target }) =>
                                    this.onChangeInvoiceNumber(target.value)
                                }
                            />
                        </Segment>
                        <Segment vertical>
                            <label className="with-input">Credit type</label>
                            <Select
                                search
                                value={this.state.fourBallCredit.type}
                                onChange={this.onChangeCreditType.bind(this)}
                                options={this.fourBallCreditTypeOptions()}
                            />
                        </Segment>
                    </div>
                </div>
            </Segment>
        );
    }

    deleteFourBallCredit() {
        const confirm = window.confirm(
            "Are you sure you want to delete this FOURBALL credit request?"
        );
        if (confirm === true) {
            this.state.fourBallCredit
                .delete()
                .then((_) => {
                    this.notification("success", "FOURBALL credit deleted");
                    this.props.history.push("/fourballs/credits");
                })
                .catch((_) => {
                    this.notification("error", "Something went wrong");
                });
        }
    }

    paidFourBallCredit() {
        const confirm = window.confirm(
            "Are you sure you want to mark this FOURBALL as paid?"
        );
        if (confirm === true) {
            this.state.fourBallCredit
                .paid()
                .then(() => {
                    const { fourBallCredit } = this.state;
                    fourBallCredit.paidAt = new Date();
                    fourBallCredit.paymentStatus = "paid";
                    this.setState({ saving: false, fourBallCredit });
                    this.notification("success", "FOURBALL credit paid");
                    this.props.dispatch(ValidationActions.remove());
                })
                .catch((error) => {
                    this.notification("error", "Something went wrong");
                });
        }
    }

    clubContacts(club) {
        return club.contacts.map((contact, index) => {
            return (
                <div className={styles.contact} key={index}>
                    <p>
                        <b>Name:</b> {contact.name}
                    </p>
                    <p>
                        <b>Position:</b> {contact.position}
                    </p>
                    <p>
                        <b>email:</b> {contact.email}
                    </p>
                    <p>
                        <b>phone:</b> {contact.phone}
                    </p>
                </div>
            );
        });
    }

    showFourBallBookingButton() {
        if (this.state.fourBallCredit.usedBy) {
            return (
                <p>
                    <Link
                        to={`/fourballs/bookings/${this.state.fourBallCredit.usedBy}`}
                    >
                        <Button primary className="theme mt-2">
                            View FOURBALL booking
                        </Button>
                    </Link>
                </p>
            );
        }
    }

    showFourBallCreditStatus() {
        return this.state.fourBallCredit.canBeUsed ? (
            this.styledFourBallStatus("unused")
        ) : this.state.fourBallCredit.usedBy ? (
            <Link
                to={`/fourballs/bookings/${this.state.fourBallCredit.usedBy}`}
            >
                {this.styledFourBallStatus("used")}
            </Link>
        ) : (
            this.styledFourBallStatus("expired")
        );
    }

    styledFourBallStatus(status) {
        return (
            <Label
                style={{
                    color: "white",
                    backgroundColor: this.statusColours[status],
                }}
            >
                CREDIT {status.toUpperCase()}
            </Label>
        );
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        if (this.loaded() === false) {
            return <CenterLoader />;
        }

        return (
            <div className="container">
                <div className="row skinny mb-3">
                    <h2>FOURBALL Credit Request</h2>
                </div>
                <div className="row skinny mb-3">
                    {!this.state.fourBallCredit.paidAt &&
                        !this.state.fourBallCredit.usedAt && (
                            <Button
                                primary
                                onClick={this.deleteFourBallCredit.bind(this)}
                                className="red"
                            >
                                Delete
                            </Button>
                        )}

                    {!this.state.fourBallCredit.paidAt && (
                        <Button
                            primary
                            onClick={this.paidFourBallCredit.bind(this)}
                            className="theme"
                        >
                            Mark as paid
                        </Button>
                    )}
                </div>
                <div className="row skinny mb-3">
                    <div className="col-md-6">
                        <div className={styles.choice}>
                            <p>
                                <span>
                                    <b>Created by:</b>
                                </span>
                                <span className="ml-2">
                                    {this.state.fourBallCredit.createdBy.name}
                                </span>
                                <span>
                                    {" "}
                                    on{" "}
                                    {moment(
                                        this.state.fourBallCredit.createdAt
                                    ).format("Do MMMM YYYY, HH:MM") +
                                        " (" +
                                        moment(
                                            this.state.fourBallCredit.createdAt
                                        ).fromNow() +
                                        ")"}
                                </span>
                                <span>
                                    {" "}
                                    belonging to{" "}
                                    <Link
                                        to={`/members/${this.state.fourBallCredit.user._id}`}
                                    >
                                        {this.state.fourBallCredit.user.name}
                                    </Link>
                                </span>
                            </p>
                            {this.styledFourBallStatus(
                                this.state.fourBallCredit.paymentStatus
                            )}
                        </div>
                        <div className={styles.choice}>
                            <div>{this.showFourBallCreditStatus()}</div>
                            <div>{this.showFourBallBookingButton()}</div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={styles.choice} key="clubDetails">
                            <h4>
                                <Link
                                    to={`/golf-clubs/${this.state.fourBallCredit.fourBallClubCourse.clubCourse.club._id}`}
                                >
                                    {
                                        this.state.fourBallCredit
                                            .fourBallClubCourse.clubCourse.name
                                    }{" "}
                                    at{" "}
                                    {
                                        this.state.fourBallCredit
                                            .fourBallClubCourse.clubCourse.club
                                            .name
                                    }
                                </Link>
                            </h4>
                            <p>
                                {
                                    this.state.fourBallCredit.fourBallClubCourse
                                        .clubCourse.club.email
                                }
                            </p>
                            <h5>Contacts</h5>
                            {this.clubContacts(
                                this.state.fourBallCredit.fourBallClubCourse
                                    .clubCourse.club
                            )}
                        </div>
                    </div>
                </div>
                <Tabs defaultActiveKey="details" id="tabs">
                    <Tab eventKey="details" title="Details">
                        {this.detailsTab()}
                    </Tab>
                </Tabs>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.handleNotifications()}
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Show);
