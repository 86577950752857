import axios from "axios";
const config = require("../config");

class TeeTimeBooking {
    constructor(properties) {
        Object.assign(this, properties);
    }

    static async index(params) {
        const { data } = await axios.get(config.api_url + "teetimes", {
            params,
        });

        return {
            data: data.data.map((item) => new TeeTimeBooking(item)),
            total: data.total,
        };
    }

    static async getById(id) {
        const { data } = await axios.get(config.api_url + "teetimes/" + id);
        return new TeeTimeBooking(data.data);
    }

    static async userIndex(id, params = {}) {
        const { data } = await axios.get(
            config.api_url + "users/" + id + "/teetimes",
            { params }
        );
        return {
            data: data.data.map((item) => new TeeTimeBooking(item)),
            total: data.total,
        };
    }

    async store() {
        const { data } = await axios.post(config.api_url + "teetimes", this);
        return new TeeTimeBooking(data.data);
    }

    async update() {
        const response = await axios.put(
            config.api_url + "teetimes/" + this._id,
            {
                status: this.status,
                paymentStatus: this.paymentStatus,
                paymentLink: this.paymentLink,
            }
        );

        return new TeeTimeBooking(response.data.data);
    }

    async updateConfirmedBooking() {
        const response = await axios.put(
            config.api_url + "teetimes/" + this._id + "/confirmed-booking",
            {
                course: this.confirmedBooking.course._id,
                date: this.confirmedBooking.date,
                time: this.confirmedBooking.time,
                players: this.confirmedBooking.players,
                price: this.confirmedBooking.price,
                bookingFee: this.confirmedBooking.bookingFee,
            }
        );
        return response.data.data;
    }

    claim() {
        return axios.post(config.api_url + "teetimes/" + this._id + "/claim");
    }

    watch() {
        return axios.post(config.api_url + "teetimes/" + this._id + "/watch");
    }

    delete() {
        return axios.delete(config.api_url + "teetimes/" + this._id);
    }

    static async getCustomerNotes(id) {
        const response = await axios.get(
            config.api_url + "teetimes/" + id + "/customer-notes"
        );

        return response.data.data;
    }

    static async getInternalNotes(id) {
        const response = await axios.get(
            config.api_url + "teetimes/" + id + "/internal-notes"
        );

        return response.data.data;
    }

    async storeCustomerNote(note) {
        const response = await axios.post(
            config.api_url + "teetimes/" + this._id + "/customer-notes",
            {
                note,
            }
        );
        return response.data.data;
    }

    async storeInternalNote(note) {
        const response = await axios.post(
            config.api_url + "teetimes/" + this._id + "/internal-notes",
            {
                note,
            }
        );
        return response.data.data;
    }

    markCustomerNotesRead() {
        return axios
            .post(
                config.api_url +
                    "teetimes/" +
                    this._id +
                    "/customer-notes/read",
                this
            )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    }

    hasConfirmedBooking() {
        return typeof this.confirmedBooking !== "undefined";
    }
}

export default TeeTimeBooking;
