import React, { Component } from "react";
import { Input, Segment, Label, Dropdown, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./show.module.scss";
import { Tabs, Tab } from "react-bootstrap";
import Header from "../../../assets/components/header";
import Footer from "../../../assets/components/footer";
import Partial404 from "../../errors/partials/404";
import Partial500 from "../../errors/partials/500";
import Notification from "../../../assets/components/notification";
import NotificationActions from "../../../store/actions/notification";
import ValidationActions from "../../../store/actions/validation";
import FourBallBooking from "../../../models/FourBallBooking";
import FourBallCredit from "../../../models/FourBallCredits";
import Notes from "../../../assets/components/fourBall/Notes";
import User from "../../../models/User";
import CenterLoader from "../../../assets/components/CenterLoader";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

class Show extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            loadingFourBall: true,
            error: null,
            customerNotes: [],
            showConfirmBooking: false,
            fourBall: new FourBallBooking(),
            fourBallCredit: new FourBallCredit(),
            loadingFourBallCredit: true,
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }

        this.statusColours = {
            additional: "#007ab5",
            included: "#ffc53b",
            unused: "#00b5ad",
            used: "#7d2525",
            paid: "#00b5ad",
            unpaid: "#7d2525",
            free: "#00b5ad",
            expired: "#007ab5",
        };
    }

    componentDidMount() {
        this.getBooking();
        this.getCustomerNotes();
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className="has-error">
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleNotifications() {
        if (this.props.notification !== null) {
            return (
                <Notification
                    type={this.props.notification.type}
                    message={this.props.notification.text}
                />
            );
        }
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    getBooking() {
        const { id } = this.props.match.params;
        FourBallBooking.show(id)
            .then((fourBall) => {
                fourBall.user = new User(fourBall.user);
                this.setState({ loadingFourBall: false, fourBall });
                if (typeof fourBall.creditUsed !== "undefined") {
                    this.getFourBallCredit(fourBall.creditUsed);
                } else {
                    this.setState({
                        loadingFourBallCredit: false,
                        fourBallCredit: new FourBallCredit(),
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    error: error.response.status,
                    loadingFourBall: false,
                });
                this.notification(
                    "error",
                    "Failed to entity, please try again"
                );
            });
    }

    getCustomerNotes() {
        const { id } = this.props.match.params;
        FourBallBooking.getCustomerNotes(id)
            .then((customerNotes) => {
                this.setState({ customerNotes });
            })
            .catch(() => {
                this.notification(
                    "error",
                    "Something went wrong, please try again"
                );
            });
    }

    getFourBallCredit(creditUsed) {
        FourBallCredit.show(creditUsed)
            .then((credit) => {
                this.setState({
                    loadingFourBallCredit: false,
                    fourBallCredit: credit,
                });
            })
            .catch((error) => {
                this.setState({
                    error: error.response.status,
                    loadingFourBallCredit: false,
                });
                this.notification(
                    "error",
                    "Failed to entity, please try again"
                );
            });
    }

    loaded() {
        return (
            this.state.loadingFourBall === false &&
            this.state.loadingFourBallCredit === false
        );
    }

    confirm() {
        this.setState({ saving: true });
        this.state.fourBall
            .confirm()
            .then(() => {
                const { fourBall } = this.state;
                fourBall.bookingStatus = "booked";
                this.setState({ saving: false, fourBall });
                this.notification("success", "FOURBALL request saved");
                this.props.dispatch(ValidationActions.remove());
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save FOURBALL request");
                this.handleValidationErrors(error);
            });
    }

    clubContacts(club) {
        return club.contacts.map((contact, index) => {
            return (
                <div className={styles.contact} key={index}>
                    <p>
                        <b>Name:</b> {contact.name}
                    </p>
                    <p>
                        <b>Position:</b> {contact.position}
                    </p>
                    <p>
                        <b>email:</b> {contact.email}
                    </p>
                    <p>
                        <b>phone:</b> {contact.phone}
                    </p>
                </div>
            );
        });
    }

    onChangeConfirmedDate(selectedDay, modifiers, dayPickerInput) {
        const { fourBall } = this.state;
        selectedDay = moment(selectedDay);
        fourBall.dateTimeBooked = moment(fourBall.dateTimeBooked)
            .set("date", selectedDay.format("DD"))
            .set("month", parseInt(selectedDay.format("MM"), 10) - 1)
            .set("year", selectedDay.format("YYYY"));
        this.setState({ fourBall });
    }

    onChangeConfirmedTime(event) {
        const { fourBall } = this.state;
        const { value } = event.target;
        fourBall.dateTimeBooked = moment(fourBall.dateTimeBooked)
            .set("hour", value.split(":")[0])
            .set("minute", value.split(":")[1]);
        this.setState({ fourBall });
    }

    async storeCustomerNote(note) {
        const newNote = await this.state.fourBall.storeCustomerNote(note);
        const { customerNotes } = this.state;
        customerNotes.push(newNote);
        this.setState({ customerNotes });
    }

    DatePickerInput = React.forwardRef((props, ref) => {
        return (
            <Input
                disabled={this.state.fourBall.isCancelled}
                ref={ref}
                {...props}
            />
        );
    });

    addConfirmedBooking() {
        const asDate = new Date(this.state.fourBall.dateRequested);
        const time = this.state.fourBall.timeRequested.substring(0, 2);
        asDate.setHours(parseInt(time));
        const { fourBall } = this.state;
        fourBall.dateTimeBooked = moment(asDate);
        this.setState({ fourBall });
    }

    confirmedBookingForm() {
        return (
            <>
                {this.errorsFor("dateTimeBooked")}
                <Segment vertical>
                    <label className="with-input">Date</label>
                    <DayPickerInput
                        name="test"
                        placeholder="Select date"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        component={this.DatePickerInput}
                        format="DD/MM/YYYY"
                        value={
                            this.state.fourBall.dateTimeBooked
                                ? moment(
                                      this.state.fourBall.dateTimeBooked
                                  ).format("DD/MM/YYYY")
                                : ""
                        }
                        onDayChange={this.onChangeConfirmedDate.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">Tee off time (24hr)</label>
                    <Input
                        type="time"
                        value={
                            this.state.fourBall.dateTimeBooked
                                ? moment(
                                      this.state.fourBall.dateTimeBooked
                                  ).format("HH:mm")
                                : ""
                        }
                        onChange={this.onChangeConfirmedTime.bind(this)}
                        disabled={this.state.fourBall.isCancelled}
                    />
                </Segment>
                <Segment vertical className="text-right">
                    <Button
                        primary
                        className="theme"
                        loading={this.state.saving}
                        onClick={this.confirm.bind(this)}
                        disabled={this.state.fourBall.isCancelled}
                    >
                        Save
                    </Button>
                </Segment>
            </>
        );
    }

    deleteFourBallBooking() {
        const confirm = window.confirm(
            "Are you sure you want to delete this FOURBALL booking request?"
        );
        if (confirm === true) {
            this.state.fourBall
                .delete()
                .then((response) => {
                    this.notification("success", "FOURBALL booking deleted");
                    this.props.history.push("/fourballs/bookings");
                })
                .catch((error) => {
                    this.notification("error", "Something went wrong");
                });
        }
    }

    cancelFourBallBooking() {
        const confirm = window.confirm(
            "Are you sure you want to cancel this FOURBALL booking request? If applicable, this will return the credit to the customers pool. This booking will not be editable if this is done."
        );
        if (confirm === true) {
            this.state.fourBall
                .cancel()
                .then(() => {
                    this.state.fourBall.cancelledAt = new Date();
                    this.state.fourBall.bookingStatus = "cancelled";
                    delete this.state.fourBall.creditUsed;
                    this.setState({
                        saving: false,
                        fourBall: this.state.fourBall,
                    });
                    this.notification("success", "FOURBALL booking cancelled");
                })
                .catch(() => {
                    this.notification("error", "Something went wrong");
                });
        }
    }

    showCancelBookingButton() {
        if (!this.state.fourBall.cancelledAt) {
            return (
                <Dropdown.Item
                    icon="close"
                    text="Cancel Booking"
                    onClick={this.cancelFourBallBooking.bind(this)}
                />
            );
        }
    }

    showDeleteBookingButton() {
        if (this.state.fourBall.cancelledAt) {
            return (
                <Dropdown.Item
                    icon="trash"
                    text="Delete Booking"
                    onClick={this.deleteFourBallBooking.bind(this)}
                />
            );
        }
    }

    showActionsDropdown() {
        return (
            <>
                <Dropdown
                    text="Options"
                    icon="cog"
                    floating
                    labeled
                    button
                    className="icon"
                >
                    <Dropdown.Menu>
                        <Dropdown.Header icon="cog" content="Options" />
                        <Dropdown.Divider />
                        {this.showCancelBookingButton()}
                        {this.showDeleteBookingButton()}
                    </Dropdown.Menu>
                </Dropdown>
            </>
        );
    }

    clubContacts(club) {
        return club.contacts.map((contact, index) => {
            return (
                <div className={styles.contact} key={index}>
                    <p>
                        <b>Name:</b> {contact.name}
                    </p>
                    <p>
                        <b>Position:</b> {contact.position}
                    </p>
                    <p>
                        <b>email:</b> {contact.email}
                    </p>
                    <p>
                        <b>phone:</b> {contact.phone}
                    </p>
                </div>
            );
        });
    }

    styledFourBallStatus() {
        return (
            <Label
                style={{
                    color: "white",
                    backgroundColor:
                        this.statusColours[
                            this.state.fourBallCredit.paymentStatus
                        ],
                }}
            >
                CREDIT {this.state.fourBallCredit.paymentStatus.toUpperCase()}
            </Label>
        );
    }

    showFourBallCreditButton() {
        if (this.state.fourBall.creditUsed) {
            return (
                <>
                    <div>{this.styledFourBallStatus()}</div>
                    <div>
                        <Link
                            to={`/fourballs/credits/${this.state.fourBall.creditUsed}`}
                        >
                            <Button primary className="theme mt-2">
                                View FOURBALL credit
                            </Button>
                        </Link>
                    </div>
                </>
            );
        }
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        if (this.loaded() === false) {
            return <CenterLoader />;
        }

        return (
            <div className="container">
                <div className="row skinny mb-3">
                    <h2>FOURBALL Booking Request</h2>
                </div>
                <div className="row skinny mb-3">
                    {this.showActionsDropdown()}
                </div>
                <div className="row skinny mb-3">
                    <div className="col-md-6">
                        <div className={styles.choice}>
                            <p>
                                <span>
                                    <b>Requested by:</b>
                                </span>
                                <span className="ml-2">
                                    <Link
                                        to={`/members/${this.state.fourBall.user._id}`}
                                    >
                                        {this.state.fourBall.user.name}
                                    </Link>
                                </span>
                                <span>
                                    {" "}
                                    on{" "}
                                    {moment(
                                        this.state.fourBall.createdAt
                                    ).format("Do MMMM YYYY, HH:MM") +
                                        " (" +
                                        moment(
                                            this.state.fourBall.createdAt
                                        ).fromNow() +
                                        ")"}
                                </span>
                            </p>
                            <p>
                                <b>Requested date & time:</b>{" "}
                                {moment(
                                    this.state.fourBall.dateRequested
                                ).format("Do MMMM YYYY")}
                                , {this.state.fourBall.timeRequested}
                            </p>
                            <p>
                                <b>No. of players:</b>{" "}
                                {this.state.fourBall.players}
                            </p>
                            <p>
                                <b>Status:</b>{" "}
                                {this.state.fourBall.bookingStatus.toUpperCase()}
                            </p>
                            {this.showFourBallCreditButton()}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={styles.choice} key="clubDetails">
                            <h4>
                                <Link
                                    to={`/golf-clubs/${this.state.fourBall.fourBallClubCourse.clubCourse.club._id}`}
                                >
                                    {
                                        this.state.fourBall.fourBallClubCourse
                                            .clubCourse.name
                                    }{" "}
                                    at{" "}
                                    {
                                        this.state.fourBall.fourBallClubCourse
                                            .clubCourse.club.name
                                    }
                                </Link>
                            </h4>
                            <p>
                                {
                                    this.state.fourBall.fourBallClubCourse
                                        .clubCourse.club.email
                                }
                            </p>
                            <h5>Contacts</h5>
                            {this.clubContacts(
                                this.state.fourBall.fourBallClubCourse
                                    .clubCourse.club
                            )}
                        </div>
                    </div>
                </div>
                <Tabs defaultActiveKey="details" id="tabs">
                    <Tab eventKey="details" title="Details">
                        <Segment vertical>
                            <div className="row skinny">
                                <div className="col">
                                    <h3>Confirmed booking</h3>
                                    {this.state.fourBall.isCancelled && (
                                        <p>
                                            This booking has been cancelled and
                                            can no longer be amended.
                                        </p>
                                    )}

                                    {this.state.fourBall.hasConfirmedBooking ===
                                    false ? (
                                        <Button
                                            primary
                                            className="theme"
                                            loading={this.state.saving}
                                            onClick={this.addConfirmedBooking.bind(
                                                this
                                            )}
                                            disabled={
                                                this.state.fourBall.isCancelled
                                            }
                                        >
                                            Add confirmed Booking
                                        </Button>
                                    ) : (
                                        this.confirmedBookingForm()
                                    )}
                                </div>
                            </div>
                        </Segment>
                    </Tab>
                    <Tab eventKey="customerNotes" title="Customer notes">
                        <Notes
                            notes={this.state.customerNotes}
                            save={this.storeCustomerNote.bind(this)}
                        />
                    </Tab>
                </Tabs>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.handleNotifications()}
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Show);
