import React, { Component } from "react";
import { Segment, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import NumberInput from "./NumberInput";

class FourBallClubCoursePrices extends Component {
    constructor(props) {
        super(props);
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <>
                        <span className="has-error">
                            {" "}
                            {this.props.validation[field][0]}{" "}
                        </span>
                        <br />
                    </>
                );
            }
        }
    }

    gatherInputPrice(role, value) {
        const existing =
            this.props.clubCourse.prices.find((price) => price.role === role) ??
            null;

        if (existing === null) {
            this.props.clubCourse.prices.push({
                role,
                price: value,
                expiryDays: 0,
            });
        } else {
            existing.price = value;
        }
        this.props.update(this.props.clubCourse);
    }

    gatherInputExpiryDays(role, value) {
        const existing =
            this.props.clubCourse.prices.find((price) => price.role === role) ??
            null;

        if (existing === null) {
            this.props.clubCourse.prices.push({
                role,
                price: 0,
                expiryDays: value,
            });
        } else {
            existing.expiryDays = value;
        }
        this.props.update(this.props.clubCourse);
    }

    renderRoles() {
        return this.props.roles.map((role, index) => {
            const price = this.props.clubCourse.prices.find(
                (course) => course.role === role._id
            ) ?? {
                price: 0,
                expiryDays: 0,
            };

            return (
                <Segment vertical key={index}>
                    <h3>{role.name}</h3>
                    <label className="with-input">Price</label>
                    <NumberInput
                        fluid
                        decimal
                        name={role._id}
                        value={price.price}
                        onChange={(value) =>
                            this.gatherInputPrice(role._id, value)
                        }
                    />
                    <label className="with-input">Expiry days</label>
                    <NumberInput
                        fluid
                        name={role._id}
                        value={price.expiryDays}
                        onChange={(value) =>
                            this.gatherInputExpiryDays(role._id, value)
                        }
                    />
                </Segment>
            );
        });
    }

    render() {
        return (
            <>
                <Segment vertical>
                    {this.errorsFor("prices")}
                    {this.renderRoles()}
                </Segment>
                <div className="row">
                    <div className="col">
                        <Segment vertical>
                            <Button
                                size="large"
                                floated="right"
                                className="theme"
                                primary
                                onClick={this.props.save}
                                disabled={this.props.saving}
                                loading={this.props.saving}
                            >
                                Save
                            </Button>
                        </Segment>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(FourBallClubCoursePrices);
