import React, { Component } from "react";
import { Input, Segment, Button } from "semantic-ui-react";
import { connect } from "react-redux";

class FourBallClubCourseGroupAvailability extends Component {
  constructor(props) {
    super(props);
  }

  errorsFor(field) {
    if (this.props.validation !== null) {
      if (typeof this.props.validation[field] !== "undefined") {
        return (
          <>
            <span className="has-error">
              {" "}
              {this.props.validation[field][0]}{" "}
            </span>
            <br />
          </>
        );
      }
    }
  }

  gatherInput(event) {
    this.props.group.groupFourBallsNumberPerDay[event.target.name][0].number =
      event.target.value;
    this.props.update(this.props.group);
  }

  render() {
    return (
      <>
        <Segment vertical>
          {this.errorsFor("mon")}
          <label className="with-input">Monday</label>
          <Input
            fluid
            name="mon"
            value={this.props.group.groupFourBallsNumberPerDay.mon[0].number}
            onChange={this.gatherInput.bind(this)}
          />
        </Segment>
        <Segment vertical>
          {this.errorsFor("mon")}
          <label className="with-input">Tuesday</label>
          <Input
            fluid
            name="tue"
            value={this.props.group.groupFourBallsNumberPerDay.tue[0].number}
            onChange={this.gatherInput.bind(this)}
          />
        </Segment>
        <Segment vertical>
          {this.errorsFor("mon")}
          <label className="with-input">Wednesday</label>
          <Input
            fluid
            name="wed"
            value={this.props.group.groupFourBallsNumberPerDay.wed[0].number}
            onChange={this.gatherInput.bind(this)}
          />
        </Segment>
        <Segment vertical>
          {this.errorsFor("mon")}
          <label className="with-input">Thursday</label>
          <Input
            fluid
            name="thu"
            value={this.props.group.groupFourBallsNumberPerDay.thu[0].number}
            onChange={this.gatherInput.bind(this)}
          />
        </Segment>
        <Segment vertical>
          {this.errorsFor("mon")}
          <label className="with-input">Friday</label>
          <Input
            fluid
            name="fri"
            value={this.props.group.groupFourBallsNumberPerDay.fri[0].number}
            onChange={this.gatherInput.bind(this)}
          />
        </Segment>
        <Segment vertical>
          {this.errorsFor("mon")}
          <label className="with-input">Saturday</label>
          <Input
            fluid
            name="sat"
            value={this.props.group.groupFourBallsNumberPerDay.sat[0].number}
            onChange={this.gatherInput.bind(this)}
          />
        </Segment>
        <Segment vertical>
          {this.errorsFor("mon")}
          <label className="with-input">Sunday</label>
          <Input
            fluid
            name="sun"
            value={this.props.group.groupFourBallsNumberPerDay.sun[0].number}
            onChange={this.gatherInput.bind(this)}
          />
        </Segment>
        <div className="row">
          <div className="col">
            <Segment vertical>
              <Button
                size="large"
                floated="right"
                className="theme"
                primary
                onClick={this.props.save}
                disabled={this.props.saving}
                loading={this.props.saving}
              >
                Save
              </Button>
            </Segment>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    notification: state.notification,
    validation: state.validation,
  };
}

export default connect(mapStateToProps)(FourBallClubCourseGroupAvailability);
