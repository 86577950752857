import React, { Component } from "react";
import { Segment, Select, Button, Checkbox } from "semantic-ui-react";
import NumberInput from "../components/NumberInput";
import { connect } from "react-redux";
import EntitySearchSelect from "./EntitySearchSelect";

class FourBallClubCourseDetails extends Component {
    constructor(props) {
        super(props);

        this.roles = [
            {
                key: 0,
                value: "MASHIE_COUNTRY_MEMBERSHIP",
                text: "Country members",
            },
            { key: 1, value: "MASHIE_CLUB_MEMBERSHIP", text: "Club members" },
            { key: 2, value: "MASHIE_TRIAL_MEMBERSHIP", text: "Trial members" },
            {
                key: 3,
                value: "MASHIE_FOUNDER_MEMBERSHIP",
                text: "Founder members",
            },
            { key: 4, value: "MASHIE_LINKS_MEMBERSHIP", text: "Links members" },
        ];
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <>
                        <span className="has-error">
                            {" "}
                            {this.props.validation[field][0]}{" "}
                        </span>
                        <br />
                    </>
                );
            }
        }
    }

    gatherInput(property, value) {
        this.props.clubCourse[property] = value;
        this.props.update(this.props.clubCourse);
    }

    rolesDropdownOptions() {
        return this.props.roles.map((role, index) => {
            return { key: index, text: role.name, value: role._id };
        });
    }

    onChangeShowForRoles(_, result) {
        this.props.clubCourse.roleBlockList = result.value;
        this.props.update(this.props.clubCourse);
    }

    onChangeEntities(value) {
        this.props.clubCourse.entityBlockList = value;
        this.props.update(this.props.clubCourse);
    }

    onSpecialOfferToggleChange(_, props) {
        this.props.clubCourse.hasSpecialOffer = props.checked;
        this.props.update(this.props.clubCourse);
    }

    isFeaturedToggleChange(_, props) {
        this.props.clubCourse.isFeatured = props.checked;
        this.props.update(this.props.clubCourse);
    }

    coursesDropdownOptions() {
        return this.props.clubCourses.map((course, index) => {
            return { key: index, text: course.name, value: course._id };
        });
    }

    onChangeShowForClubCourses(_, result) {
        this.props.clubCourse.clubCourse = result.value;
        this.props.update(this.props.clubCourse);
    }

    clubCourseGroupsDropdownOptions() {
        return this.props.clubCourseGroups.map((group, index) => {
            return { key: index, text: group.name, value: group._id };
        });
    }

    onChangeShowForClubCourseGroups(_, result) {
        this.props.clubCourse.group = result.value;
        this.props.update(this.props.clubCourse);
    }

    render() {
        return (
            <>
                <Segment vertical>
                    <h3>Course</h3>
                    <p>Which course is this for?</p>
                    {this.errorsFor("clubCourse")}
                    <Select
                        fluid
                        search
                        value={this.props.clubCourse.clubCourse}
                        options={this.coursesDropdownOptions()}
                        onChange={this.onChangeShowForClubCourses.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    <h3>Club Course Group</h3>
                    <p>Which club course group does this course belong to?</p>
                    {this.errorsFor("group")}
                    <Select
                        fluid
                        value={this.props.clubCourse.group}
                        options={this.clubCourseGroupsDropdownOptions()}
                        onChange={this.onChangeShowForClubCourseGroups.bind(
                            this
                        )}
                    />
                </Segment>
                <Segment vertical>
                    <h3>Booking lead time</h3>
                    <p>
                        How many days should be blocked out before a customer
                        can book?
                    </p>
                    {this.errorsFor("bookingLeadTime")}
                    <NumberInput
                        fluid
                        name="bookingLeadTime"
                        value={this.props.clubCourse.bookingLeadTime}
                        onChange={(value) =>
                            this.gatherInput("bookingLeadTime", value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    <h3>Booking max time</h3>
                    <p>
                        How many days in the future should a customer be able to
                        book?
                    </p>
                    {this.errorsFor("bookingMaxTime")}
                    <NumberInput
                        fluid
                        name="bookingMaxTime"
                        value={this.props.clubCourse.bookingMaxTime}
                        onChange={(value) =>
                            this.gatherInput("bookingMaxTime", value)
                        }
                    />
                </Segment>
                <Segment vertical>
                    <h3>Membership Type Block List</h3>
                    <p>
                        Which membership types should not be able to see this
                        club course in the app. Leave blank to show this club
                        course to all membership types.
                    </p>
                    {this.errorsFor("roleBlockList")}
                    <Select
                        fluid
                        multiple
                        value={this.props.clubCourse.roleBlockList}
                        options={this.rolesDropdownOptions()}
                        onChange={this.onChangeShowForRoles.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    <h3>Entity Block List</h3>
                    <p>
                        Which entities members' should not be able to see this
                        club course in the app. Leave blank to show this club
                        course to all entities.
                    </p>
                    {this.errorsFor("entityBlockList")}
                    <EntitySearchSelect
                        fluid
                        multiple
                        value={this.props.clubCourse.entityBlockList}
                        onChange={(value) => this.onChangeEntities(value)}
                    />
                </Segment>
                <Segment vertical>
                    <h3>Show at the top of the list</h3>
                    <p>
                        Selecting this will move the club at the top of the
                        Fourball Club list
                    </p>
                    <Checkbox
                        toggle
                        checked={this.props.clubCourse.hasSpecialOffer}
                        onChange={this.onSpecialOfferToggleChange.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    <h3>Featured FOURBALL</h3>
                    <p>
                        Set this FOURBALL club as featured to have it appear on
                        the monthly member update email
                    </p>
                    <Checkbox
                        toggle
                        checked={this.props.clubCourse.isFeatured}
                        onChange={this.isFeaturedToggleChange.bind(this)}
                    />
                </Segment>
                <div className="row">
                    <div className="col">
                        <Segment vertical>
                            <Button
                                size="large"
                                floated="right"
                                className="theme"
                                primary
                                onClick={this.props.save}
                                disabled={this.props.saving}
                                loading={this.props.saving}
                            >
                                Save
                            </Button>
                        </Segment>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(FourBallClubCourseDetails);
