import React, { Component } from "react";
import { Input, Segment, Button, Select } from "semantic-ui-react";
import { connect } from "react-redux";
import Notification from "../notification";
import NotificationActions from "../../../store/actions/notification";
import Map from "./Map";

class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
    };
  }

  notification(type, text) {
    this.props.dispatch(NotificationActions.create({ type, text }));
  }

  handleNotifications() {
    if (this.props.notification !== null) {
      return (
        <Notification
          type={this.props.notification.type}
          message={this.props.notification.text}
        />
      );
    }
  }

  gatherLocation(index, value) {
    const { club } = this.props;
    club.location[index] = value;
    this.props.update(club);
  }

  delete() {
    const confirm = window.confirm(
      "Are you sure you want to delete this club?"
    );
    if (confirm === true) {
      this.setState({ deleting: true });
      this.props.club
        .delete()
        .then(() => {
          this.setState({ deleting: false });
          this.notification("succes", "Club deleted");
          window.location = "/golf-clubs";
        })
        .catch((error) => {
          console.log(error);
          this.setState({ deleting: false });
          this.notification("error", "Failed to delete club");
        });
    }
  }

  errorsFor(field) {
    if (this.props.validation !== null) {
      if (typeof this.props.validation[field] !== "undefined") {
        return (
          <span className="has-error"> {this.props.validation[field][0]} </span>
        );
      }
    }
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col">
            <Segment vertical>
              {this.errorsFor("lat")}
              <label className="with-input">Latitude</label>
              <Input
                fluid
                name="1"
                value={this.props.club.location[1]}
                onChange={({ target }) => this.gatherLocation(1, target.value)}
              />
            </Segment>
          </div>
          <div className="col">
            <Segment vertical>
              {this.errorsFor("lon")}
              <label className="with-input">Longitude</label>
              <Input
                fluid
                name="0"
                value={this.props.club.location[0]}
                onChange={({ target }) => this.gatherLocation(0, target.value)}
              />
            </Segment>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div
              style={{
                width: "100%",
                height: 400,
                borderRadius: 20,
                overflow: "hidden",
                marginTop: 20,
              }}
            >
              <Map
                style={{ width: "100%", height: 400 }}
                club={this.props.club}
                pinMove={this.gatherLocation.bind(this)}
              />
            </div>
          </div>
        </div>
        <Segment vertical>
          <Button
            size="large"
            floated="right"
            className="theme"
            primary
            onClick={this.props.save}
          >
            Save
          </Button>
        </Segment>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    notification: state.notification,
    validation: state.validation,
  };
}

export default connect(mapStateToProps)(Location);
