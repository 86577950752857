import React, { useState, useEffect } from "react";
import {
    Form,
    Modal,
    TextArea,
    Segment,
    Button,
    Dropdown,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import ClubReview from "../../../models/ClubReview";
import Header from "../../../assets/components/header";
import Footer from "../../../assets/components/footer";
import LoadingPlaceholder from "../../../assets/components/LoadingPlaceholder";
import StarSelectDisplay from "../../../assets/components/StarSelectDisplay";

export default function ({ history, match }) {
    const [review, setReview] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [rejectionReason, setRejectionReason] = useState("DUPLICATE");
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);

    const rejectionReasons = [
        {
            key: 0,
            text: "User has reviewed this club / course too recently",
            value: "DUPLICATE",
        },
        {
            key: 1,
            text: "User is farming credit",
            value: "FARMING",
        },
        {
            key: 2,
            text: "Contains inappropriate or harmful content",
            value: "HARMFUL",
        },
        {
            key: 3,
            text: "Contains spam",
            value: "SPAM",
        },
        {
            key: 4,
            text: "Contains poor quality or unreadable content",
            value: "POOR_QUALITY",
        },
    ];

    useEffect(() => {
        const loadReview = async () => {
            try {
                const response = await ClubReview.show(match.params.id);
                setReview(response);
            } catch (_) {
                setError(true);
            }
            setLoading(false);
        };
        loadReview();
    }, []);

    const approve = async () => {
        setShowApproveModal(false);
        setLoading(true);
        try {
            await review.approve();
            review.approvedAt = new Date();
            review.status = "APPROVED";
            setReview(new ClubReview(review));
            setShowApproveModal(false);
        } catch (_) {
            setError(true);
        }
        setLoading(false);
    };

    const reject = async () => {
        setShowRejectModal(false);
        setLoading(true);
        try {
            await review.reject({ reason: rejectionReason });
            history.goBack();
        } catch (_) {
            setError(true);
            setLoading(false);
        }
    };

    const actions = () => {
        if (review.status === "APPROVED") {
            return (
                <p>
                    This review was approved on the{" "}
                    {format(review.approvedAt, "do 'of' LLLL yyyy")}
                </p>
            );
        }
        if (review.status === "REJECTED") {
            return (
                <p>
                    This review was rejected on the{" "}
                    {format(review.rejectedAt, "do 'of' LLLL yyyy")}:{" "}
                    {review.rejectedReason}
                </p>
            );
        }

        if (review.status === "EXPIRED") {
            return (
                <p>
                    This review expired on the{" "}
                    {format(review.rejectedAt, "do 'of' LLLL yyyy")}
                </p>
            );
        }

        return (
            <>
                <Button
                    primary
                    className="theme mt-4"
                    onClick={() => setShowApproveModal(true)}
                    disabled={false}
                >
                    Approve review
                </Button>
                <Button
                    primary
                    className="red"
                    onClick={() => setShowRejectModal(true)}
                    disabled={false}
                >
                    Reject review
                </Button>
            </>
        );
    };

    const bookingReview = () => {
        return review.bookingReview ? (
            <Link to={`/reviews/bookings/${review.bookingReview._id}`}>
                View
            </Link>
        ) : (
            "N/A"
        );
    };

    const content = () => {
        if (loading === true) {
            return <LoadingPlaceholder fluid />;
        }

        if (error === true) {
            return <h2>Something went wrong.</h2>;
        }

        return (
            <>
                <div className="row skinny">
                    <div className="col">
                        <h4>
                            Club:{" "}
                            <Link to={`/golf-clubs/${review.club._id}`}>
                                {review.club.name}
                            </Link>
                        </h4>
                        <h4>Course: {review.course?.name ?? "-"}</h4>
                        <h4>
                            Member:{" "}
                            <Link to={`/members/${review.user._id}`}>
                                {review.user.name}
                            </Link>
                        </h4>
                        <p className="ml-5">
                            Last review:{" "}
                            {review.recentReviews.lastReview
                                ? format(
                                      new Date(review.recentReviews.lastReview),
                                      "do 'of' LLLL yyyy"
                                  )
                                : "None"}
                        </p>
                        <p className="ml-5">
                            Last review of {review.club.name}:{" "}
                            {review.recentReviews.lastReviewOfClub
                                ? format(
                                      new Date(
                                          review.recentReviews.lastReviewOfClub
                                      ),
                                      "do 'of' LLLL yyyy"
                                  )
                                : "None"}
                        </p>
                        <p className="ml-5">
                            No. of reviews in the past six months:{" "}
                            {review.recentReviews.recentReviews}
                        </p>
                        <p className="ml-5">
                            No. of reviews in total:{" "}
                            {review.recentReviews.totalReviews}
                        </p>
                        <h4>Booking review: {bookingReview()}</h4>
                    </div>
                </div>
                <div className="row skinny mt-3">
                    <div className="col">
                        <Form>
                            <Segment vertical>
                                <h4>Mashie Member rating</h4>
                                <StarSelectDisplay score={review.rating} />
                            </Segment>
                            {review.questions.map((question) => (
                                <Segment vertical key={question._id}>
                                    <h4>{question.description}</h4>
                                    {question.questionType === "RATING" && (
                                        <StarSelectDisplay
                                            score={question.answer.rating}
                                        />
                                    )}
                                    {question.questionType === "TEXT" && (
                                        <TextArea
                                            value={question.answer.text}
                                            disabled
                                        />
                                    )}
                                </Segment>
                            ))}
                            <Segment vertical>
                                <h4>Review text</h4>
                                <TextArea disabled value={review.reviewText} />
                            </Segment>
                            <Segment vertical>{actions()}</Segment>
                        </Form>
                    </div>
                </div>
                <Modal open={showApproveModal}>
                    <Modal.Header>Approve review</Modal.Header>
                    <Modal.Content>
                        <p>
                            Are you sure you want to approve this review? Once
                            approved, this review will be visible to other
                            members within the app.
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            primary
                            className="theme"
                            content="Approve"
                            onClick={() => approve()}
                        />
                        <Button
                            content="Cancel"
                            onClick={() => setShowApproveModal(false)}
                        />
                    </Modal.Actions>
                </Modal>
                <Modal open={showRejectModal}>
                    <Modal.Header>Reject review</Modal.Header>
                    <Modal.Content style={{ height: "300px" }}>
                        <p>For what reason are you rejecting this review?</p>
                        <Dropdown
                            selection
                            placeholder={"Status"}
                            options={rejectionReasons}
                            value={rejectionReason}
                            onChange={(_, { value }) =>
                                setRejectionReason(value)
                            }
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            primary
                            className="theme"
                            content="Reject"
                            onClick={() => reject()}
                        />
                        <Button
                            content="Cancel"
                            onClick={() => setShowRejectModal(false)}
                        />
                    </Modal.Actions>
                </Modal>
            </>
        );
    };

    return (
        <>
            <div className="d-flex main">
                <Header history={history} />
                <div className="container">{content()}</div>
            </div>
            <Footer />
        </>
    );
}
