import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import DataTable from "../../assets/components/dataTable";
import Notification from "../../assets/components/notification";
import MemberSearchSelect from "../../assets/components/MemberSearchSelect";
import NotificationActions from "../../store/actions/notification";
import { Button, Label, Select } from "semantic-ui-react";
import TeeTimeBooking from "../../models/TeeTimeBooking";
import GolfClubSearchSelect from "../../assets/components/GolfClubSearchSelect";
import EntitySearchSelect from "../../assets/components/EntitySearchSelect";
import moment from "moment";

class TeeTimesIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true,
            bookings: [],
            notification: null,
            error: false,
            filters: {
                club: [],
                entity: [],
                member: [],
                status: "active",
            },
            table: {
                page: 1,
                term: "",
                sort: "createdAt",
                order: "asc",
                limit: 25,
            },
            totalRecords: 0,
        };

        this.statusColours = {
            requested: "#007ab5",
            confirmed: "#00b571",
            booked: "#00b5ad",
            "awaiting confirmation": "#ffc53b",
            "cancellation requested": "#d76e6e",
            cancelled: "#7d2525",
        };

        this.statusFilterOptions = [
            {
                key: 0,
                text: "All",
                value: null,
            },
            {
                key: 1,
                text: "Active",
                value: "active",
            },
            {
                key: 2,
                text: "Booked",
                value: "booked",
            },
            {
                key: 3,
                text: "Cancelled",
                value: "cancelled",
            },
        ];
    }

    componentDidMount() {
        this.getBookings(this.state.table);
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleNotifications() {
        if (this.props.notification !== null) {
            return (
                <Notification
                    type={this.props.notification.type}
                    message={this.props.notification.text}
                />
            );
        }
    }

    showClaimedLabel(teeTime) {
        if (typeof teeTime.claimedBy === "undefined") {
            return "-";
        }

        return <Label>{teeTime.claimedBy.name}</Label>;
    }

    getBookings(params) {
        this.setState({ loading: true });
        TeeTimeBooking.index(Object.assign(params, this.state.filters))
            .then(({ data, total }) => {
                this.setState({
                    table: params,
                    loading: false,
                    bookings: data,
                    totalRecords: total,
                });
            })
            .catch((error) => {
                this.setState({ loading: false, error: true });
                this.notification(
                    "error",
                    "Failed to load Tee time bookings, please try again"
                );
            });
    }

    onChangeFilter(property, value) {
        const { filters, table } = this.state;
        filters[property] = value;
        table.page = 1;
        this.setState({ filters, table });
        this.getBookings(table);
    }

    viewButton(teeTime) {
        const showUnreadCount =
            this.props.user.isWatchingTeeTime(teeTime) ||
            this.props.user.hasClaimedTeeTime(teeTime);
        if (showUnreadCount && teeTime.unreadMessages > 0) {
            return (
                <Link to={`/tee-time-requests/${teeTime._id}`}>
                    <Button
                        primary
                        className="theme"
                        style={{ position: "relative" }}
                    >
                        <Label color="red" circular floating>
                            {teeTime.unreadMessages}
                        </Label>
                        View
                    </Button>
                </Link>
            );
        }

        return (
            <Link to={`/tee-time-requests/${teeTime._id}`}>
                <Button primary className="theme">
                    View
                </Button>
            </Link>
        );
    }

    styledStatus(status) {
        return (
            <Label
                style={{
                    color: "white",
                    backgroundColor: this.statusColours[status],
                }}
            >
                {status}
            </Label>
        );
    }

    requestedOrConfirmedDate(booking) {
        if (booking.hasConfirmedBooking()) {
            return (
                new Date(booking.confirmedBooking.date).toLocaleDateString() +
                " (confirmed)"
            );
        }

        return new Date(booking.date).toLocaleDateString();
    }

    requestedOrConfirmedTime(booking) {
        if (booking.hasConfirmedBooking()) {
            return booking.confirmedBooking.time + " (confirmed)";
        }

        return booking.timeslot;
    }

    requestedOrConfirmedClub(booking) {
        if (booking.hasConfirmedBooking()) {
            return {
                name:
                    booking.confirmedBooking.course.club.name + " (confirmed)",
                _id: booking.confirmedBooking.course.club._id,
            };
        }

        return {
            name: booking.choices[0].course.club.name,
            _id: booking.choices[0].course.club._id,
        };
    }

    table(teeTimes) {
        const headers = [
            { key: "requested", text: "Date requested", searchable: true },
            { key: "memberName", text: "Member", searchable: true },
            { key: "entity", text: "Entity", searchable: true },
            { key: "club", text: "Club", searchable: true },
            { key: "date", text: "Booking date", searchable: true },
            { key: "timeslot", text: "Time slot", searchable: true },
            { key: "players", text: "Players", searchable: true },
            { key: "status", text: "Status", searchable: true },
            { key: "payment", text: "Payment", searchable: true },
            { key: "claimed", text: "Claimed" },
        ];

        if (this.props.user.has("MASHIE_ADMIN")) {
            headers.push({ key: "actions", text: "Actions" });
        }

        const data = teeTimes.map((teeTime) => {
            const club = this.requestedOrConfirmedClub(teeTime);
            const row = {
                requested: {
                    value:
                        moment(teeTime.createdAt).format(
                            "Do MMMM YYYY, HH:MM"
                        ) +
                        " (" +
                        moment(teeTime.createdAt).fromNow() +
                        ")",
                },
                memberName: {
                    value: (
                        <Link to={`/members/${teeTime.user._id}`}>
                            {teeTime.user.name}
                        </Link>
                    ),
                },
                entity: {
                    value: (
                        <Link
                            to={`/entities/${teeTime.user.entity.entity._id}`}
                        >
                            {teeTime.user.entity.entity.name}
                        </Link>
                    ),
                },
                club: {
                    value: (
                        <Link to={`/golf-clubs/${club._id}`}>{club.name}</Link>
                    ),
                },
                date: {
                    value: this.requestedOrConfirmedDate(teeTime),
                    search: moment(teeTime.date).unix(),
                },
                timeslot: { value: this.requestedOrConfirmedTime(teeTime) },
                players: { value: teeTime.players },
                status: {
                    value: this.styledStatus(teeTime.status),
                },
                payment: {
                    value: teeTime.paymentStatus,
                },
                claimed: { value: this.showClaimedLabel(teeTime) },
            };

            if (this.props.user.has("MASHIE_ADMIN")) {
                row.actions = { value: this.viewButton(teeTime) };
            }

            return row;
        });

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
                onChange={this.getBookings.bind(this)}
                totalRecords={this.state.totalRecords}
                hideSearch={true}
                page={this.state.table.page}
                sort={this.state.table.sort}
                order={this.state.table.order}
                limit={this.state.table.limit}
                sortOptions={[
                    { text: "Status", key: "status" },
                    { text: "Payment status", key: "paymentStatus" },
                    { text: "Players", key: "players" },
                    { text: "Booking date", key: "date" },
                    { text: "Date requested", key: "createdAt" },
                    { text: "Member (grouped)", key: "user" },
                    { text: "Claimed by (grouped)", key: "claimedBy" },
                ]}
                pagination
            />
        );
    }

    list() {
        if (this.state.error === true) {
            return (
                <p className="text-center">
                    Something went wrong. Please try again.
                </p>
            );
        }

        return this.table(this.state.bookings);
    }

    body() {
        return (
            <div className="container-fluid" style={{ overflowX: "scroll" }}>
                <div className="row page-header mt-3">
                    <div className="col">
                        <h2>Tee time requests</h2>
                    </div>
                    <div className="col-sm-12 col-md-2 text-right">
                        <Link to="/tee-time-requests/create">
                            <Button primary className="theme">
                                Add Tee Time
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h3>Filters</h3>
                        <Select
                            placeholder="Filter by status"
                            search
                            value={this.state.filters.status}
                            onChange={(_, { value }) =>
                                this.onChangeFilter("status", value)
                            }
                            options={this.statusFilterOptions}
                            className="mr-3"
                        />
                        <EntitySearchSelect
                            multiple
                            placeholder="Filter by entity"
                            value={this.state.filters.entity}
                            onChange={(value) =>
                                this.onChangeFilter("entity", value)
                            }
                            className="mr-3"
                        />
                        <GolfClubSearchSelect
                            multiple
                            value={this.state.filters.club}
                            onChange={(value) =>
                                this.onChangeFilter("club", value)
                            }
                            placeholder="Filter by club"
                            className="mr-3"
                        />
                        <MemberSearchSelect
                            onChange={(value) =>
                                this.onChangeFilter("member", value)
                            }
                            multiple
                            value={this.state.filters.member}
                            placeholder="Filter by member"
                        />
                    </div>
                </div>
                <hr />
                <div className="row mt-3">
                    <div className="col">{this.list()}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.handleNotifications()}
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(TeeTimesIndex);
