import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import DataTable from "../../assets/components/dataTable";
import Notification from "../../assets/components/notification";
import NotificationActions from "../../store/actions/notification";
import { Button, Label } from "semantic-ui-react";
import UserStoreActions from "../../store/actions/user";
import logoPlaceholder from "../../assets/images/building.svg";
import Entity from "../../models/Entity";
const config = require("../../config");

class EntitiesIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: false,
            table: {
                page: 1,
                term: "",
                sort: "createdAt",
                order: "desc",
                limit: 25,
            },
            totalRecords: 0,
            searchTerm: "",
            entities: [],
            notification: null,
            error: false,
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleNotifications() {
        if (this.props.notification !== null) {
            return (
                <Notification
                    type={this.props.notification.type}
                    message={this.props.notification.text}
                />
            );
        }
    }

    setCurrentAdminEntity(entityId) {
        const { user } = this.props;
        this.props.user
            .setCurrentAdminEntity(entityId)
            .then(async () => {
                const entity = await Entity.show(entityId);
                user.currentAdministrationEntity = entity;
                this.props.dispatch(UserStoreActions.store(user));
                this.notification("success", "Entity changed");
            })
            .catch(() => {
                this.notification("error", "Failed to change entity");
            });
    }

    getEntities(params) {
        this.setState({ loading: true });
        Entity.index(params)
            .then((data) => {
                this.setState({
                    loading: false,
                    table: params,
                    entities: data.entities,
                    totalRecords: data.total,
                });
            })
            .catch((error) => {
                this.setState({ loading: false, error: true });
                this.notification(
                    "error",
                    "Failed to load entities, please try again"
                );
            });
    }

    logo(entity) {
        if (typeof entity.logo !== "undefined") {
            return config.content_url + "logos/" + entity.logo;
        }

        return logoPlaceholder;
    }

    componentDidMount() {
        this.getEntities(this.state.table);
    }

    currentlyManaging(entity) {
        if (entity._id === this.props.user.currentAdministrationEntity._id) {
            return (
                <Label basic color="teal" pointing="left">
                    Currently managing
                </Label>
            );
        }
    }

    claimedBy(entity) {
        if (
            typeof entity.claimedBy === "undefined" ||
            entity.claimedBy === null
        ) {
            return "-";
        }

        return <Label>{entity.claimedBy.name}</Label>;
    }

    table() {
        const headers = [
            { key: "created", text: "Created" },
            { key: "logo", text: "Logo" },
            { key: "name", text: "Name" },
            { key: "type", text: "Type" },
            { key: "primaryContactName", text: "Primary contact" },
            { key: "claimedBy", text: "Claimed" },
            { key: "status", text: "Status" },
            { key: "members", text: "Members" },
            { key: "actions", text: "Actions" },
        ];

        const data = this.state.entities.map((entity) => {
            return {
                created: {
                    value: moment(entity.createdAt).format("DD/MM/YYYY"),
                },
                logo: {
                    value: (
                        <img src={entity.logoUrl} className="profile-logo-sm" />
                    ),
                },
                name: {
                    value: (
                        <>
                            {entity.name} {this.currentlyManaging(entity)}
                        </>
                    ),
                    search: entity.name,
                },
                type: {
                    value: entity.entityType || "",
                    search: entity.entityType,
                },
                primaryContactName: {
                    value: entity.primaryContactName || "-",
                    search: entity.primaryContactName,
                },
                claimedBy: { value: this.claimedBy(entity) },
                status: { value: entity.status || "", search: entity.status },
                members: { value: entity.totalMembers },
                actions: {
                    value: (
                        <>
                            <Link to={`/entities/${entity._id}`}>
                                <Button primary className="theme">
                                    View
                                </Button>
                            </Link>
                            <Button
                                primary
                                className="theme"
                                onClick={() =>
                                    this.setCurrentAdminEntity(entity._id)
                                }
                            >
                                Manage
                            </Button>
                        </>
                    ),
                },
            };
        });

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
                totalRecords={this.state.totalRecords}
                page={this.state.table.page}
                onChange={this.getEntities.bind(this)}
                searchTerm={this.state.table.term}
                sort={this.state.table.sort}
                order={this.state.table.order}
                limit={this.state.table.limit}
                sortOptions={[
                    { text: "Creation", key: "createdAt" },
                    { text: "Name", key: "name" },
                    { text: "Type", key: "entityType" },
                ]}
                pagination
            />
        );
    }

    body() {
        return (
            <div className="container-fluid" style={{ overflowX: "scroll" }}>
                <div className="row page-header mt-3">
                    <div className="col-sm-12 col-md-10">
                        <h2>Entities</h2>
                    </div>
                    <div className="col-sm-12 col-md-2 text-right">
                        <Link to="/entities/create">
                            <Button primary className="theme">
                                Add entity
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col">{this.table()}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.handleNotifications()}
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(EntitiesIndex);
