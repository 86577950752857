import React, { Component } from "react";
import {
    Input,
    Segment,
    Select,
    Label,
    Dropdown,
    Button,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";
import { Tabs, Tab } from "react-bootstrap";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import Notes from "../../assets/components/teeTime/Notes";
import Partial404 from "../errors/partials/404";
import Partial500 from "../errors/partials/500";
import Notification from "../../assets/components/notification";
import NotificationActions from "../../store/actions/notification";
import ValidationActions from "../../store/actions/validation";
import TeeTimeBooking from "../../models/TeeTimeBooking";
import GolfClub from "../../models/GolfClub";
import User from "../../models/User";
import Currency from "../../models/Currency";
import CenterLoader from "../../assets/components/CenterLoader";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import NumberInput from "../../assets/components/NumberInput";
import GolfClubSearchSelect from "../../assets/components/GolfClubSearchSelect";
import formatNumberAsPrice from "../../utilities/formatNumberAsPrice";

class Show extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            loadingTeeTime: true,
            customerNotes: [],
            internalNotes: [],
            error: null,
            teeTime: new TeeTimeBooking(),
            canSendMessage: true,
            selectedConfirmedClub: null,
            activeChosenClubCourses: [],
            currency: null,
            nearby: [],
        };

        this.statuses = [
            {
                key: 0,
                text: "Requested",
                value: "requested",
            },
            {
                key: 2,
                text: "Awaiting confirmation",
                value: "awaiting confirmation",
            },
            {
                key: 3,
                text: "Confirmed",
                value: "confirmed",
            },
            {
                key: 4,
                text: "Booked",
                value: "booked",
            },
            {
                key: 5,
                text: "Cancellation requested",
                value: "cancellation requested",
            },
            {
                key: 6,
                text: "Cancelled",
                value: "cancelled",
            },
        ];

        this.paymentStatuses = [
            {
                key: 0,
                text: "Unpaid",
                value: "unpaid",
            },
            {
                key: 1,
                text: "Pay MASHIE Now",
                value: "pay mashie now",
            },
            {
                key: 2,
                text: "Pay club Now",
                value: "pay club now",
            },
            {
                key: 3,
                text: "Pay club on arrival",
                value: "pay club on arrival",
            },
            {
                key: 4,
                text: "Paid",
                value: "paid",
            },
            {
                key: 5,
                text: "No payment required",
                value: "no payment required",
            },
            {
                key: 6,
                text: "Refunded",
                value: "refunded",
            },
        ];

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }
    }

    async componentDidMount() {
        await this.getTeeTime();
        this.getCustomerNotes();
        this.getInternalNotes();
        this.getCurrency();
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className="has-error">
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleNotifications() {
        if (this.props.notification !== null) {
            return (
                <Notification
                    type={this.props.notification.type}
                    message={this.props.notification.text}
                />
            );
        }
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    coursesDropdownOptions() {
        return this.state.activeChosenClubCourses.map((club, index) => {
            return { key: index, text: club.name, value: club._id };
        });
    }

    async getTeeTime() {
        const { id } = this.props.match.params;
        try {
            const teeTime = await TeeTimeBooking.getById(id);
            teeTime.user = new User(teeTime.user);
            const nearby = await new GolfClub(
                teeTime.choices[0].course.club
            ).nearby();
            let activeChosenClubCourses = [];
            if (teeTime.confirmedBooking !== undefined) {
                const { club } = teeTime.confirmedBooking.course;
                activeChosenClubCourses = await new GolfClub(club).coursesIndex(
                    { sort: "name" }
                );
            }

            this.setState({
                loadingTeeTime: false,
                teeTime,
                nearby,
                activeChosenClubCourses,
                selectedConfirmedClub:
                    teeTime.confirmedBooking?.course.club._id ?? null,
            });
        } catch (error) {
            this.setState({
                error: error.response.status,
                loadingTeeTime: false,
            });
            this.notification("error", "Failed to entity, please try again");
        }
    }

    async getCurrency() {
        const club = this.state.teeTime.confirmedBooking?.course.club ?? null;
        if (club === null) return;
        const currency = await Currency.getById(club.currency);
        this.setState({ currency });
    }

    getCustomerNotes() {
        const { id } = this.props.match.params;
        TeeTimeBooking.getCustomerNotes(id)
            .then((customerNotes) => {
                this.setState({ customerNotes });
            })
            .catch(() => {
                this.notification(
                    "error",
                    "Something went wrong, please try again"
                );
            });
    }

    getInternalNotes() {
        const { id } = this.props.match.params;
        TeeTimeBooking.getInternalNotes(id)
            .then((internalNotes) => {
                this.setState({ internalNotes });
            })
            .catch(() => {
                this.notification(
                    "error",
                    "Something went wrong, please try again"
                );
            });
    }

    loaded() {
        return this.state.loadingTeeTime === false;
    }

    async addConfirmedBooking() {
        const { teeTime } = this.state;
        teeTime.confirmedBooking = {
            date: teeTime.date,
            time: teeTime.timeslot.split(" ")[0],
            players: teeTime.players,
            price: teeTime.price ?? 0,
            bookingFee: teeTime.bookingFee ?? 0,
            course: teeTime.choices[0].course,
        };

        const club = await GolfClub.show(teeTime.choices[0].course.club._id);

        const courses = await club.coursesIndex({ sort: "name" });

        this.setState({
            teeTime,
            selectedConfirmedClub: teeTime.choices[0].course.club._id,
            activeChosenClubCourses: courses,
            currency: club.currency,
        });
    }

    onChangeStatus(value) {
        const { teeTime } = this.state;
        teeTime.status = value;
        this.setState({ teeTime });
    }

    onChangePaymentStatus(event, props) {
        const { teeTime } = this.state;
        const { value } = props;
        teeTime.paymentStatus = value;
        this.setState({ teeTime });
    }

    save() {
        this.setState({ saving: true });
        this.state.teeTime
            .update()
            .then((response) => {
                this.setState({ saving: false });
                this.notification("success", "Tee time request saved");
                this.props.dispatch(ValidationActions.remove());
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save Tee time request");
                this.handleValidationErrors(error);
            });
    }

    clubContacts(club) {
        return club.contacts.map((contact, index) => {
            return (
                <div className={styles.contact} key={index}>
                    <p>
                        <b>Name:</b> {contact.name}
                    </p>
                    <p>
                        <b>Position:</b> {contact.position}
                    </p>
                    <p>
                        <b>email:</b> {contact.email}
                    </p>
                    <p>
                        <b>phone:</b> {contact.phone}
                    </p>
                </div>
            );
        });
    }

    onChangeConfirmedDate(selectedDay, modifiers, dayPickerInput) {
        const { teeTime } = this.state;
        teeTime.confirmedBooking.date = selectedDay;
        this.setState({ teeTime });
    }

    onChangeConfirmedTime(event) {
        const { teeTime } = this.state;
        const { value } = event.target;
        teeTime.confirmedBooking.time = value;
        this.setState({ teeTime });
    }

    onChangeConfirmedPlayers(value) {
        const { teeTime } = this.state;
        teeTime.confirmedBooking.players = parseInt(value, 10) || 0;
        this.setState({ teeTime });
    }

    onChangeInput(property, value) {
        const { teeTime } = this.state;
        teeTime[property] = value === "" ? null : value;
        this.setState({ teeTime });
    }

    onChangeConfirmedPrice(value) {
        const { teeTime } = this.state;
        teeTime.confirmedBooking.price = value;
        this.setState({ teeTime });
    }

    onChangeConfirmedBookingFee(value) {
        const { teeTime } = this.state;
        teeTime.confirmedBooking.bookingFee = value || "";
        this.setState({ teeTime });
    }

    async onChangeConfirmedClub(value) {
        const { teeTime } = this.state;
        const club = await GolfClub.show(value);
        const courses = await club.coursesIndex({ sort: "name" });
        this.setState({
            teeTime,
            activeChosenClubCourses: courses,
            currency: club.currency,
        });

        teeTime.confirmedBooking.course = courses[0];
        this.setState({ teeTime, selectedConfirmedClub: value });
    }

    onChangeConfirmedCourse(value) {
        const { teeTime } = this.state;
        teeTime.confirmedBooking.course =
            this.state.activeChosenClubCourses.find(
                (course) => course._id === value
            );
        this.setState({ teeTime });
    }

    detailsTab() {
        return (
            <>
                <Segment vertical>
                    <div className="row skinny">
                        <div className="col">
                            {this.errorsFor("status")}
                            <label className="with-input">Status</label>
                            <Select
                                fluid
                                value={this.state.teeTime.status}
                                onChange={(_, { value }) =>
                                    this.onChangeStatus(value)
                                }
                                options={this.statuses}
                            />
                            <p className="mt-3">
                                <b>Note: </b>
                                The customer will be notified of any status
                                changes.
                            </p>
                        </div>
                    </div>
                </Segment>
                <Segment vertical className="text-right">
                    <Button
                        primary
                        className="theme"
                        loading={this.state.saving}
                        onClick={this.save.bind(this)}
                    >
                        Save
                    </Button>
                </Segment>
            </>
        );
    }

    addNewInternalNote() {
        const { teeTime } = this.state;
        const textarea = document.getElementById("addInternalNote");
        if (textarea.value.length > 0) {
            const note = {
                note: textarea.value,
                createdAt: Date.now(),
            };

            if (typeof teeTime.newInternalNotes === "undefined") {
                teeTime.newInternalNotes = [];
            }

            teeTime.newInternalNotes.push(note);
            this.setState({ teeTime });
            textarea.value = "";
            this.save();
        }
    }

    async addNewCustomerNote(note) {
        const newNote = await this.state.teeTime.storeCustomerNote(note);
        const { customerNotes } = this.state;
        customerNotes.push(newNote);
        this.setState({ customerNotes });
    }

    async addNewInternalNote(note) {
        const newNote = await this.state.teeTime.storeInternalNote(note);
        const { internalNotes } = this.state;
        internalNotes.push(newNote);
        this.setState({ internalNotes });
    }

    listCustomerNotes() {
        return this.state.customerNotes.map((note, index) => {
            if (note.createdBy._id === this.state.teeTime.user._id) {
                return (
                    <div className={styles.messageGrey} key={index}>
                        <img
                            className="profile-logo-xs mr-3"
                            src={User.profileImageUrlForUser(note.createdBy)}
                        />
                        <span>
                            <b>{note.createdBy.name}</b>
                        </span>
                        <span className="ml-3">
                            {moment(note.createdAt).fromNow()}
                        </span>
                        <p className="mt-3">{note.note}</p>
                    </div>
                );
            }

            const customerReadBy = note.readBy.find(
                (read) => read.user === this.state.teeTime.user._id
            );
            const readByText = customerReadBy
                ? "Read " +
                  moment(customerReadBy.at).format("DD/MM/YYYY, HH:mm")
                : "";
            return (
                <div className={styles.messageBlue} key={index}>
                    <img
                        className="profile-logo-xs mr-3"
                        src={User.profileImageUrlForUser(note.createdBy)}
                    />
                    <span>
                        <b>{note.createdBy.name}</b>
                    </span>
                    <span className="ml-3">
                        {moment(note.createdAt).fromNow()}
                    </span>
                    <p className="mt-3">{note.note}</p>
                    <span className="small">{readByText}</span>
                </div>
            );
        });
    }

    paymentTab() {
        return (
            <>
                <Segment vertical>
                    <div className="row">
                        <div className="col">
                            <label className="with-input">Payment link</label>
                            <Input
                                fluid
                                placeholder="Payment link"
                                value={this.state.teeTime.paymentLink || ""}
                                onChange={({ target }) =>
                                    this.onChangeInput(
                                        "paymentLink",
                                        target.value
                                    )
                                }
                            />
                        </div>
                        <div className="col">
                            {this.errorsFor("paymentStatus")}
                            <label className="with-input">Payment status</label>
                            <Select
                                fluid
                                value={this.state.teeTime.paymentStatus}
                                onChange={this.onChangePaymentStatus.bind(this)}
                                options={this.paymentStatuses}
                            />
                        </div>
                    </div>
                </Segment>
                <Segment vertical className="text-right">
                    <Button
                        primary
                        className="theme"
                        loading={this.state.saving}
                        onClick={this.save.bind(this)}
                    >
                        Save
                    </Button>
                </Segment>
            </>
        );
    }

    numberOfPlayers() {
        if (this.state.teeTime.players > 8) {
            return this.state.teeTime.players + "+";
        }

        return this.state.teeTime.players;
    }

    listChoices() {
        return (
            <div className={styles.choice}>
                <h3>Requested club</h3>
                {this.state.teeTime.choices.map((choice, index) => {
                    return (
                        <div key={index}>
                            <p className="m-0">
                                <Link
                                    key={index}
                                    to={`/golf-clubs/${choice.course.club._id}`}
                                >
                                    {choice.course.club.name}
                                </Link>
                            </p>
                            <p className="m-0">{choice.course.name}</p>
                            <p className="m-0">
                                {choice.course.club.email ?? (
                                    <i>Club email not available</i>
                                )}
                            </p>
                            {this.clubContacts(choice.course.club)}
                        </div>
                    );
                })}
            </div>
        );
    }

    listNearby() {
        const { nearby } = this.state;

        return (
            <div className={styles.choice}>
                <h3>Alternative clubs (within 60 miles)</h3>
                {nearby.length === 0 ? (
                    <p>No alternative clubs were found</p>
                ) : (
                    nearby.map((club, index) => {
                        return (
                            <div key={index} className="mt-3">
                                <p className="m-0">
                                    <Link to={`/golf-clubs/${club._id}`}>
                                        {club.name}
                                    </Link>
                                </p>
                                <p className="m-0">
                                    {club.distance} miles away
                                </p>
                            </div>
                        );
                    })
                )}
            </div>
        );
    }

    showCorrectCurrency() {
        return this.state.currency?.icon ?? "";
    }

    DatePickerInput = React.forwardRef((props, ref) => {
        return <Input ref={ref} {...props} />;
    });

    updateConfirmedBooking() {
        const { teeTime } = this.state;
        this.state.teeTime
            .updateConfirmedBooking()
            .then((response) => {
                this.notification("success", "Booking updated");
            })
            .catch((error) => {
                this.handleValidationErrors(error);
                this.notification("error", "Something went wrong");
            });
    }

    confirmBooking() {
        if (typeof this.state.teeTime.confirmedBooking === "undefined") {
            return (
                <Button
                    primary
                    className="theme mt-3"
                    onClick={this.addConfirmedBooking.bind(this)}
                >
                    Add booking
                </Button>
            );
        }

        return (
            <Segment vertical>
                <div className="row skinny">
                    <div className="col">
                        {this.errorsFor("confirmedBooking")}
                        <Segment vertical>
                            <label className="with-input">Club</label>
                            <GolfClubSearchSelect
                                value={this.state.selectedConfirmedClub}
                                onChange={(value) =>
                                    this.onChangeConfirmedClub(value)
                                }
                            />
                        </Segment>
                        <Segment vertical>
                            <label className="with-input">Course</label>
                            <Select
                                search
                                value={
                                    this.state.teeTime.confirmedBooking.course
                                        ?._id ?? null
                                }
                                onChange={(_, { value }) =>
                                    this.onChangeConfirmedCourse(value)
                                }
                                options={this.coursesDropdownOptions()}
                            />
                        </Segment>
                        <Segment vertical>
                            <label className="with-input">No. of players</label>
                            <Input
                                placeholder="No. of players"
                                value={
                                    this.state.teeTime.confirmedBooking.players
                                }
                                onChange={({ target }) =>
                                    this.onChangeConfirmedPlayers(target.value)
                                }
                            />
                        </Segment>
                        <Segment vertical>
                            {this.errorsFor("price")}
                            <label className="with-input">Price (total)</label>
                            <NumberInput
                                icon={this.showCorrectCurrency()}
                                iconPosition="left"
                                placeholder="Price"
                                fluid
                                decimal
                                value={
                                    this.state.teeTime.confirmedBooking.price
                                }
                                onChange={(value) =>
                                    this.onChangeConfirmedPrice(value)
                                }
                            />
                        </Segment>
                        <Segment vertical>
                            {this.errorsFor("bookingFee")}
                            <label className="with-input">
                                Booking fee (total)
                            </label>
                            <NumberInput
                                icon={"gbp"}
                                decimal
                                iconPosition="left"
                                placeholder="Booking fee"
                                fluid
                                value={
                                    this.state.teeTime.confirmedBooking
                                        .bookingFee
                                }
                                onChange={(value) =>
                                    this.onChangeConfirmedBookingFee(value)
                                }
                            />
                        </Segment>
                        <Segment vertical>
                            <label className="with-input">Date</label>
                            <DayPickerInput
                                name="test"
                                placeholder="Select date"
                                formatDate={formatDate}
                                parseDate={parseDate}
                                component={this.DatePickerInput}
                                format="DD/MM/YYYY"
                                value={moment(
                                    this.state.teeTime.confirmedBooking.date
                                ).format("DD/MM/YYYY")}
                                onDayChange={this.onChangeConfirmedDate.bind(
                                    this
                                )}
                            />
                        </Segment>
                        <Segment vertical>
                            <label className="with-input">
                                Tee off time (24hr)
                            </label>
                            <Input
                                type="time"
                                value={this.state.teeTime.confirmedBooking.time}
                                placeholder="No. of players"
                                onChange={this.onChangeConfirmedTime.bind(this)}
                            />
                        </Segment>
                        <Segment vertical>
                            <Button
                                primary
                                className="theme"
                                onClick={this.updateConfirmedBooking.bind(this)}
                            >
                                Save
                            </Button>
                        </Segment>
                    </div>
                </div>
            </Segment>
        );
    }

    deleteTeeTime() {
        const confirm = window.confirm(
            "Are you sure you want to delete this tee time request?"
        );
        if (confirm === true) {
            this.state.teeTime
                .delete()
                .then((response) => {
                    this.notification("success", "Tee time deleted");
                    this.props.history.push("/tee-time-requests");
                })
                .catch((error) => {
                    this.notification("error", "Something went wrong");
                });
        }
    }

    showActionsDropdown() {
        return (
            <>
                <Dropdown
                    text="Options"
                    icon="cog"
                    floating
                    labeled
                    button
                    className="icon"
                >
                    <Dropdown.Menu>
                        <Dropdown.Header icon="cog" content="Options" />
                        <Dropdown.Divider />
                        <Dropdown.Item
                            icon="trash"
                            text="Delete"
                            onClick={this.deleteTeeTime.bind(this)}
                        />
                    </Dropdown.Menu>
                </Dropdown>
            </>
        );
    }

    watchTeeTime() {
        const confirm = window.confirm(
            "Are your sure you want to watch this teetime?"
        );
        if (confirm === true) {
            this.state.teeTime
                .watch()
                .then(() => {
                    const { teeTime } = this.state;
                    teeTime.watchers.push(this.props.user);
                    this.setState({ teeTime });
                    this.notification(
                        "success",
                        "You are now watching this tee time"
                    );
                })
                .catch((error) => {
                    this.notification("error", "Failed to watch tee time");
                });
        }
    }

    showWatchLabel() {
        if (this.props.user.isWatchingTeeTime(this.state.teeTime)) {
            return (
                <Label as="a" className="mr-2">
                    Watching
                </Label>
            );
        }
    }

    showWatchButton() {
        if (this.props.user.isWatchingTeeTime(this.state.teeTime) === false) {
            return (
                <Button
                    primary
                    className="theme"
                    onClick={this.watchTeeTime.bind(this)}
                >
                    Watch
                </Button>
            );
        }
    }

    claimTeeTime() {
        const confirm = window.confirm(
            "Are yuo sure you want to claim this teetime?"
        );
        if (confirm === true) {
            const { teeTime } = this.state;
            teeTime
                .claim()
                .then(() => {
                    teeTime.claimedBy = this.props.user;
                    this.setState({ teeTime });
                    this.notification("success", "Tee time claimed");
                })
                .catch((error) => {
                    this.notification("error", "Failed to claim tee time");
                });
        }
    }

    showClaimLabel() {
        if (typeof this.state.teeTime.claimedBy !== "undefined") {
            return (
                <Label as="a" image className="mr-2">
                    <img
                        src={User.profileImageUrlForUser(
                            this.state.teeTime.claimedBy
                        )}
                    />
                    Claimed by {this.state.teeTime.claimedBy.name}
                </Label>
            );
        }
    }

    showClaimButton() {
        const { teeTime } = this.state;
        if (this.props.user.hasClaimedTeeTime(teeTime) === false) {
            return (
                <Button
                    primary
                    className="theme"
                    onClick={this.claimTeeTime.bind(this)}
                >
                    Claim
                </Button>
            );
        }
    }

    onChangeTab(key) {
        if (key === "customerNotes") {
            this.getCustomerNotes();
            this.state.teeTime.markCustomerNotesRead();
            const { teeTime } = this.state;
            teeTime.unreadMessages = 0;
            this.setState({ teeTime });
        }
        if (key === "internalNotes") {
            this.getInternalNotes();
        }
    }

    customerNotesTabText() {
        const { teeTime } = this.state;
        const showUnreadCount =
            this.props.user.isWatchingTeeTime(teeTime) ||
            this.props.user.hasClaimedTeeTime(teeTime);
        if (showUnreadCount && teeTime.unreadMessages > 0) {
            return (
                <div>
                    Customer notes{" "}
                    <Label circular color="red" className="ml-1" size="small">
                        {teeTime.unreadMessages}
                    </Label>
                </div>
            );
        }

        return "Customer notes";
    }

    showQuotedPrice() {
        if ("price" in this.state.teeTime === false) {
            return <i>None</i>;
        }

        let priceString = `${
            this.state.currency?.symbol ?? "£"
        }${formatNumberAsPrice(this.state.teeTime.price)}`;
        if (this.state.teeTime.bookingFee > 0) {
            priceString += ` (+ £${formatNumberAsPrice(
                this.state.teeTime.bookingFee
            )} booking fee)`;
        }

        return priceString;
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        if (this.loaded() === false) {
            return <CenterLoader />;
        }

        return (
            <div className="container">
                <div className="row skinny mb-3">
                    <div className="col-12 mb-3">
                        <h2>Tee Time Request</h2>
                    </div>
                    <div className="col-12 mb-3">
                        {this.showClaimLabel()}
                        {this.showWatchLabel()}
                        {this.showClaimButton()}
                        {this.showWatchButton()}
                    </div>
                    <div className="col-12">{this.showActionsDropdown()}</div>
                </div>
                <div className="row skinny mb-3">
                    <div className="col-4">
                        <div className={styles.choice}>
                            <h3>Original booking details</h3>
                            <p>
                                <span>
                                    <b>Requested by:</b>
                                </span>
                                <span className="ml-2">
                                    <Link
                                        to={`/members/${this.state.teeTime.user._id}`}
                                    >
                                        {this.state.teeTime.user.name}
                                    </Link>
                                </span>
                                <span>
                                    {" "}
                                    on{" "}
                                    {moment(
                                        this.state.teeTime.createdAt
                                    ).format("Do MMMM YYYY, HH:MM") +
                                        " (" +
                                        moment(
                                            this.state.teeTime.createdAt
                                        ).fromNow() +
                                        ")"}
                                </span>
                            </p>
                            <p>
                                <b>Requested date & time:</b>{" "}
                                {moment(this.state.teeTime.date).format(
                                    "Do MMMM YYYY"
                                )}
                                , {this.state.teeTime.timeslot}
                            </p>
                            <p>
                                <b>No. of players:</b> {this.numberOfPlayers()}
                            </p>
                            <p>
                                <b>Additional requests:</b>{" "}
                                {this.state.teeTime.additionalRequests
                                    .length ? (
                                    this.state.teeTime.additionalRequests.join(
                                        ", "
                                    )
                                ) : (
                                    <i>None</i>
                                )}
                            </p>
                            <p>
                                <b>Quoted price: </b>
                                {this.showQuotedPrice()}
                            </p>
                            <p>
                                <b>Notes:</b>{" "}
                                {this.state.teeTime.notes &&
                                this.state.teeTime.notes.length ? (
                                    this.state.teeTime.notes
                                ) : (
                                    <i>None</i>
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="col-4">{this.listChoices()}</div>
                    <div className="col-4">{this.listNearby()}</div>
                </div>
                <Tabs
                    defaultActiveKey="confirmedBooking"
                    id="tabs"
                    onSelect={this.onChangeTab.bind(this)}
                >
                    <Tab eventKey="confirmedBooking" title="Booking">
                        <div className="row skinny mb-3">
                            <div className="col">{this.confirmBooking()}</div>
                        </div>
                    </Tab>
                    <Tab eventKey="details" title="Details">
                        {this.detailsTab()}
                    </Tab>
                    <Tab
                        eventKey="customerNotes"
                        title={this.customerNotesTabText()}
                    >
                        <Notes
                            notes={this.state.customerNotes}
                            save={this.addNewCustomerNote.bind(this)}
                        />
                    </Tab>
                    <Tab eventKey="internalNotes" title="Internal notes">
                        <Notes
                            notes={this.state.internalNotes}
                            save={this.addNewInternalNote.bind(this)}
                        />
                    </Tab>
                    <Tab eventKey="payment" title="Payment">
                        {this.paymentTab()}
                    </Tab>
                </Tabs>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.handleNotifications()}
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Show);
