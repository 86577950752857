const axios = require('axios');
const config = require('../config');

class Role {
	constructor(properties) {
		Object.assign(this, properties);
	}

	static getRoles() {
		return axios.get(config.api_url + 'roles')
			.then(response => {
				return response;
			}).catch(error => {
				throw error;
			});
	}
}

export default Role;
