import axios from "axios";
import profileImagePlaceholder from "../assets/images/camera-placeholder.png";
import React from "react";
import { Label } from "semantic-ui-react";
const config = require("../config");

class Member {
    constructor(properties) {
        this.emailAddresses = [];
        this.phoneNumbers = [];
        this.addresses = [];
        this.roles = [];
        this.notes = [];
        this.permissions = [];
        this.allPermissions = [];
        Object.assign(this, properties);
    }

    has(key) {
        if (typeof this.roles !== "undefined") {
            return this.allPermissions.some(
                (permission) => permission.key === key
            );
        }

        return false;
    }

    hasRole(key) {
        if (typeof this.roles !== "undefined") {
            return this.roles.some((role) => role.role.key === key);
        }

        return false;
    }

    get hasActiveMembership() {
        if (this.hasOwnProperty("activeMembership") === false) {
            return false;
        }

        return this.activeMembership !== null;
    }

    getMembershipStatus() {
        if (
            this.hasOwnProperty("memberships") === false &&
            this.hasOwnProperty("activeMembership") === false
        ) {
            return {
                label: (
                    <Label basic className="m-0 mr-3" color="grey">
                        Could not determine
                    </Label>
                ),
                expiry: null,
            };
        }

        if (
            this.hasOwnProperty("activeMembership") &&
            this.activeMembership !== null
        ) {
            const label = (
                <Label basic className="m-0 mr-3" color="green">
                    {this.activeMembership.name}
                </Label>
            );

            return {
                label,
                expiry: this.activeMembership.expiresAt,
            };
        }

        if (this.hasOwnProperty("memberships") && this.memberships.length > 0) {
            let colour = "blue";
            if (this.memberships[0].status === "Expired") {
                colour = "red";
            }

            const label = (
                <Label basic className="m-0 mr-3" color={colour}>
                    {this.memberships[0].name}
                </Label>
            );

            return {
                label,
                expiry: this.memberships[0].expiresAt,
            };
        }

        return {
            label: (
                <Label basic className="m-0 mr-3" color="grey">
                    No memberships
                </Label>
            ),
            expiry: null,
        };
    }

    static async show(id) {
        const response = await axios.get(config.api_url + "members/" + id);
        return new Member(response.data.data);
    }

    static async getTransactions(params) {
        const { data } = await axios.get(config.api_url + "transactions", {
            params,
        });
        return {
            transactions: data.data,
            total: data.total,
        };
    }

    static async getUserTransactions(id) {
        const response = await axios.get(
            config.api_url + "users/" + id + "/transactions"
        );
        return response.data.data;
    }

    async storeCreditTransaction(transaction) {
        const response = await axios.post(
            config.api_url + "users/" + this._id + "/transactions",
            transaction
        );
        return response.data.data;
    }

    static async index(params) {
        const { data } = await axios.get(config.api_url + "members", {
            params,
        });
        return {
            members: data.data.map((member) => new Member(member)),
            total: data.total,
        };
    }

    static async list(params = {}) {
        const { data } = await axios.get(config.api_url + "members/list", {
            params,
        });
        return data.data.map((member) => new Member(member));
    }

    async store() {
        const response = await axios.post(config.api_url + "/members", {
            firstName: this.firstName,
            surname: this.surname,
            emailAddresses: this.emailAddresses.map((email) => ({
                email: email.email,
                emailType: email.emailType,
                primary: email.primary,
            })),
        });

        return new Member(response.data.data);
    }

    updateProfileImage(profileImage) {
        return axios.put(
            config.api_url + "members/" + this._id + "/profile-image",
            {
                profileImage,
            }
        );
    }

    async update() {
        const response = await axios.put(
            config.api_url + "members/" + this._id,
            {
                firstName: this.firstName,
                surname: this.surname,
                emailAddresses: this.emailAddresses.map((email) => ({
                    email: email.email,
                    emailType: email.emailType,
                    primary: email.primary,
                })),
                phoneNumbers: this.phoneNumbers.map((phone) => ({
                    number: phone.number,
                    phoneType: phone.phoneType,
                    primary: phone.primary,
                })),
                addresses: this.addresses.map((address) => ({
                    line1: address.line1,
                    line2: address.line2,
                    town: address.town,
                    county: address.county,
                    postcode: address.postcode,
                    country: address.country,
                    addressType: address.addressType,
                })),
                membershipNumber: this.membershipNumber,
                membershipPaymentType: this.membershipPaymentType,
                onlineCreditCode: this.onlineCreditCode,
                MashieHandicap: this.MashieHandicap,
                CONGUHandicap: this.CONGUHandicap,
                homeGolfCourse: this.homeGolfCourse,
                secondaryGolfCourse: this.secondaryGolfCourse,
                chestSize: this.chestSize,
                waistSize: this.waistSize,
                trouserLength: this.trouserLength,
                collarSize: this.collarSize,
                shoeSize: this.shoeSize,
                preferredFit: this.preferredFit,
                introducer: this.introducer,
                personalBio: this.personalBio,
                businessBio: this.businessBio,
                discountCode: this.discountCode,
                invoiceOnly: this.invoiceOnly,
                canRequestCredit: this.canRequestCredit,
                unsubscribeFromCommunications:
                    this.unsubscribeFromCommunications,
                interests: this.interests,
            }
        );

        return new Member(response.data.data);
    }

    async updateRoles() {
        const response = await axios.put(
            config.api_url + "members/" + this._id + "/roles",
            {
                roles: this.roles.map((role) => ({
                    _id: role._id,
                    role: role.role._id,
                    expiresAt: role.expiresAt,
                    activatesAt: role.activatesAt,
                    duration: role.duration,
                    cost: role.cost,
                    invoiceNumber: role.invoiceNumber,
                    paid: role.paid,
                    creditExempt: role.creditExempt,
                    eventCredit: role.eventCredit,
                    deletedAt: role.deletedAt,
                    createdAt: role.createdAt,
                    purchaseTransaction: role.purchaseTransaction,
                    createdBy: role.createdBy,
                    deletedAt: role.deletedAt,
                    deletedBy: role.deletedBy,
                })),
            }
        );

        return response.data.data;
    }

    async updateEntities() {
        const response = await axios.put(
            config.api_url + "members/" + this._id + "/entities",
            {
                entities: this.entities.map((entity) => ({
                    entity: entity.entity._id,
                    primary: entity.primary,
                })),
            }
        );

        return response.data.data;
    }

    delete() {
        return axios.delete(config.api_url + "members/" + this._id);
    }

    static async getNotes(id) {
        const response = await axios.get(
            config.api_url + "members/" + id + "/notes"
        );
        return response.data.data;
    }

    async storeNote(note) {
        const response = await axios.post(
            config.api_url + "members/" + this._id + "/notes",
            { note }
        );
        return response.data.data;
    }

    sendWelcomeEmail() {
        return axios.post(
            config.api_url + "members/" + this._id + "/send-welcome-email"
        );
    }

    sendPasswordResetEmail() {
        return axios.post(
            config.api_url +
                "members/" +
                this._id +
                "/send-password-reset-email"
        );
    }

    get profileImageUrl() {
        if (typeof this.profileImage !== "undefined") {
            return config.content_url + "profiles/" + this.profileImage;
        }

        return profileImagePlaceholder;
    }
}

export default Member;
