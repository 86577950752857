import React, { Component } from "react";
import { Dropdown, Label, Button } from "semantic-ui-react";
import { Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import Header from "../../../assets/components/header";
import Footer from "../../../assets/components/footer";
import Partial404 from "../../errors/partials/404";
import Partial500 from "../../errors/partials/500";
import Notification from "../../../assets/components/notification";
import NotificationActions from "../../../store/actions/notification";
import FourBallBooking from "../../../models/FourBallBooking";
import DataTable from "../../../assets/components/dataTable";
import MemberSearchSelect from "../../../assets/components/MemberSearchSelect";
import { Link } from "react-router-dom";
import moment from "moment";
import Calendar from "../../../assets/components/fourBall/Calendar";

class Show extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            loading: true,
            error: null,
            notification: null,
            fourBallBookings: [],
            filters: {
                status: "pending",
                member: null,
            },
            table: {
                page: 1,
                term: "",
                sort: "createdAt",
                order: "asc",
                limit: 25,
            },
            totalRecords: 0,
            calendarBookings: [],
        };

        this.statusColours = {
            requested: "#007ab5",
            pending: "#007ab5",
            confirmed: "#00b571",
            booked: "#00b5ad",
            "awaiting confirmation": "#ffc53b",
            "cancellation requested": "#d76e6e",
            cancelled: "#7d2525",
        };
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    componentDidMount() {
        this.getBookings(this.state.table);
        this.getCalendarBookings();
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleNotifications() {
        if (this.props.notification !== null) {
            return (
                <Notification
                    type={this.props.notification.type}
                    message={this.props.notification.text}
                />
            );
        }
    }

    getBookings(params) {
        this.setState({ loading: true });
        FourBallBooking.index(Object.assign(params, this.state.filters))
            .then((data) => {
                this.setState({
                    table: params,
                    totalRecords: data.total,
                    loading: false,
                    fourBallBookings: data.bookings,
                });
            })
            .catch((error) => {
                this.setState({
                    error: error.response.status,
                    loading: false,
                });
                this.notification("error", "Failed to user, please try again");
            });
    }

    getCalendarBookings(month = new Date().getMonth()) {
        FourBallBooking.getCalendarBookings({
            month,
        }).then((calendarBookings) => this.setState({ calendarBookings }));
    }

    viewFourBallBookingButton(fourBall) {
        return (
            <Link to={`/fourballs/bookings/${fourBall._id}`}>
                <Button primary className="theme">
                    View
                </Button>
            </Link>
        );
    }

    onChangeFilter(property, value) {
        const { filters, table } = this.state;
        filters[property] = value;

        table.page = 1;
        this.setState({ filters, table });
        this.getBookings(table);
    }

    table() {
        const headers = [
            { key: "created", text: "Created date", searchable: true },
            { key: "memberName", text: "Member", searchable: true },
            { key: "entity", text: "Entity", searchable: true },
            { key: "clubCourse", text: "Club Course", searchable: true },
            {
                key: "dateTimeRequested",
                text: "Date/Time Requested",
                searchable: true,
            },
            {
                key: "dateTimeBooked",
                text: "Date/Time Booked",
                searchable: true,
            },
            { key: "players", text: "Players", searchable: true },
            { key: "bookedAt", text: "Booked date", searchable: true },
        ];

        if (this.props.user.has("MASHIE_ADMIN")) {
            headers.push({ key: "actions", text: "Actions" });
        }

        const data = this.state.fourBallBookings.map((fourBall) => {
            const row = {
                created: {
                    value: moment(fourBall.createdAt).format(
                        "Do MMMM YYYY, HH:mm"
                    ),
                    search: moment(fourBall.createdAt).unix(),
                },
                memberName: {
                    value: (
                        <Link to={`/members/${fourBall.user._id}`}>
                            {fourBall.user.name}
                        </Link>
                    ),
                    search: fourBall.user.name,
                },
                entity: {
                    value: (
                        <Link
                            to={`/entities/${fourBall.user.entity.entity._id}`}
                        >
                            {fourBall.user.entity.entity.name}
                        </Link>
                    ),
                    search: fourBall.user.entity.entity.name,
                },
                clubCourse: {
                    value: (
                        <Link
                            to={`/golf-clubs/${fourBall.fourBallClubCourse.clubCourse.club._id}`}
                        >
                            {fourBall.fourBallClubCourse.clubCourse.name} at{" "}
                            {fourBall.fourBallClubCourse.clubCourse.club.name}
                        </Link>
                    ),
                    search:
                        fourBall.fourBallClubCourse.clubCourse.name +
                        " at " +
                        fourBall.fourBallClubCourse.clubCourse.club.name,
                },
                dateTimeRequested: {
                    value: (
                        <b>
                            {moment(fourBall.dateRequested).format(
                                "Do MMMM YYYY"
                            )}{" "}
                            between {fourBall.timeRequested}
                        </b>
                    ),
                    search: moment(fourBall.dateRequested).unix(),
                },
                dateTimeBooked: {
                    value: fourBall.dateTimeBooked ? (
                        <b>
                            {moment(fourBall.dateTimeBooked).format(
                                "Do MMMM YYYY HH:mm"
                            )}
                        </b>
                    ) : (
                        "-"
                    ),
                    search: moment(fourBall.dateTimeBooked).unix(),
                },
                players: {
                    value: fourBall.players,
                    search: fourBall.players,
                },
                bookedAt: {
                    value: fourBall.bookedAt ? (
                        <b>
                            {moment(fourBall.bookedAt).format(
                                "Do MMMM YYYY HH:mm"
                            )}
                        </b>
                    ) : (
                        "-"
                    ),
                    search: moment(fourBall.bookedAt).unix(),
                },
            };

            if (this.props.user.has("MASHIE_ADMIN")) {
                row.actions = {
                    value: this.viewFourBallBookingButton(fourBall),
                };
            }

            return row;
        });

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
                onChange={this.getBookings.bind(this)}
                totalRecords={this.state.totalRecords}
                hideSearch={true}
                page={this.state.table.page}
                sort={this.state.table.sort}
                order={this.state.table.order}
                limit={this.state.table.limit}
                sortOptions={[
                    { text: "Creation", key: "createdAt" },
                    { text: "Requested date", key: "dateRequested" },
                    { text: "Booked date", key: "dateTimeBooking" },
                    { text: "Member", key: "user" },
                    { text: "Players", key: "players" },
                ]}
                pagination
            />
        );
    }

    styledFourBallStatus(status) {
        return (
            <Label
                style={{
                    color: "white",
                    backgroundColor: this.statusColours[status],
                }}
            >
                {status}
            </Label>
        );
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        return (
            <div className="container skinny">
                <div className="row page-header mt-3">
                    <div className="col">
                        <h2>FOURBALL bookings</h2>
                        <Tabs defaultActiveKey="table">
                            <Tab eventKey="table" title="Table">
                                <div className="row pt-3">
                                    <div className="col text-right">
                                        <Link to="/fourballs/bookings/create">
                                            <Button primary className="theme">
                                                Create booking
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <h3>Filters</h3>
                                        <Dropdown
                                            selection
                                            placeholder={"Booking status"}
                                            options={[
                                                {
                                                    key: 0,
                                                    text: "All",
                                                    value: null,
                                                },
                                                {
                                                    key: 1,
                                                    text: "Pending",
                                                    value: "pending",
                                                },
                                                {
                                                    key: 2,
                                                    text: "Booked",
                                                    value: "booked",
                                                },
                                                {
                                                    key: 3,
                                                    text: "Cancelled",
                                                    value: "cancelled",
                                                },
                                            ]}
                                            value={this.state.filters.status}
                                            onChange={(_, { value }) =>
                                                this.onChangeFilter(
                                                    "status",
                                                    value
                                                )
                                            }
                                        />
                                        <MemberSearchSelect
                                            onChange={(value) =>
                                                this.onChangeFilter(
                                                    "member",
                                                    value
                                                )
                                            }
                                            value={this.state.filters.member}
                                            placeholder="Filter by member"
                                            className="ml-3"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">{this.table()}</div>
                                </div>
                            </Tab>

                            <Tab eventKey="calendar" title="Calendar">
                                <div className="pt-3">
                                    <Calendar
                                        bookings={this.state.calendarBookings}
                                        onChangeMonth={this.getCalendarBookings.bind(
                                            this
                                        )}
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.handleNotifications()}
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(Show);
