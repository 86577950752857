import React, { Component } from "react";
import {
  Input,
  Segment,
  Form,
  Checkbox,

  TextArea,


  Button,

} from "semantic-ui-react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./show.module.scss";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import Partial404 from "../errors/partials/404";
import Partial500 from "../errors/partials/500";
import Notification from "../../assets/components/notification";
import NotificationActions from "../../store/actions/notification";
import ValidationActions from "../../store/actions/validation";
import AppVariable from "../../models/AppVariable";
import CenterLoader from "../../assets/components/CenterLoader";

class AppVariablesShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      loadingAppVariable: true,
      error: null,
      notification: null,
      appVariable: new AppVariable(),
    };

    if (this.props.user.has("MASHIE_ADMIN") === false) {
      this.props.history.push("/members");
    }
  }

  componentDidMount() {
    this.getAppVariable();
  }

  componentWillUnmount() {
    this.props.dispatch(NotificationActions.remove());
  }

  errorsFor(field) {
    if (this.props.validation !== null) {
      if (typeof this.props.validation[field] !== "undefined") {
        return (
          <span className="has-error"> {this.props.validation[field][0]} </span>
        );
      }
    }
  }

  displayError() {
    if (this.state.error === 500) {
      return <Partial500 />;
    }

    if (this.state.error === 404) {
      return <Partial404 />;
    }
  }

  notification(type, text) {
    this.props.dispatch(NotificationActions.create({ type, text }));
  }

  handleNotifications() {
    if (this.props.notification !== null) {
      return (
        <Notification
          type={this.props.notification.type}
          message={this.props.notification.text}
        />
      );
    }
  }

  handleValidationErrors(error) {
    if (error.response.status === 422) {
      this.props.dispatch(ValidationActions.create(error.response.data.errors));
    }
  }

  handleNewAdminValidationErrors(error) {
    if (error.response.status === 422) {
      this.setState({ validationErrorsNewAdmin: error.response.data.errors });
    }
  }

  getAppVariable() {
    const { id } = this.props.match.params;
    AppVariable.getByIdOrKey(id)
      .then((data) => {
        this.setState({
          loadingAppVariable: false,
          appVariable: new AppVariable(data),
        });
      })
      .catch((error) => {
        this.setState({ error: error.response.status });
        this.notification(
          "error",
          "Failed to load app variable, please try again"
        );
      });
  }

  onChangeName(event) {
    const appVariable = this.state.appVariable;
    appVariable[event.target.name] = event.target.value;
    this.setState({ appVariable });
  }

  onChangeValue(event) {
    const { appVariable } = this.state;
    appVariable[event.target.name] = event.target.value;
    this.setState({ appVariable });
  }

  onChangeData(event) {
    const { appVariable } = this.state;
    appVariable[event.target.name] = event.target.value;
    this.setState({ appVariable });
  }

  onChangeToggleIsEnabled() {
    const { appVariable } = this.state;
    appVariable.isEnabled = !appVariable.isEnabled;
    this.setState({ appVariable });
  }

  save() {
    this.setState({ saving: true });
    this.state.appVariable
      .update()
      .then((response) => {
        this.setState({
          saving: false,
          appVariable: new AppVariable(response.data.data),
        });
        this.notification("success", "App variable saved");
      })
      .catch((error) => {
        this.setState({ saving: false });
        this.notification("error", "Failed to save app variable");
        this.handleValidationErrors(error);
      });
  }

  loaded() {
    return this.state.loadingAppVariable === false;
  }

  offerDetailsTab() {
    return (
      <div className={styles.mainSegment}>
        <h3>Details</h3>
        <p>Edit the app variables details.</p>
        <Segment vertical>
          {this.errorsFor("name")}
          <label className="with-input">
            Name <b>*</b>
          </label>
          <Input
            fluid
            name="name"
            value={this.state.appVariable.name || ""}
            onChange={this.onChangeName.bind(this)}
          />
        </Segment>
        <Segment vertical>
          <label className="with-input">Enabled</label>
          <Checkbox
            toggle
            checked={this.state.appVariable.isEnabled}
            onChange={this.onChangeToggleIsEnabled.bind(this)}
          />
        </Segment>
        <Segment vertical>
          {this.errorsFor("val")}
          <label className="with-input">Value</label>
          <Form>
            <TextArea
              name="val"
              value={this.state.appVariable.val || ""}
              onChange={this.onChangeValue.bind(this)}
            />
          </Form>
        </Segment>
        <Segment vertical>
          {this.errorsFor("data")}
          <label className="with-input">Data</label>
          <Form>
            <TextArea
              name="data"
              value={this.state.appVariable.data || ""}
              onChange={this.onChangeData.bind(this)}
            />
          </Form>
        </Segment>
        <Segment vertical>
          <Button
            size="large"
            floated="right"
            className="theme"
            primary
            onClick={this.save.bind(this)}
            disabled={this.state.saving}
            loading={this.state.saving}
          >
            Save
          </Button>
        </Segment>
      </div>
    );
  }

  body() {
    if (this.state.error) {
      return this.displayError();
    }

    if (this.loaded() === false) {
      return <CenterLoader />;
    }

    return (
      <div className="container">
        <div className="row skinny">
          <div className="col">
            <Tabs defaultActiveKey="details" id="uncontrolled-tab-example">
              <Tab eventKey="details" title="Details">
                {this.offerDetailsTab()}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="d-flex main">
          <Header history={this.props.history} />
          {this.handleNotifications()}
          {this.body()}
        </div>
        <Footer />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    notification: state.notification,
    validation: state.validation,
  };
}

export default connect(mapStateToProps)(AppVariablesShow);
