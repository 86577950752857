import axios from "axios";
const config = require("../config");

class FourBallBooking {
    constructor(properties) {
        Object.assign(this, properties);
    }

    static async userIndex(id, params = {}) {
        const response = await axios.get(
            config.api_url + "fourball/users/" + id + "/bookings",
            { params }
        );
        return response.data.data.map(
            (booking) => new FourBallBooking(booking)
        );
    }

    static async getCalendarBookings(params = {}) {
        const response = await axios.get(
            config.api_url + "fourball/bookings/calendar",
            { params }
        );
        return response.data.data.map((item) => {
            item.start = new Date(item.start);
            item.end = new Date(item.end);
            return item;
        });
    }

    get isCancelled() {
        return this.bookingStatus === "cancelled";
    }

    get hasConfirmedBooking() {
        return typeof this.dateTimeBooked !== "undefined";
    }

    static async show(id) {
        const response = await axios.get(
            config.api_url + "fourball/bookings/" + id
        );
        return new FourBallBooking(response.data.data);
    }

    static async getCustomerNotes(id) {
        const response = await axios.get(
            config.api_url + "fourball/bookings/" + id + "/customer-notes"
        );
        return response.data.data;
    }

    async storeCustomerNote(note) {
        const response = await axios.post(
            config.api_url +
                "fourball/bookings/" +
                this._id +
                "/customer-notes",
            {
                note,
            }
        );
        return response.data.data;
    }

    static async index(params) {
        const response = await axios.get(config.api_url + "fourball/bookings", {
            params,
        });

        return {
            bookings: response.data.data.map(
                (booking) => new FourBallBooking(booking)
            ),
            total: response.data.total,
        };
    }

    cancel() {
        return axios.post(
            config.api_url + "fourball/bookings/" + this._id + "/cancel"
        );
    }

    confirm() {
        return axios.post(
            config.api_url + "fourball/bookings/" + this._id + "/confirm",
            {
                dateTimeBooked: this.dateTimeBooked,
            }
        );
    }

    delete() {
        return axios.delete(config.api_url + "fourball/bookings/" + this._id);
    }

    store() {
        return axios.post(config.api_url + "fourball/bookings", {
            fourBallClubCourse: this.fourBallClubCourse,
            dateRequested: this.dateRequested,
            timeRequested: this.timeRequested,
            players: this.players,
            user: this.user,
            creditUsed: this.creditUsed,
        });
    }
}

export default FourBallBooking;
