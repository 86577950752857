import React, { Component } from "react";
import { Input, Segment, Select, Form, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import Header from "../../../assets/components/header";
import Footer from "../../../assets/components/footer";
import Partial404 from "../../errors/partials/404";
import Partial500 from "../../errors/partials/500";
import Notification from "../../../assets/components/notification";
import NotificationActions from "../../../store/actions/notification";
import ValidationActions from "../../../store/actions/validation";
import FourBallCredit from "../../../models/FourBallCredits";
import FourBallClubCourses from "../../../models/FourBallClubCourses";
import MemberSearchSelect from "../../../assets/components/MemberSearchSelect";
import CenterLoader from "../../../assets/components/CenterLoader";
import NumberInput from "../../../assets/components/NumberInput";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

class Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            loadingClubCourses: true,
            clubCourses: [],

            error: null,
            fourBallCredit: new FourBallCredit({
                expiresAt: new Date(Date.now() + 1.577e10), // Six months from now
                price: 0,
                type: "included",
                user:
                    new URLSearchParams(window.location.search).get("member") ??
                    null,
            }),
        };
    }

    componentDidMount() {
        this.getClubCourses();
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className="has-error">
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleNotifications() {
        if (this.props.notification !== null) {
            return (
                <Notification
                    type={this.props.notification.type}
                    message={this.props.notification.text}
                />
            );
        }
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    clubCoursesDropdownOptions() {
        return this.state.clubCourses.map((clubCourse, index) => {
            return {
                key: index,
                text: clubCourse.name,
                value: clubCourse._id,
            };
        });
    }

    creditTypesDropdownOptions() {
        return [
            {
                key: 0,
                text: "Included (free / part of membership)",
                value: "included",
            },
            { key: 1, text: "Additional (paid for)", value: "additional" },
        ];
    }

    getClubCourses() {
        this.setState({ loading: true });
        FourBallClubCourses.list()
            .then((clubCourses) => {
                this.setState({
                    loadingClubCourses: false,
                    clubCourses,
                });
            })
            .catch((error) => {
                this.setState({ loadingClubCourses: false, error: true });
                this.notification(
                    "error",
                    "Failed to load club courses, please try again"
                );
            });
    }

    loaded() {
        return this.state.loadingClubCourses === false;
    }

    save() {
        this.setState({ saving: true });
        this.state.fourBallCredit
            .store()
            .then((credit) => {
                this.setState({ saving: false });
                this.notification("success", "Tee time request saved");
                this.props.dispatch(ValidationActions.remove());
                return this.props.history.push(
                    "/fourballs/credits/" + credit._id
                );
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save Tee time request");
                this.handleValidationErrors(error);
            });
    }

    canSave() {
        const { type, user, fourBallClubCourse } = this.state.fourBallCredit;
        if (
            type === undefined ||
            user === undefined ||
            fourBallClubCourse === undefined
        ) {
            return true;
        }

        return false;
    }

    DatePickerInput = React.forwardRef((props, ref) => {
        return <Input ref={ref} {...props} />;
    });

    onChangeMember(value) {
        const { fourBallCredit } = this.state;
        fourBallCredit.user = value;
        this.setState({ fourBallCredit });
    }

    onChangeCreditType(event, props) {
        const { value } = props;
        const { fourBallCredit } = this.state;
        fourBallCredit.type = value;
        this.setState({ fourBallCredit });
    }

    onChangeExpiresAtDate(selectedDay, modifiers, dayPickerInput) {
        const { fourBallCredit } = this.state;
        fourBallCredit.expiresAt = selectedDay;
        this.setState({ fourBallCredit });
    }

    onChangeRequestedPlayers(event) {
        const { teeTime } = this.state;
        const value = parseInt(event.target.value) || "";
        teeTime.players = value;
        this.setState({ teeTime });
    }

    onChangeRequestedClub(event, props) {
        const { value } = props;
        const { teeTime } = this.state;
        const club = this.state.golfClubs.find((club) => club._id === value);
        teeTime.choices[0].course = undefined;

        if (typeof club.courses[0] !== "undefined") {
            teeTime.choices[0].course = club.courses[0]._id;
        }

        this.setState({ teeTime, activeChosenClub: club });
    }

    onChangeClubCourse(event, props) {
        const { value } = props;
        const { fourBallCredit } = this.state;
        fourBallCredit.fourBallClubCourse = value;
        this.setState({ fourBallCredit });
    }

    onChangeFourBallCreditPrice(value) {
        const { fourBallCredit } = this.state;
        fourBallCredit.price = value;
        this.setState({ fourBallCredit });
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        if (this.loaded() === false) {
            return <CenterLoader />;
        }

        return (
            <div className="container">
                <h2>Create FOURBALL credit</h2>
                <Form>
                    <Segment vertical>
                        <Form.Field required>
                            {this.errorsFor("member")}
                            <label className="with-input">Member</label>
                            <MemberSearchSelect
                                onChange={this.onChangeMember.bind(this)}
                                value={this.state.fourBallCredit.user ?? ""}
                                active
                                fluid
                            />
                        </Form.Field>
                    </Segment>
                    <Segment vertical>
                        <Form.Field required>
                            <label className="with-input">Club Course</label>
                            <Select
                                search
                                value={
                                    this.state.fourBallCredit.fourBallClubCourse
                                        ? this.state.fourBallCredit
                                              .fourBallClubCourse
                                        : undefined
                                }
                                onChange={this.onChangeClubCourse.bind(this)}
                                options={this.clubCoursesDropdownOptions()}
                            />
                        </Form.Field>
                    </Segment>
                    <Segment vertical>
                        <Form.Field>
                            {this.errorsFor("date")}
                            <label className="with-input">Expires</label>
                            <DayPickerInput
                                name="test"
                                placeholder="Select date"
                                formatDate={formatDate}
                                parseDate={parseDate}
                                component={this.DatePickerInput}
                                format="DD/MM/YYYY"
                                value={moment(
                                    this.state.fourBallCredit.expiresAt
                                ).format("DD/MM/YYYY")}
                                onDayChange={this.onChangeExpiresAtDate.bind(
                                    this
                                )}
                            />
                        </Form.Field>
                    </Segment>
                    <Segment vertical>
                        <Form.Field required>
                            <label className="with-input">Credit type</label>
                            <Select
                                onChange={this.onChangeCreditType.bind(this)}
                                placeholder="Please select a type"
                                options={this.creditTypesDropdownOptions()}
                                value={this.state.fourBallCredit.type}
                            />
                        </Form.Field>
                    </Segment>
                    <Segment vertical>
                        <Form.Field>
                            <label className="with-input">Credit price</label>
                            <NumberInput
                                fluid
                                decimal
                                icon="gbp"
                                iconPosition="left"
                                name="price"
                                placeholder={"Price"}
                                value={this.state.fourBallCredit.price}
                                onChange={(value) =>
                                    this.onChangeFourBallCreditPrice(value)
                                }
                            />
                        </Form.Field>
                    </Segment>
                    <Segment vertical className="text-right">
                        <Button
                            primary
                            className="theme"
                            loading={this.state.saving}
                            disabled={this.canSave()}
                            onClick={this.save.bind(this)}
                        >
                            Save
                        </Button>
                    </Segment>
                </Form>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.handleNotifications()}
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Create);
