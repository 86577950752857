import React, { Component } from "react";
import { Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import Header from "../../../assets/components/header";
import Footer from "../../../assets/components/footer";
import Partial404 from "../../errors/partials/404";
import Partial500 from "../../errors/partials/500";
import Notification from "../../../assets/components/notification";
import NotificationActions from "../../../store/actions/notification";
import FourBallClubCourses from "../../../models/FourBallClubCourses";
import DataTable from "../../../assets/components/dataTable";
import { Link } from "react-router-dom";
import moment from "moment";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            loading: false,
            table: {
                page: 1,
                term: "",
                sort: "createdAt",
                order: "asc",
                limit: 25,
            },
            totalRecords: 0,
            error: null,
            notification: null,
            fourBallClubCourses: [],
        };
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    componentDidMount() {
        this.getFourBallClubCourses(this.state.table);
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleNotifications() {
        if (this.props.notification !== null) {
            return (
                <Notification
                    type={this.props.notification.type}
                    message={this.props.notification.text}
                />
            );
        }
    }

    getFourBallClubCourses(params) {
        this.setState({ loading: true });
        FourBallClubCourses.index(params)
            .then((data) => {
                this.setState({
                    table: params,
                    totalRecords: data.total,
                    fourBallClubCourses: data.courses,
                    loading: false,
                });
            })
            .catch((error) => {
                this.setState({
                    error: error.response.status,
                    loading: false,
                });
                this.notification("error", "Failed to user, please try again");
            });
    }

    deleteClubCourse(clubCourse) {
        const confirm = window.confirm(
            "Are you sure you want to delete this club course? Customers who have credits for this course will no longer be able to book it on the app. Customers will not be able to find it on the app either. Please remove all unused credits from those customers before deleting the course!"
        );
        if (confirm === true) {
            clubCourse
                .delete()
                .then((respose) => {
                    this.notification("success", "Club course deleted");
                    this.props.history.go(0);
                })
                .catch((error) => {
                    this.notification("error", "Failed to delete club course");
                });
        }
    }

    table() {
        const headers = [
            { key: "createdAt", text: "Created on" },
            { key: "clubCourse", text: "Club course" },
            { key: "group", text: "Group" },
            { key: "bookingLeadTime", text: "Booking lead time" },
            { key: "bookingMaxTime", text: "Booking max time" },
        ];

        if (this.props.user.has("MASHIE_ADMIN")) {
            headers.push({ key: "actions", text: "Actions" });
        }

        const data = this.state.fourBallClubCourses.map((clubCourse) => {
            const row = {
                createdAt: {
                    value: moment(clubCourse.createdAt).format(
                        "DD/MM/YYYY, HH:mm"
                    ),
                },
                clubCourse: {
                    value: (
                        <Link
                            to={`/golf-clubs/${clubCourse.clubCourse.club._id}`}
                        >
                            {clubCourse.clubCourse.name} at{" "}
                            {clubCourse.clubCourse.club.name}
                        </Link>
                    ),
                },
                group: {
                    value: (
                        <Link
                            to={`/fourballs/club-course-groups/${clubCourse.group._id}`}
                        >
                            {clubCourse.group.name}
                        </Link>
                    ),
                },
                bookingLeadTime: { value: clubCourse.bookingLeadTime },
                bookingMaxTime: { value: clubCourse.bookingMaxTime },
            };

            if (this.props.user.has("MASHIE_ADMIN")) {
                let actions = [];

                actions.push(
                    <Link to={`/fourballs/club-courses/${clubCourse._id}`}>
                        <Button primary className="theme">
                            View
                        </Button>
                    </Link>
                );

                actions.push(
                    <Button
                        primary
                        className="red"
                        onClick={() => this.deleteClubCourse(clubCourse)}
                        key="delete"
                    >
                        Delete
                    </Button>
                );

                row.actions = { value: actions };
            }

            return row;
        });

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
                onChange={this.getFourBallClubCourses.bind(this)}
                totalRecords={this.state.totalRecords}
                hideSearch={true}
                page={this.state.table.page}
                sort={this.state.table.sort}
                order={this.state.table.order}
                limit={this.state.table.limit}
                sortOptions={[
                    { text: "Creation", key: "createdAt" },
                    { text: "Club course", key: "clubCourse" },
                    { text: "Group", key: "group" },
                ]}
                pagination
            />
        );
    }

    showClubCourses() {
        if (this.state.error === true) {
            return (
                <p className="text-center">
                    Something went wrong. Please try again.
                </p>
            );
        }

        return this.table(this.state.fourBallClubCourses);
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        return (
            <div className="container-fluid" style={{ overflowX: "scroll" }}>
                <div className="row page-header mt-3">
                    <div className="col">
                        <h2>FOURBALL Club Courses</h2>
                    </div>
                    <div className="col text-right">
                        <Link to="/fourballs/club-courses/create">
                            <Button primary className="theme">
                                Create course
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col">{this.showClubCourses()}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.handleNotifications()}
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(Index);
