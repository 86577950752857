import axios from "axios";
const config = require("../config");

class FourBallClubCourses {
    constructor(properties) {
        this.bookingLeadTime = 2;
        this.bookingMaxTime = 365;
        this.prices = [];
        this.roleBlockList = [];
        this.entityBlockList = [];
        Object.assign(this, properties);
    }

    static async index(params) {
        const response = await axios.get(
            config.api_url + "fourball/club-courses",
            {
                params,
            }
        );

        return {
            courses: response.data.data.map(
                (clubCourse) => new FourBallClubCourses(clubCourse)
            ),
            total: response.data.total,
        };
    }

    static async list() {
        const response = await axios.get(
            config.api_url + "fourball/club-courses/list"
        );
        return response.data.data.map(
            (clubCourse) => new FourBallClubCourses(clubCourse)
        );
    }

    static async show(id) {
        const response = await axios.get(
            config.api_url + "fourball/club-courses/" + id
        );
        return new FourBallClubCourses(response.data.data);
    }

    static getFourBallClubCourseAvailability(id) {
        return axios
            .get(
                config.api_url + "fourball/club-courses/" + id + "/availability"
            )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    }

    store() {
        return axios
            .post(config.api_url + "fourball/club-courses", {
                clubCourse: this.clubCourse,
                group: this.group,
                prices: this.prices,
                bookingLeadTime: this.bookingLeadTime,
                bookingMaxTime: this.bookingMaxTime,
                entityBlockList: this.entityBlockList,
                roleBlockList: this.roleBlockList,
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    }

    delete() {
        return axios
            .delete(config.api_url + "fourball/club-courses/" + this._id)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    }

    update() {
        return axios
            .put(config.api_url + "fourball/club-courses/" + this._id, {
                clubCourse: this.clubCourse,
                group: this.group,
                prices: this.prices,
                bookingLeadTime: this.bookingLeadTime,
                bookingMaxTime: this.bookingMaxTime,
                entityBlockList: this.entityBlockList,
                roleBlockList: this.roleBlockList,
                hasSpecialOffer: this.hasSpecialOffer,
                isFeatured: this.isFeatured,
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    }
}

export default FourBallClubCourses;
