import React, { Component } from "react";
import { Input, Segment, Select, Button, Checkbox } from "semantic-ui-react";
import { connect } from "react-redux";
import styles from "./create.module.scss";
import Header from "../../../assets/components/header";
import Footer from "../../../assets/components/footer";
import Partial404 from "../../errors/partials/404";
import Partial500 from "../../errors/partials/500";
import Notification from "../../../assets/components/notification";
import NotificationActions from "../../../store/actions/notification";
import ValidationActions from "../../../store/actions/validation";
import FourBallBooking from "../../../models/FourBallBooking";
import FourBallCredit from "../../../models/FourBallCredits";
import MemberSearchSelect from "../../../assets/components/MemberSearchSelect";
import CenterLoader from "../../../assets/components/CenterLoader";
import FourBallClubCourses from "../../../models/FourBallClubCourses";
import { Link } from "react-router-dom";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

class Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            error: null,
            fourBall: new FourBallBooking({
                players: 4,
                user:
                    new URLSearchParams(window.location.search).get("member") ??
                    null,
            }),
            loadingClubCourses: true,
            clubCourses: [],
            loadingmembers: true,
            members: [],
            loadingUserCredits: false,
            disabledDatesOverride: false,
            userCredits: [],
            loadingClubCourseAvailability: false,
            clubCourseAvailability: [],
            timeslots: [
                "06:00 - 07:00",
                "07:00 - 08:00",
                "08:00 - 09:00",
                "09:00 - 10:00",
                "10:00 - 11:00",
                "11:00 - 12:00",
                "12:00 - 13:00",
                "13:00 - 14:00",
                "14:00 - 15:00",
                "15:00 - 16:00",
                "16:00 - 17:00",
                "17:00 - 18:00",
                "18:00 - 19:00",
                "19:00 - 20:00",
                "20:00 - 21:00",
                "21:00 - 22:00",
            ],
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }
    }

    componentDidMount() {
        this.getClubCourses();
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className="has-error">
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleNotifications() {
        if (this.props.notification !== null) {
            return (
                <Notification
                    type={this.props.notification.type}
                    message={this.props.notification.text}
                />
            );
        }
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    getClubCourses() {
        this.setState({ loading: true });
        FourBallClubCourses.index({ page: 1 })
            .then((clubCourses) => {
                this.setState({
                    loadingClubCourses: false,
                    clubCourses: clubCourses,
                });
            })
            .catch((error) => {
                this.setState({ loadingClubCourses: false, error: true });
                this.notification(
                    "error",
                    "Failed to load club courses, please try again"
                );
            });
    }

    getMemberCredits() {
        this.setState({ loadingUserCredits: true });
        FourBallCredit.userIndex(this.state.fourBall.user, {
            usable: true,
        })
            .then((credits) => {
                this.setState({
                    loadingUserCredits: false,
                    userCredits: credits,
                });
            })
            .catch((error) => {
                this.setState({ loadingUserCredits: false, error: true });
                this.notification(
                    "error",
                    "Failed to load user credits, please try again"
                );
            });
    }

    getClubCourseAvailability(credit) {
        this.setState({ loadingClubCourseAvailability: true });
        FourBallClubCourses.getFourBallClubCourseAvailability(
            credit.fourBallClubCourse._id
        )
            .then((response) => {
                let maxMin = moment(response.data.data.maxMin.max);

                if (
                    "expiresAt" in credit &&
                    maxMin.isAfter(moment(credit.expiresAt))
                ) {
                    maxMin = moment(credit.expiresAt);
                }

                const disabledDates = [
                    {
                        before: moment(response.data.data.maxMin.min).toDate(),
                        after: maxMin.toDate(),
                    },
                    ...response.data.data.unavailableDates.map(
                        (dateString) => new Date(dateString)
                    ),
                ];

                this.setState({
                    loadingClubCourseAvailability: false,
                    clubCourseAvailability: response.data.data,
                    disabledDates,
                });
            })
            .catch(() => {
                this.setState({
                    loadingClubCourseAvailability: false,
                    error: true,
                });
                this.notification(
                    "error",
                    "Failed to load availability, please try again"
                );
            });
    }

    loaded() {
        return (
            this.state.loadingClubCourses === false &&
            this.state.loadingUserCredits === false &&
            this.state.loadingClubCourseAvailability === false
        );
    }

    onChangeMember(value) {
        const { fourBall } = this.state;
        fourBall.user = value;
        fourBall.creditUsed = undefined;
        fourBall.fourBallClubCourse = undefined;
        fourBall.dateRequested = undefined;
        fourBall.timeRequested = undefined;
        this.setState({ fourBall: fourBall });
        this.getMemberCredits();
    }

    onChangeFourBallCredit(event, props) {
        const { fourBall } = this.state;
        const credit = this.state.userCredits.find(
            ({ _id }) => _id === props.value
        );
        fourBall.creditUsed = credit._id;
        fourBall.fourBallClubCourse = credit.fourBallClubCourse._id;
        fourBall.dateRequested = undefined;
        fourBall.timeRequested = undefined;
        this.setState({ fourBall: fourBall });
        this.getClubCourseAvailability(credit);
    }

    listTimeSlots() {
        return this.state.timeslots.map((slot, index) => {
            return { key: index, text: slot, value: slot };
        });
    }

    listCreditsAsOptions() {
        return this.state.userCredits.map((credit, index) => {
            const option = { key: index };
            option.value = credit._id;
            let text = "1 x ";
            if (credit.type == "included") {
                text = text + "complimentary FOURBALL";
            } else {
                text = text + credit.paymentStatus + " FOURBALL";
            }
            text =
                text +
                " for " +
                credit.fourBallClubCourse.clubCourse.name +
                " at " +
                credit.fourBallClubCourse.clubCourse.club.name;
            text =
                text +
                " (" +
                (typeof credit.expiresAt !== "undefined"
                    ? "Expires on " +
                      moment(credit.expiresAt)
                          .utc()
                          .format("dddd, Do MMMM YYYY")
                    : "Does not expire") +
                ")";
            option.text = text;
            return option;
        });
    }

    clubCoursesDropdownOptions() {
        //Only show club courses that the user has available credits for
        return this.state.clubCourses.map((clubCourse, index) => {
            return {
                key: index,
                text:
                    clubCourse.clubCourse.name +
                    " at " +
                    clubCourse.clubCourse.club.name,
                value: clubCourse._id,
            };
        });
    }

    save() {
        this.setState({ saving: true });
        this.state.fourBall
            .store()
            .then((response) => {
                this.notification("success", "Booking created");
                this.props.history.push(
                    "/fourballs/bookings/" + response.data.data._id
                );
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save FOURBALL booking");
                this.handleValidationErrors(error);
            });
    }

    DatePickerInput = React.forwardRef((props, ref) => {
        return <Input ref={ref} {...props} />;
    });

    showUserCredits() {
        if (this.state.fourBall.user !== null) {
            if (this.state.userCredits.length === 0) {
                return (
                    <>
                        <p className="mt-3">
                            This member has no usable credits. Would you like to
                            create a new one?
                        </p>
                        <Link
                            to={`/fourballs/credits/create/?member=${this.state.fourBall.user}`}
                        >
                            <Button primary className="theme">
                                Add credit
                            </Button>
                        </Link>
                    </>
                );
            }
            return (
                <Segment vertical>
                    {this.errorsFor("creditUsed")}
                    <label className="with-input">
                        Please select the FOURBALL credit you wish to use for
                        this user
                    </label>
                    <Select
                        fluid
                        search
                        value={this.state.fourBall.creditUsed}
                        placeholder="Select a FOURBALL credit"
                        onChange={this.onChangeFourBallCredit.bind(this)}
                        options={this.listCreditsAsOptions()}
                    />
                </Segment>
            );
        }
    }

    onChangeDateRequested(selectedDay, modifiers, dayPickerInput) {
        const { fourBall } = this.state;
        fourBall.dateRequested = selectedDay ?? new Date();
        this.setState({ fourBall });
    }

    DatePickerInput = React.forwardRef((props, ref) => {
        return <Input ref={ref} {...props} />;
    });

    onChangeTimeRequested(event, props) {
        const { value } = props;
        const { fourBall } = this.state;
        fourBall.timeRequested = value;
        this.setState({ fourBall });
    }

    onChangeOverrideDisabledDates(value) {
        this.setState({ disabledDatesOverride: value.checked });
    }

    showRequestedDateAndTime() {
        if (
            typeof this.state.fourBall.creditUsed !== "undefined" &&
            this.state.fourBall.creditUsed != "" &&
            typeof this.state.clubCourseAvailability !== "undefined" &&
            this.state.clubCourseAvailability != "" &&
            typeof this.state.disabledDates !== "undefined"
        ) {
            // Only show when a credit has been selected
            // Only show dates and times that are available for this clubCourse's group
            return (
                <>
                    <Segment vertical>
                        <label className="with-input">Date Requested</label>
                        {this.errorsFor("dateRequested")}
                        <DayPickerInput
                            name="test"
                            placeholder="Select date"
                            formatDate={formatDate}
                            parseDate={parseDate}
                            component={this.DatePickerInput}
                            format="DD/MM/YYYY"
                            value={
                                this.state.fourBall.dateRequested
                                    ? new Date(
                                          this.state.fourBall.dateRequested
                                      )
                                    : ""
                            }
                            onDayChange={this.onChangeDateRequested.bind(this)}
                            dayPickerProps={{
                                disabledDays: this.state.disabledDatesOverride
                                    ? []
                                    : this.state.disabledDates,
                            }}
                        />
                        <Checkbox
                            toggle
                            label="Override disabled booking slots"
                            checked={this.state.disabledDatesOverride}
                            onChange={(_, value) =>
                                this.onChangeOverrideDisabledDates(value)
                            }
                            className="ml-3"
                        />
                    </Segment>
                    <Segment vertical>
                        {this.errorsFor("timeRequested")}
                        <label className="with-input">
                            Preferred Time Slot Requested
                        </label>
                        <Select
                            fluid
                            search
                            value={this.state.fourBall.timeRequested}
                            placeholder="Select a preferred time slot"
                            onChange={this.onChangeTimeRequested.bind(this)}
                            options={this.listTimeSlots()}
                        />
                    </Segment>
                </>
            );
        }
    }

    onChangePlayers(event) {
        const { fourBall } = this.state;
        const { value } = event.target;
        fourBall.players = value;
        this.setState({ fourBall });
    }

    showNumberOfPlayers() {
        if (
            typeof this.state.fourBall.dateRequested !== "undefined" &&
            this.state.fourBall.dateRequested != "" &&
            typeof this.state.fourBall.timeRequested !== "undefined" &&
            this.state.fourBall.timeRequested != ""
        ) {
            //Only show when date and time have been chosen and selected
            return (
                <Segment vertical>
                    {this.errorsFor("players")}
                    <label className="with-input">Number of players</label>
                    <Input
                        type="number"
                        min="1"
                        max="4"
                        value={this.state.fourBall.players}
                        onChange={this.onChangePlayers.bind(this)}
                    />
                </Segment>
            );
        }
    }

    showSave() {
        if (
            typeof this.state.fourBall.dateRequested !== "undefined" &&
            this.state.fourBall.dateRequested != "" &&
            typeof this.state.fourBall.timeRequested !== "undefined" &&
            this.state.fourBall.timeRequested != ""
        ) {
            return (
                <Segment vertical className="text-right">
                    <Button
                        primary
                        className="theme"
                        loading={this.state.saving}
                        onClick={this.save.bind(this)}
                    >
                        Save
                    </Button>
                </Segment>
            );
        }
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        if (this.loaded() === false) {
            return <CenterLoader />;
        }

        return (
            <div className="container">
                <div className="row skinny mb-3">
                    <h2>Create a new FOURBALL Booking request</h2>
                </div>
                <div className="row skinny mb-3">
                    <div className="col-md-12">
                        <div className={styles.choice}>
                            <Segment vertical>
                                {this.errorsFor("member")}
                                <label className="with-input">Member</label>
                                <MemberSearchSelect
                                    onChange={this.onChangeMember.bind(this)}
                                    value={this.state.fourBall.user}
                                    active
                                    fluid
                                />
                            </Segment>
                            {this.showUserCredits()}
                            {this.showRequestedDateAndTime()}
                            {this.showNumberOfPlayers()}
                            {this.showSave()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.handleNotifications()}
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Create);
