import { combineReducers } from "redux";
import user from "./user";
import login from "./login";
import register from "./register";
import notification from "./notification";
import validation from "./validation";
import subjectUser from "./subjectUser";
import subjectGolfClub from "./subjectGolfClub";

export default combineReducers({
  user,
  login,
  register,
  notification,
  validation,
  subjectUser,
  subjectGolfClub,
});
