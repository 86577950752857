import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../../assets/components/header";
import Footer from "../../../assets/components/footer";
import DataTable from "../../../assets/components/dataTable";
import Notification from "../../../assets/components/notification";
import NotificationActions from "../../../store/actions/notification";
import { Button } from "semantic-ui-react";
import EntityAdmin from "../../../models/EntityAdmin";

class AdminsIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            admins: [],
            page: 1,
            searchTerm: "",
            totalRecords: 0,
            error: false,
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }
    }

    componentDidMount() {
        this.getAdmins({ page: 1 });
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleNotifications() {
        if (this.props.notification !== null) {
            return (
                <Notification
                    type={this.props.notification.type}
                    message={this.props.notification.text}
                />
            );
        }
    }

    getAdmins(params) {
        this.setState({ loading: true });
        EntityAdmin.index(params)
            .then((data) => {
                this.setState({
                    page: params.page,
                    searchTerm: params.term,
                    totalRecords: data.total,
                    loading: false,
                    admins: data.admins,
                });
            })
            .catch((error) => {
                this.setState({ loading: false, error: true });
                this.notification(
                    "error",
                    "Failed to load admins, please try again"
                );
            });
    }

    table() {
        const headers = [
            { key: "profileImage", text: "Profile image" },
            { key: "name", text: "name" },
            { key: "actions", text: "Actions" },
        ];

        const data = this.state.admins.map((user) => {
            return {
                profileImage: {
                    value: (
                        <img
                            src={user.profileImageUrl}
                            className="profile-logo-sm"
                        />
                    ),
                },
                name: { value: user.name, search: user.name },
                actions: {
                    value: (
                        <Link to={`/admins/${user._id}`}>
                            <Button primary className="theme">
                                Edit
                            </Button>
                        </Link>
                    ),
                },
            };
        });

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
                page={this.state.page}
                totalRecords={this.state.totalRecords}
                onChange={this.getAdmins.bind(this)}
                searchTerm={this.state.searchTerm}
                pagination
            />
        );
    }

    body() {
        return (
            <div className="container-fluid">
                <div className="row page-header mt-3">
                    <div className="col-sm-12 col-md-8">
                        <h2>Entity admins</h2>
                    </div>
                    <div className="col-sm-12 col-md-4 text-right">
                        <Link to="/admins/create">
                            <Button primary className="theme">
                                Add admin
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col">{this.table()}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.handleNotifications()}
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(AdminsIndex);
