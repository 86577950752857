import axios from "axios";
import logoPlaceholder from "../assets/images/camera-placeholder.png";
import EventBooking from "./EventBooking";
import EventReserve from "./EventReserve";
import Member from "./Member";
const config = require("../config");

class Event {
    constructor(properties) {
        this.partnerImages = [];
        this.removePartnerImages = [];
        this.price = 0;
        this.costPerPerson = 0;
        this.spacesLeftTrigger = 3;
        Object.assign(this, properties);
    }

    static async show(id) {
        const { data } = await axios.get(config.api_url + "events/" + id);
        return new Event(data.data);
    }

    static async index(params) {
        const { data } = await axios.get(config.api_url + "events", { params });
        return {
            events: data.data.map((event) => new Event(event)),
            total: data.total,
        };
    }

    static async list(params = {}) {
        const { data } = await axios.get(config.api_url + "events/list", {
            params,
        });
        return data.data.map((event) => new Event(event));
    }

    static indexFuture() {
        return this.index("future");
    }

    static indexHistoric() {
        return this.index("historic");
    }

    static async bookingsIndex(id) {
        const { data } = await axios.get(
            config.api_url + "events/" + id + "/bookings"
        );
        return data.data.map((item) => {
            const booking = new EventBooking(item);
            booking.user = new Member(item.user);
            return booking;
        });
    }

    static async reservations(id) {
        const { data } = await axios.get(
            config.api_url + "events/" + id + "/reservations"
        );
        return data.data.map((item) => {
            const booking = new EventReserve(item);
            booking.user = new Member(item.user);
            return booking;
        });
    }

    async update() {
        const { data } = await axios.put(
            config.api_url + "events/" + this._id,
            this
        );
        return new Event(data.data);
    }

    async updateImage(image) {
        const response = await axios.put(
            config.api_url + "events/" + this._id + "/image",
            {
                image,
            }
        );

        return response.data.data;
    }

    async store() {
        const { data } = await axios.post(config.api_url + "events", this);
        return new Event(data.data);
    }

    async storePartnerImage(image) {
        const response = await axios.post(
            config.api_url + "events/" + this._id + "/partner-images",
            {
                image,
            }
        );

        return response.data.data;
    }

    async updatePartnerImage(imageId, payload) {
        const response = await axios.put(
            config.api_url +
                "events/" +
                this._id +
                "/partner-images/" +
                imageId,
            payload
        );

        return response.data.data;
    }

    deletePartnerImage(imageId) {
        return axios.delete(
            config.api_url + "events/" + this._id + "/partner-images/" + imageId
        );
    }

    delete() {
        return axios
            .delete(config.api_url + "events/" + this._id)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    }

    get imageUrl() {
        if (typeof this.newImage !== "undefined") {
            return this.newImage;
        }

        if (typeof this.image !== "undefined") {
            return config.content_url + "events/images/" + this.image;
        }

        return logoPlaceholder;
    }

    static partnerImage(image) {
        return config.content_url + "events/images/" + image.image;
    }
}

export default Event;
