import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import DataTable from "../../assets/components/dataTable";
import Notification from "../../assets/components/notification";
import { Button, Label } from "semantic-ui-react";
import EventSeries from "../../models/EventSeries";

class EventsSeries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            eventSeries: [],
            table: {
                page: 1,
                term: "",
                sort: "createdAt",
                order: "desc",
                limit: 25,
            },
            totalRecords: 0,
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }
    }

    handleNotifications() {
        if (this.props.notification !== null) {
            return (
                <Notification
                    type={this.props.notification.type}
                    message={this.props.notification.text}
                />
            );
        }
    }

    getEventSeries(params) {
        this.setState({ loading: true });
        EventSeries.index(params)
            .then((data) => {
                this.setState({
                    loading: false,
                    eventSeries: data.series,
                    table: params,
                    totalRecords: data.total,
                });
            })
            .catch(() => {
                this.setState({ loading: false, error: true });
                this.notification(
                    "error",
                    "Failed to load event series, please try again"
                );
            });
    }

    componentDidMount() {
        this.getEventSeries(this.state.table);
    }

    table() {
        const headers = [
            { key: "name", text: "Name", searchable: true },
            { key: "isHidden", text: "Enabled/Disabled" },
            { key: "actions", text: "Actions" },
        ];

        const data = this.state.eventSeries.map((eventSeries) => {
            return {
                name: { value: eventSeries.name, search: eventSeries.name },
                isHidden: {
                    value:
                        eventSeries.isHidden === false ? (
                            <Label color="green">Enabled</Label>
                        ) : (
                            <Label color="red">Disabled</Label>
                        ),
                },
                actions: {
                    value: (
                        <>
                            <Link to={`/events/series/${eventSeries._id}`}>
                                <Button primary className="theme">
                                    View
                                </Button>
                            </Link>
                        </>
                    ),
                },
            };
        });

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
                totalRecords={this.state.totalRecords}
                page={this.state.table.page}
                onChange={this.getEventSeries.bind(this)}
                searchTerm={this.state.table.term}
                sort={this.state.table.sort}
                order={this.state.table.order}
                limit={this.state.table.limit}
                sortOptions={[
                    { text: "Creation", key: "createdAt" },
                    { text: "Enabled", key: "isHidden" },
                ]}
                pagination
            />
        );
    }

    body() {
        return (
            <div className="container-fluid" style={{ overflowX: "scroll" }}>
                <div className="row page-header mt-3">
                    <div className="col-sm-12 col-md-10">
                        <h2>Event Series</h2>
                    </div>
                    <div className="col-sm-12 col-md-2 text-right">
                        <Link to="/events/series/create">
                            <Button primary className="theme">
                                Add series
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col">{this.table()}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.handleNotifications()}
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(EventsSeries);
