import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Label, Button } from "semantic-ui-react";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import DataTable from "../../assets/components/dataTable";
import Notification from "../../assets/components/notification";
import NotificationActions from "../../store/actions/notification";
import Member from "../../models/Member";
import User from "../../models/User";

class TransactionsIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            transactions: [],
            table: {
                page: 1,
                term: "",
                sort: "createdAt",
                order: "asc",
                limit: 25,
            },
            totalRecords: 0,
            notification: null,
            error: false,
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }
    }

    componentDidMount() {
        this.getTransactions(this.state.table);
    }

    getTransactions(params) {
        this.setState({ loading: true });
        Member.getTransactions(params)
            .then((data) => {
                this.setState({
                    loading: false,
                    transactions: data.transactions,
                    table: params,
                    totalRecords: data.total,
                });
            })
            .catch(() => {
                this.setState({ loading: false, error: true });
                this.notification(
                    "error",
                    "Failed to load transactions, please try again"
                );
            });
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleNotifications() {
        if (this.props.notification !== null) {
            return (
                <Notification
                    type={this.props.notification.type}
                    message={this.props.notification.text}
                />
            );
        }
    }

    table() {
        const headers = [
            { key: "createdAt", text: "Date", width: 3 },
            { key: "amount", text: "Amount", width: 2 },
            { key: "note", text: "Note" },
            { key: "user", text: "Member" },
            { key: "claimedBy", text: "Claimed By" },
            { key: "actions", text: "Actions" },
        ];

        const data = this.state.transactions.map((transaction) => {
            const { createdBy } = transaction;

            return {
                createdAt: {
                    value: this.formatDate(transaction.createdAt),
                    search: transaction.createdAt,
                },
                amount: {
                    value: this.getTransactionAmountLabel(transaction.amount),
                    search: transaction.amount,
                },
                note: { value: transaction.note, search: transaction.note },
                user: {
                    value: this.getUserLink(createdBy),
                    search: createdBy.firstName + " " + createdBy.surname,
                },
                claimedBy: { value: this.showClaimLabel(transaction) },
                actions: { value: this.showClaimButton(transaction) },
            };
        });

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
                totalRecords={this.state.totalRecords}
                page={this.state.table.page}
                onChange={this.getTransactions.bind(this)}
                sort={this.state.table.sort}
                order={this.state.table.order}
                limit={this.state.table.limit}
                sortOptions={[
                    { text: "Creation", key: "createdAt" },
                    { text: "Amount", key: "amount" },
                    { text: "Claimed", key: "claimedBy" },
                ]}
                pagination
                hideSearch
            />
        );
    }

    getUserLink(user) {
        const { _id, firstName, surname } = user;
        return <Link to={`/members/${_id}`}>{firstName + " " + surname}</Link>;
    }

    claimTransaction(transaction) {
        const confirm = window.confirm(
            "Are you sure you want to claim this transaction?"
        );
        if (confirm === true) {
            User.claimTransaction(transaction.createdBy._id, transaction._id)
                .then(() => {
                    this.getTransactions(this.state.table);
                    this.notification("success", "Transaction claimed");
                })
                .catch((error) => {
                    this.notification("error", "Failed to claim Transaction");
                });
        }
    }

    showClaimLabel(transaction) {
        if (typeof transaction.claimedBy !== "undefined") {
            return (
                <Label as="a" image>
                    <img
                        src={User.profileImageUrlForUser(transaction.claimedBy)}
                    />
                    Claimed by {transaction.claimedBy.firstName}{" "}
                    {transaction.claimedBy.surname}
                </Label>
            );
        }
    }

    showClaimButton(transaction) {
        if (this.props.user.hasClaimedTransaction(transaction) === false) {
            return (
                <Button
                    primary
                    className="theme"
                    onClick={() => this.claimTransaction(transaction)}
                >
                    Claim
                </Button>
            );
        }
    }

    getTransactionAmountLabel(amount) {
        let labelColor = "blue";
        if (amount > 0) labelColor = "green";
        if (amount < 0) labelColor = "red";

        return <Label color={labelColor}>£ {amount}</Label>;
    }

    formatDate(date) {
        return moment(date).format("DD/MM/YYYY, HH:mm");
    }

    body() {
        return (
            <div className="container-fluid" style={{ overflowX: "scroll" }}>
                <div className="row page-header mt-3">
                    <div className="col">
                        <h2>Credit requests (made from the app)</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col">{this.table()}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.handleNotifications()}
                    {this.body()}
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(TransactionsIndex);
