import React, { Component } from "react";
import { Button } from "semantic-ui-react";

class Management extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
    };
  }

  render() {
    return (
      <>
        <p>Deleted users are recoverable at a later date</p>
        <Button
          primary
          loading={this.state.deleting}
          onClick={this.props.delete}
          className="red"
        >
          Delete user
        </Button>
      </>
    );
  }
}

export default Management;
