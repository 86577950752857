import axios from "axios";
const config = require("../config");

class FourBallClubCourseGroups {
    constructor(properties) {
        this.roleBlockList = [];
        this.entityBlockList = [];
        this.groupFourBallsNumberPerDay = {
            mon: [
                {
                    number: 0,
                },
            ],
            tue: [
                {
                    number: 0,
                },
            ],
            wed: [
                {
                    number: 0,
                },
            ],
            thu: [
                {
                    number: 0,
                },
            ],
            fri: [
                {
                    number: 0,
                },
            ],
            sat: [
                {
                    number: 0,
                },
            ],
            sun: [
                {
                    number: 0,
                },
            ],
        };
        Object.assign(this, properties);
    }

    static async index(params) {
        const response = await axios.get(
            config.api_url + "fourball/club-course-groups",
            { params }
        );
        return {
            groups: response.data.data.map(
                (group) => new FourBallClubCourseGroups(group)
            ),
            total: response.data.total,
        };
    }

    static async list() {
        const response = await axios.get(
            config.api_url + "fourball/club-course-groups/list"
        );
        return response.data.data.map(
            (group) => new FourBallClubCourseGroups(group)
        );
    }

    static async show(id) {
        const response = await axios.get(
            config.api_url + "fourball/club-course-groups/" + id
        );
        return new FourBallClubCourseGroups(response.data.data);
    }

    store() {
        return axios
            .post(config.api_url + "fourball/club-course-groups", {
                name: this.name,
                entityBlockList: this.entityBlockList,
                roleBlockList: this.roleBlockList,
                groupFourBallsNumberPerDay: this.groupFourBallsNumberPerDay,
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    }

    delete() {
        return axios
            .delete(config.api_url + "fourball/club-course-groups/" + this._id)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    }

    update() {
        return axios
            .put(config.api_url + "fourball/club-course-groups/" + this._id, {
                name: this.name,
                entityBlockList: this.entityBlockList,
                roleBlockList: this.roleBlockList,
                groupFourBallsNumberPerDay: this.groupFourBallsNumberPerDay,
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    }
}

export default FourBallClubCourseGroups;
