import axios from "axios";
const config = require("../config");

class GolfCourse {
    constructor(properties) {
        this.name = "";
        this.par = 0;
        this.holes = 0;
        this.yardage = 0;
        this.description = "";
        this.greenFees = {
            summer: {
                highest: null,
                monday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
                tuesday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },

                    club: { am: null, pm: null },
                },
                wednesday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },

                    club: { am: null, pm: null },
                },
                thursday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
                friday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
                saturday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
                sunday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
            },
            winter: {
                highest: null,
                monday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
                tuesday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
                wednesday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
                thursday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
                friday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
                saturday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
                sunday: {
                    country: { am: null, pm: null },
                    links: { am: null, pm: null },
                    club: { am: null, pm: null },
                },
            },
        };

        Object.assign(this, properties);
    }

    static getById(id) {
        return axios.get(config.api_url + "clubs/" + id);
    }

    store(clubId) {
        return axios.post(config.api_url + "clubs/" + clubId + "/courses", {
            name: this.name,
            holes: this.holes,
            par: this.par,
            yardage: this.yardage,
            description: this.description,
            greenFees: this.greenFees,
        });
    }

    update() {
        return axios.put(
            config.api_url + "clubs/" + this.club._id + "/courses/" + this._id,
            {
                name: this.name,
                holes: this.holes,
                par: this.par,
                yardage: this.yardage,
                description: this.description,
                greenFees: this.greenFees,
            }
        );
    }

    delete() {
        return axios.delete(
            config.api_url + "clubs/" + this.club._id + "/courses/" + this._id
        );
    }

    static async list() {
        const { data } = await axios.get(config.api_url + "courses/list");
        return data.data.map((item) => new GolfCourse(item));
    }
}

export default GolfCourse;
