import React, { Component } from "react";
import { Input } from "semantic-ui-react";

class NumberInput extends Component {
    parse(value) {
        const negative = this.props.negative ?? false;
        const decimal = this.props.decimal ?? false;

        if (value == null) return "";
        if (
            value === "-" ||
            value === "" ||
            value.lastIndexOf(".") === value.length - 1
        ) {
            return value;
        }

        const asFloat = parseFloat(value);
        if (isNaN(asFloat)) return "";

        if (negative === false) {
            if (asFloat < 0) {
                return 0 - asFloat;
            }
        }
        if (decimal === false) {
            return parseInt(asFloat);
        }

        return asFloat;
    }

    onBlur(value) {
        const parsed = this.parse(value);
        if (isNaN(parseFloat(parsed))) {
            let onChangeValue = this.props.default;
            if (typeof onChangeValue === "undefined") {
                onChangeValue = 0;
            }
            return this.props.onChange(onChangeValue);
        }
    }

    render() {
        const allowedProps = [
            "key",
            "fluid",
            "value",
            "className",
            "icon",
            "iconPosition",
            "name",
        ];
        const filteredProps = [];
        for (const key in this.props) {
            if (allowedProps.includes(key)) {
                filteredProps[key] = this.props[key];
            }
        }

        return (
            <Input
                onBlur={({ target }) => this.onBlur(target.value)}
                onChange={({ target }) => {
                    this.props.onChange(this.parse(target.value));
                }}
                {...filteredProps}
            />
        );
    }
}

export default NumberInput;
