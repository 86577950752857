import React, { Component } from "react";
import { Label, Button, Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import Header from "../../../assets/components/header";
import Footer from "../../../assets/components/footer";
import Partial404 from "../../errors/partials/404";
import Partial500 from "../../errors/partials/500";
import Notification from "../../../assets/components/notification";
import NotificationActions from "../../../store/actions/notification";
import FourBallCredits from "../../../models/FourBallCredits";
import DataTable from "../../../assets/components/dataTable";
import MemberSearchSelect from "../../../assets/components/MemberSearchSelect";
import { Link } from "react-router-dom";
import moment from "moment";

class Show extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            loading: true,
            table: {
                page: 1,
                term: "",
                sort: "createdAt",
                order: "asc",
                limit: 25,
            },
            filters: {
                usable: null,
                member: null,
            },
            totalRecords: 0,
            error: null,
            notification: null,
            fourBallCredits: [],
        };

        this.statusColours = {
            additional: "#007ab5",
            included: "#ffc53b",
            unused: "#00b5ad",
            used: "#7d2525",
            paid: "#00b5ad",
            unpaid: "#7d2525",
            free: "#00b5ad",
            expired: "#007ab5",
        };

        this.usableOptions = [
            { key: 0, text: "All", value: null },
            { key: 1, text: "Usable", value: true },
            { key: 2, text: "Not usable", value: false },
        ];
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    componentDidMount() {
        this.getCredits(this.state.table);
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleNotifications() {
        if (this.props.notification !== null) {
            return (
                <Notification
                    type={this.props.notification.type}
                    message={this.props.notification.text}
                />
            );
        }
    }

    onChangeFilter(property, value) {
        const { filters, table } = this.state;
        filters[property] = value;
        table.page = 1;
        this.setState({ filters, table });
        this.getCredits(table);
    }

    getCredits(params) {
        this.setState({ loading: true });
        FourBallCredits.index(Object.assign(params, this.state.filters))
            .then((data) => {
                this.setState({
                    table: params,
                    totalRecords: data.total,
                    loading: false,
                    fourBallCredits: data.credits,
                });
            })
            .catch((error) => {
                this.setState({
                    error: error.response.status,
                    loading: false,
                });
                this.notification("error", "Failed to user, please try again");
            });
    }

    showFourBallBookingButton(fourBall) {
        if (fourBall.usedBy) {
            return (
                <Link to={`/fourballs/bookings/${fourBall.usedBy}`}>
                    <Button primary className="theme">
                        View Booking
                    </Button>
                </Link>
            );
        }
    }

    viewFourBallCreditButtons(fourBall) {
        return (
            <>
                <Link to={`/fourballs/credits/${fourBall._id}`}>
                    <Button primary className="theme">
                        View Credit
                    </Button>
                </Link>
            </>
        );
    }

    table() {
        const headers = [
            { key: "createdAt", text: "Created on" },
            { key: "memberName", text: "Member name" },
            { key: "entity", text: "Entity" },
            { key: "clubCourse", text: "Club course" },
            { key: "canBeUsed", text: "Usage status" },
            { key: "paymentStatus", text: "Payment status" },
            { key: "paidAt", text: "Paid on" },
            { key: "invoiceNumber", text: "Invoice no." },
            { key: "type", text: "Credit type" },
            { key: "price", text: "Price" },
            { key: "expiresAt", text: "Expires" },
        ];

        if (this.props.user.has("MASHIE_ADMIN")) {
            headers.push({ key: "actions", text: "Actions" });
        }

        const data = this.state.fourBallCredits.map((credit) => {
            const row = {
                createdAt: {
                    value: moment(credit.createdAt).format("DD/MM/YYYY, HH:mm"),
                    search: moment(credit.createdAt).unix(),
                },
                memberName: {
                    value: (
                        <Link to={`/members/${credit.user._id}`}>
                            {credit.user.name}
                        </Link>
                    ),
                    search: credit.user.name,
                },
                entity: {
                    value: (
                        <Link to={`/entities/${credit.user.entity.entity._id}`}>
                            {credit.user.entity.entity.name}
                        </Link>
                    ),
                    search: credit.user.entity.entity.name,
                },
                clubCourse: {
                    value: (
                        <Link
                            to={`/golf-clubs/${credit.fourBallClubCourse.clubCourse.club._id}`}
                        >
                            {credit.fourBallClubCourse.clubCourse.name} at{" "}
                            {credit.fourBallClubCourse.clubCourse.club.name}
                        </Link>
                    ),
                    search:
                        credit.fourBallClubCourse.clubCourse.name +
                        " at " +
                        credit.fourBallClubCourse.clubCourse.club.name,
                },
                canBeUsed: {
                    value: credit.canBeUsed ? (
                        this.styledFourBallStatus("unused")
                    ) : credit.usedBy ? (
                        <Link to={`/fourballs/bookings/${credit.usedBy}`}>
                            {this.styledFourBallStatus("used")}
                        </Link>
                    ) : (
                        this.styledFourBallStatus("expired")
                    ),
                    search: credit.canBeUsed
                        ? "unused"
                        : credit.usedBy
                        ? "used"
                        : "expired",
                },
                paymentStatus: {
                    value: this.styledFourBallStatus(credit.paymentStatus),
                    search: credit.paymentStatus,
                },
                paidAt: {
                    value: credit.paidAt
                        ? moment(credit.paidAt).format("DD/MM/YYYY, HH:mm")
                        : "-",
                    search: credit.paidAt ? moment(credit.paidAt).unix() : "-",
                },
                invoiceNumber: {
                    value: credit.invoiceNumber,
                },
                type: {
                    value: this.styledFourBallStatus(credit.type),
                    search: credit.type,
                },
                price: { value: "£" + credit.price, search: credit.price },
                expiresAt: {
                    value: credit.expiresAt
                        ? moment(credit.expiresAt).format("DD/MM/YYYY, HH:mm")
                        : "-",
                    search: credit.expiresAt
                        ? moment(credit.expiresAt).unix()
                        : "-",
                },
            };

            if (this.props.user.has("MASHIE_ADMIN")) {
                row.actions = { value: this.viewFourBallCreditButtons(credit) };
            }

            return row;
        });

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
                onChange={this.getCredits.bind(this)}
                totalRecords={this.state.totalRecords}
                hideSearch={true}
                page={this.state.table.page}
                sort={this.state.table.sort}
                order={this.state.table.order}
                limit={this.state.table.limit}
                sortOptions={[
                    { text: "Creation", key: "createdAt" },
                    { text: "Usage status", key: "usedBy" },
                    { text: "Date paid", key: "paidAt" },
                    { text: "Expiration", key: "expiresAt" },
                    { text: "Credit type", key: "type" },
                    { text: "Price", key: "price" },
                    { text: "Member", key: "user" },
                    { text: "Club", key: "fourBallClubCourse" },
                ]}
                pagination
            />
        );
    }

    styledFourBallStatus(status) {
        return (
            <Label
                style={{
                    color: "white",
                    backgroundColor: this.statusColours[status],
                }}
            >
                {status.toUpperCase()}
            </Label>
        );
    }

    showCredits() {
        if (this.state.error === true) {
            return (
                <p className="text-center">
                    Something went wrong. Please try again.
                </p>
            );
        }

        return this.table();
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        return (
            <div className="container-fluid" style={{ overflowX: "scroll" }}>
                <div className="row page-header mt-3">
                    <div className="col">
                        <h2>FOURBALL credits</h2>
                    </div>
                    <div className="col text-right">
                        <Link to="/fourballs/credits/create">
                            <Button primary className="theme">
                                Create credit
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h3>Filters</h3>
                        <Dropdown
                            selection
                            placeholder={"Usable"}
                            options={this.usableOptions}
                            value={this.state.filters.usable}
                            onChange={(_, { value }) =>
                                this.onChangeFilter("usable", value)
                            }
                        />
                        <MemberSearchSelect
                            onChange={(value) =>
                                this.onChangeFilter("member", value)
                            }
                            value={this.state.filters.member}
                            placeholder="Filter by member"
                            className="ml-3"
                        />
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col">{this.showCredits()}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.handleNotifications()}
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(Show);
