import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from '../../assets/components/header';
import Footer from '../../assets/components/footer';
import DataTable from '../../assets/components/dataTable';
import Notification from '../../assets/components/notification';
import NotificationActions from '../../store/actions/notification';
import { Button, Icon, Segment, Label } from 'semantic-ui-react'
import CenterLoader from '../../assets/components/CenterLoader';
import Currency from '../../models/Currency';
const config = require('../../config');

class CurrenciesIndex extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			currencies: [],
			notification: null,
			error: false
		};

		if (this.props.user.has('MASHIE_ADMIN') === false) {
			this.props.history.push('/members');
		}
	}

	componentWillUnmount() {
		this.props.dispatch(NotificationActions.remove());
	}

	notification(type, text) {
		this.props.dispatch(NotificationActions.create({type, text}))
	}

	handleNotifications() {
		if (this.props.notification !== null) {
			return <Notification type={this.props.notification.type} message={this.props.notification.text} />
		}
	}

	getCurrencies() {
        this.setState({loading: true});
		Currency.getCurrencies().then(currencies => {
            this.setState({loading: false, currencies: currencies.data.data.map(currency => new Currency(currency))});
		}).catch(error => {
			this.setState({loading: false, error: true});
			this.notification('error', 'Failed to load currencies, please try again');
		})
	}

	componentDidMount() {
		this.getCurrencies();
	}

	table() {
		const headers = [
            {key: 'name', text: 'Name', searchable: true},
            {key: 'symbol', text: 'Symbol'},
			{key: 'actions', text: 'Actions'}
		];

        const data = this.state.currencies.map(currency => {
			return {
				name: {value: currency.name, search: currency.name},
                symbol: {value: currency.symbol},
				actions: {value: (
					<>
						<Link to={`/currencies/${currency._id}` }><Button primary className="theme">View</Button></Link>
					</>
				)}
			}
		})

		return <DataTable headers={headers} data={data} />
	}

	body() {
		if (this.state.loading === true) {
			return <CenterLoader />;
		}

		return (
			<div className="container-fluid" style={{overflowX:'scroll'}}>
				<div className="row page-header mt-3">
					<div className="col-sm-12 col-md-10">
						<h2>Currencies</h2>
					</div>
					<div className="col-sm-12 col-md-2 text-right">
						<Link to="/currencies/create"><Button primary className="theme">Add currency</Button></Link>
					</div>
				</div>
				<div className="row">
					<div className="col">
						{ this.table() }
					</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<>
				<div className="d-flex main">
					<Header history={this.props.history} />
					{ this.handleNotifications() }
					{ this.body() }
				</div>
				<Footer />
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
		notification: state.notification,
	};
}

export default connect(mapStateToProps)(CurrenciesIndex);
