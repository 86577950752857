import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "../../assets/components/golfClub/style.module.scss";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import GolfClub from "../../models/GolfClub";
import Notification from "../../assets/components/notification";
import NotificationActions from "../../store/actions/notification";
import ValidationActions from "../../store/actions/validation";
import GolfClubDetials from "../../assets/components/golfClub/Details";

class Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            club: new GolfClub(),
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
        this.props.dispatch(ValidationActions.remove());
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleNotifications() {
        if (this.props.notification !== null) {
            return (
                <Notification
                    type={this.props.notification.type}
                    message={this.props.notification.text}
                />
            );
        }
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    update(club) {
        this.setState({ club });
    }

    save() {
        this.setState({ saving: true });
        this.state.club
            .store()
            .then((response) => {
                this.props.history.push("/golf-clubs");
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save club");
                this.handleValidationErrors(error);
            });
    }

    clubDetailsTab() {
        return (
            <div className={styles.mainSegment}>
                <h3>Details</h3>
                <p>Edit the club's details.</p>
                <GolfClubDetials
                    club={this.state.club}
                    update={this.update.bind(this)}
                    save={this.save.bind(this)}
                />
            </div>
        );
    }

    body() {
        return (
            <div className="container">
                <div className="row skinny">
                    <div className="col">{this.clubDetailsTab()}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.handleNotifications()}
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Create);
