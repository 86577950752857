import React, { useEffect, useRef, useState, ReactElement } from "react";
import ReactDOM from "react-dom";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

const render = (status) => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

function MyMapComponent({ center, zoom, style, club, pinMove }) {
  const ref = useRef();

  useEffect(() => {
    const handleDragEnd = (event) => {
      const result = event.latLng.toJSON();
      let latLon = {};
      latLon[0] = { name: 0, value: result.lng };
      latLon[1] = { name: 1, value: result.lat };
      pinMove(latLon);
    };

    var map = new window.google.maps.Map(ref.current, {
      center,
      zoom,
    });

    var markerImage = {
      url: "/mashie-pin.png",
      size: new window.google.maps.Size(23, 40),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(11, 40),
      scaledSize: new window.google.maps.Size(23, 40),
    };

    if (
      typeof club.location !== "undefined" &&
      typeof club.location[0] !== "undefined" &&
      typeof club.location[1] !== "undefined"
    ) {
      var pin = new window.google.maps.LatLng(
        parseFloat(club.location[1]),
        parseFloat(club.location[0])
      );
      var marker = new window.google.maps.Marker({
        position: pin,
        map: map,
        title: club.name,
        icon: markerImage,
        draggable: true,
      });
      marker.addListener("dragend", handleDragEnd);
    } else {
      var pin = new window.google.maps.LatLng(0, 0);
      var marker = new window.google.maps.Marker({
        position: pin,
        map: map,
        title: "Move pin to set",
        icon: markerImage,
        draggable: true,
      });
      marker.addListener("dragend", handleDragEnd);
    }
  });

  return <div ref={ref} id="map" style={style} />;
}

function GolfClubMap({ style, club, pinMove }) {
  const center = {
    lat:
      typeof club.location !== "undefined" &&
      typeof club.location[1] !== "undefined"
        ? parseFloat(club.location[1])
        : 0,
    lng:
      typeof club.location !== "undefined" &&
      typeof club.location[0] !== "undefined"
        ? parseFloat(club.location[0])
        : 0,
  };
  const zoom = 10;

  return (
    <Wrapper apiKey="AIzaSyAs_ascZBHN1UOpg9BvRc0jkqqlIkIPgF8" render={render}>
      <MyMapComponent
        center={center}
        zoom={zoom}
        style={style}
        club={club}
        pinMove={pinMove}
      />
    </Wrapper>
  );
}

export default GolfClubMap;
