import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./show.module.scss";
import Header from "../../../assets/components/header";
import Footer from "../../../assets/components/footer";
import FourBallClubCourseGroups from "../../../models/FourBallClubCourseGroups";
import FourBallBooking from "../../../models/FourBallBooking";
import Partial404 from "../../errors/partials/404";
import Partial500 from "../../errors/partials/500";
import Notification from "../../../assets/components/notification";
import ClubCourseGroupDetails from "../../../assets/components/fourBallClubCourseGroupDetails";
import ClubCourseGroupAvailability from "../../../assets/components/fourBallClubCourseGroupAvailability";
import Role from "../../../models/Role";
import NotificationActions from "../../../store/actions/notification";
import ValidationActions from "../../../store/actions/validation";
import CenterLoader from "../../../assets/components/CenterLoader";
import Calendar from "../../../assets/components/fourBall/Calendar";

class Show extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            loadingClubCourseGroup: true,
            loadingRoles: true,
            error: null,
            group: new FourBallClubCourseGroups(),
            calendarBookings: [],
        };
    }

    componentWillUnmount() {
        this.props.dispatch(ValidationActions.remove());
        this.props.dispatch(NotificationActions.remove());
    }

    componentDidMount() {
        this.getClubCourseGroup();
        this.getCalendarBookings();
        this.getRoles();
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleNotifications() {
        if (this.props.notification !== null) {
            return (
                <Notification
                    type={this.props.notification.type}
                    message={this.props.notification.text}
                />
            );
        }
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    getCalendarBookings(month = new Date().getMonth()) {
        FourBallBooking.getCalendarBookings({
            month,
            group: this.props.match.params.id,
        }).then((calendarBookings) => this.setState({ calendarBookings }));
    }

    getClubCourseGroup() {
        FourBallClubCourseGroups.show(this.props.match.params.id)
            .then((group) => {
                this.setState({ group, loadingClubCourseGroup: false });
            })
            .catch((error) => {
                this.setState({
                    error: error.response.status,
                    loadingClubCourseGroup: false,
                });
                this.notification(
                    "error",
                    "Failed to load club course group, please try again"
                );
            });
    }

    getRoles() {
        Role.getRoles()
            .then((roles) => {
                const exclude = [
                    "MASHIE_ADMIN",
                    "ENTITY_ADMIN",
                    "APP_USER",
                    "ENTITY_CONTACT",
                ];
                roles = roles.data.data.filter(
                    (role) => exclude.includes(role.key) === false
                );
                this.setState({ loadingRoles: false, roles: roles });
            })
            .catch((error) => {
                this.setState({ error: error.response.status });
                this.notification("error", "Failed to user, please try again");
            });
    }

    displayError() {
        if (this.state.error === 404) {
            return <Partial404 />;
        }

        return <Partial500 />;
    }

    update(group) {
        this.setState({ group });
    }

    save() {
        this.setState({ saving: true });
        this.state.group
            .update()
            .then((response) => {
                this.notification("success", "Club course group created");
                this.setState({ saving: false });
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification(
                    "error",
                    "Failed to update club course group"
                );
                this.handleValidationErrors(error);
            });
    }

    loaded() {
        return (
            this.state.loadingRoles === false &&
            this.state.loadingClubCourseGroup === false
        );
    }

    detailsTab() {
        return (
            <div className={styles.mainSegment}>
                <h3>Club course group details</h3>
                <p>Set the club course group's details</p>
                <ClubCourseGroupDetails
                    roles={this.state.roles}
                    update={this.update.bind(this)}
                    group={this.state.group}
                    save={this.save.bind(this)}
                    saving={this.state.saving}
                />
            </div>
        );
    }

    fourballsPerDayTab() {
        return (
            <div className={styles.mainSegment}>
                <h3>Club course group FOURBALLS per day</h3>
                <p>
                    Set how many FOURBALL bookings can be made within this club
                    course group per day{" "}
                </p>
                <ClubCourseGroupAvailability
                    update={this.update.bind(this)}
                    group={this.state.group}
                    save={this.save.bind(this)}
                    saving={this.state.saving}
                />
            </div>
        );
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        if (this.loaded() === false) {
            return <CenterLoader />;
        }

        return (
            <div className="container skinny">
                <h2>FOURBALL Club Course Group</h2>
                <div className="row">
                    <div className="col">
                        <Tabs
                            defaultActiveKey="details"
                            id="uncontrolled-tab-example"
                        >
                            <Tab eventKey="details" title="Details">
                                {this.detailsTab()}
                            </Tab>
                            <Tab
                                eventKey="fourballsPerDay"
                                title="FOURBALLS per day"
                            >
                                {this.fourballsPerDayTab()}
                            </Tab>
                            <Tab eventKey="calendar" title="Calendar">
                                <div className="pt-3">
                                    <Calendar
                                        bookings={this.state.calendarBookings}
                                        onChangeMonth={this.getCalendarBookings.bind(
                                            this
                                        )}
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.handleNotifications()}
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
        subjectFourBallClubCourseGroup: state.subjectFourBallClubCourseGroup,
    };
}

export default connect(mapStateToProps)(Show);
