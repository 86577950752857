import React, { Component } from "react";
import { Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import Header from "../../../assets/components/header";
import Footer from "../../../assets/components/footer";
import Partial404 from "../../errors/partials/404";
import Partial500 from "../../errors/partials/500";
import Notification from "../../../assets/components/notification";
import NotificationActions from "../../../store/actions/notification";
import FourBallClubCourseGroups from "../../../models/FourBallClubCourseGroups";
import DataTable from "../../../assets/components/dataTable";
import { Link } from "react-router-dom";
import moment from "moment";

class Show extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            saving: false,
            table: {
                page: 1,
                term: "",
                sort: "createdAt",
                order: "asc",
                limit: 25,
            },
            totalRecords: 0,
            error: null,
            notification: null,
            fourBallClubCourseGroups: [],
        };
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    componentDidMount() {
        this.getGroups(this.state.table);
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleNotifications() {
        if (this.props.notification !== null) {
            return (
                <Notification
                    type={this.props.notification.type}
                    message={this.props.notification.text}
                />
            );
        }
    }

    getGroups(params) {
        this.setState({ loading: true });
        FourBallClubCourseGroups.index(params)
            .then((data) => {
                this.setState({
                    loading: false,
                    table: params,
                    totalRecords: data.total,
                    fourBallClubCourseGroups: data.groups,
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: error.response.status,
                });
                this.notification("error", "Failed to user, please try again");
            });
    }

    renderClubCourses(clubCourses) {
        const response = [];
        for (const clubCourse in clubCourses) {
            const clubCourseListKey = "clubCourseList " + clubCourse;
            response.push(
                <div key={clubCourseListKey}>
                    <Link
                        to={`/fourballs/club-course/${clubCourses[clubCourse]._id}`}
                    >
                        {clubCourses[clubCourse].clubCourse.name} at{" "}
                        {clubCourses[clubCourse].clubCourse.club.name}
                    </Link>
                </div>
            );
        }
        return response;
    }

    deleteClubCourseGroup(clubCourseGroup) {
        const confirm = window.confirm(
            "Are you sure you want to delete this club course group?"
        );
        if (confirm === true) {
            clubCourseGroup
                .delete()
                .then((respose) => {
                    this.notification("success", "Club course group deleted");
                    this.getGroups({ page: this.state.page });
                })
                .catch((error) => {
                    this.notification(
                        "error",
                        "Failed to delete club course group"
                    );
                });
        }
    }

    table() {
        const headers = [
            { key: "createdAt", text: "Created on" },
            { key: "name", text: "Group name" },
            { key: "courses", text: "Club courses" },
        ];

        if (this.props.user.has("MASHIE_ADMIN")) {
            headers.push({ key: "actions", text: "Actions" });
        }

        const data = this.state.fourBallClubCourseGroups.map(
            (clubCourseGroup, i) => {
                const row = {
                    createdAt: {
                        value: moment(clubCourseGroup.createdAt).format(
                            "DD/MM/YYYY, HH:mm"
                        ),
                    },
                    name: { value: clubCourseGroup.name },
                    courses: {
                        value: this.renderClubCourses(
                            clubCourseGroup.clubCourses
                        ),
                    },
                };

                if (this.props.user.has("MASHIE_ADMIN")) {
                    let actions = [];

                    actions.push(
                        <Link
                            to={`/fourballs/club-course-groups/${clubCourseGroup._id}`}
                            key={"link-" + i}
                        >
                            <Button primary className="theme" key={"view-" + i}>
                                View
                            </Button>
                        </Link>
                    );

                    if (clubCourseGroup.clubCourses.length === 0) {
                        actions.push(
                            <Button
                                primary
                                className="red"
                                onClick={() =>
                                    this.deleteClubCourseGroup(clubCourseGroup)
                                }
                                key={"delete-" + i}
                            >
                                Delete
                            </Button>
                        );
                    }

                    row.actions = { value: actions };
                }

                return row;
            }
        );

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
                onChange={this.getGroups.bind(this)}
                totalRecords={this.state.totalRecords}
                hideSearch={true}
                page={this.state.table.page}
                sort={this.state.table.sort}
                order={this.state.table.order}
                limit={this.state.table.limit}
                sortOptions={[
                    { text: "Creation", key: "createdAt" },
                    { text: "Name", key: "name" },
                ]}
                pagination
            />
        );
    }

    showClubCourseGroups() {
        if (this.state.error === true) {
            return (
                <p className="text-center">
                    Something went wrong. Please try again.
                </p>
            );
        }

        return this.table();
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        return (
            <div className="container-fluid" style={{ overflowX: "scroll" }}>
                <div className="row page-header mt-3">
                    <div className="col">
                        <h2>FOURBALL Club Course groups</h2>
                    </div>
                    <div className="col text-right">
                        <Link to="/fourballs/club-course-groups/create">
                            <Button primary className="theme">
                                Create group
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col">{this.showClubCourseGroups()}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.handleNotifications()}
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(Show);
