import React, {Component} from 'react';
import styles from './notification.module.scss';
import { Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import NotificationActions from '../../store/actions/notification';

class Notification extends Component {
	constructor(props) {
		super(props)
		this.state = {
			dismissed: false
		};
	}

	shouldComponentUpdate() {
		if (this.state.dismissed === true) {
				this.setState({dismissed: false});
		}

		return true;
	}

	hide() {
		this.props.dispatch(NotificationActions.remove());
	}

	render() {
		return (
			<div className={styles.notification + ' ' + styles[this.props.type || 'info']}>
				<div onClick={this.hide.bind(this)} className={styles.dismiss}><Icon name="times" /></div><div className={styles.content}><p>{ this.props.message }</p></div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		notification: state.notification,
	};
}

export default connect(mapStateToProps)(Notification);
