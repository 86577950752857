import React, { Component } from "react";
import DataTable from "../dataTable";
import { connect } from "react-redux";
import moment from "moment";
import {
    Button,
    Form,
    Label,
    TextArea,
    Select,
    Segment,
    Modal,
    Checkbox,
} from "semantic-ui-react";
import { Tabs, Tab } from "react-bootstrap";
import NumberInput from "../../../assets/components/NumberInput";
import styles from "../confirmationBox.module.scss";
import AppVariable from "../../../models/AppVariable";
import FourBallCredits from "../../../models/FourBallCredits";
import NotificationActions from "../../../store/actions/notification";
import transactionStyles from "./transaction.module.scss";
import { Link } from "react-router-dom";

class Transactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newTransaction: null,
            showConfirmationModal: false,
            denominations: [],
            errorText: "",
            fourBallCredits: [],
            denominationSelection: 0,
        };

        this.statusColours = {
            additional: "#007ab5",
            included: "#ffc53b",
            unused: "#00b5ad",
            used: "#7d2525",
            paid: "#00b5ad",
            unpaid: "#7d2525",
            free: "#00b5ad",
            expired: "#007ab5",
        };
    }

    getFourBallCredits(id) {
        FourBallCredits.userIndex(id)
            .then((fourBallCredits) => {
                this.setState({ fourBallCredits });
            })
            .catch((error) => {
                //this.notification('error', 'Something went wrong, please try again');
            });
    }

    onSaveTransaction() {
        this.props
            .save(this.state.newTransaction)
            .then(() => {
                this.setState({
                    showConfirmationModal: false,
                    errorText: "",
                    newTransaction: null,
                    denominationSelection: 0,
                });
                this.props.dispatch(
                    NotificationActions.create({
                        type: "success",
                        text: "Credit added successfully",
                    })
                );
            })
            .catch((error) => {
                this.props.dispatch(
                    NotificationActions.create({
                        type: "error",
                        text: "Something went wrong adding your credit",
                    })
                );
            });
    }

    renderModal() {
        const isPositive = this.state.newTransaction.amount > 0;
        const { amount, freeAmount } = this.state.newTransaction;
        const total = amount + freeAmount + this.props.user.eventBalance;

        return (
            <Modal open={this.state.showConfirmationModal}>
                <Modal.Header>Please confirm the below changes</Modal.Header>
                <Modal.Content>
                    <div className="row">
                        <div className="col text-center">
                            <h2>Old MASHIE credit balance</h2>
                            <h2 className={styles.confirmationBoxTextRed}>
                                £{this.props.user.eventBalance.toFixed(2)}
                            </h2>
                        </div>
                        <div className="col text-center">
                            <h2>Transaction</h2>
                            <h2
                                className={
                                    isPositive
                                        ? styles.confirmationBoxTextGreen
                                        : styles.confirmationBoxTextRed
                                }
                            >
                                {isPositive ? "+" : ""} £
                                {this.state.newTransaction.amount}
                                <br />
                                {this.state.newTransaction.freeAmount > 0 &&
                                    `+ ${this.state.newTransaction.freeAmount} free`}
                            </h2>
                        </div>
                        <div className="col text-center">
                            <h2>&nbsp;</h2>
                            <h2 className={styles.confirmationBoxTextBlack}>
                                =
                            </h2>
                        </div>
                        <div className="col text-center">
                            <h2>New MASHIE credit balance</h2>
                            <h2 className={styles.confirmationBoxTextBlack}>
                                £ {parseFloat(total).toFixed(2)}
                            </h2>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Cancel"
                        onClick={() =>
                            this.setState({ showConfirmationModal: false })
                        }
                    />
                    <Button
                        primary
                        className="theme"
                        content="Confirm"
                        onClick={() => this.onSaveTransaction()}
                    />
                </Modal.Actions>
            </Modal>
        );
    }

    onChangeAmount(value) {
        const { newTransaction } = this.state;
        newTransaction.amount = value;
        newTransaction.freeAmount = 0;
        this.setState({ newTransaction, denominationSelection: 0 });
    }

    onChangeNote(event) {
        const { newTransaction } = this.state;
        newTransaction.note = event.target.value;
        this.setState({ newTransaction });
    }

    editorOrTable() {
        if (this.state.newTransaction !== null) {
            return this.transactionEditor();
        }

        return (
            <div className="row mt-3">
                <div className="col">
                    <Tabs defaultActiveKey="eventTransactions">
                        <Tab eventKey="eventTransactions" title="MASHIE credit">
                            <Button
                                primary
                                className="theme mt-3 mb-3"
                                onClick={this.newTransaction.bind(this)}
                            >
                                New MASHIE credit transaction
                            </Button>
                            {this.eventTransactionsTable()}
                        </Tab>
                        <Tab
                            eventKey="fourBallCredits"
                            title="FOURBALL Credits"
                        >
                            <Link
                                to={`/fourballs/credits/create/?member=${this.props.user._id}`}
                            >
                                <Button primary className="theme mt-3 mb-3">
                                    Add FOURBALL credit
                                </Button>
                            </Link>
                            {this.fourBallCreditsTable()}
                        </Tab>
                        <Tab
                            eventKey="clothingTransactions"
                            title="Clothing (legacy)"
                        >
                            <div className="pt-3">
                                {this.clothingTransactionsTable()}
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        );
    }

    newTransaction() {
        this.setState({
            newTransaction: {
                amount: 0,
                freeAmount: 0,
                note: "",
                isInvoiceable: true,
            },
        });
    }

    cancelNewTransaction() {
        this.setState({
            newTransaction: null,
            showConfirmationModal: false,
            denominationSelection: 0,
        });
    }

    getDenominationsAppVariable() {
        this.setState({ loading: true });
        AppVariable.getByIdOrKey("EVENT_CREDIT_TOPUP_VALUES").then(
            (appVariable) => {
                this.setState({
                    loading: false,
                    denominations: JSON.parse(appVariable.data).options,
                });
            }
        );
    }

    onChangeSelect(value) {
        const { newTransaction } = this.state;
        newTransaction.amount = value;
        for (const amount of this.state.denominations) {
            if (amount.cost === value) {
                newTransaction.freeAmount = amount.free;
            }
        }
        newTransaction.amount += newTransaction.freeAmount;
        this.setState({ newTransaction, denominationSelection: value });
    }

    denominationOptions() {
        const options = this.state.denominations.map((item) => {
            const option = {
                value: item.cost,
                text: "£" + item.cost,
                free: item.free,
            };

            if (item.free > 0) {
                option.text += " + £" + item.free + " free";
            }

            return option;
        });

        options.unshift({ value: 0, text: "Select amount", free: 0 });
        return options;
    }

    componentDidMount() {
        this.getDenominationsAppVariable();
        this.getFourBallCredits(this.props.user._id);
    }

    onChangeIsInvoiceable() {
        const { newTransaction } = this.state;
        newTransaction.isInvoiceable = !newTransaction.isInvoiceable;
        this.setState({ newTransaction });
    }

    transactionEditor() {
        return (
            <>
                <Segment vertical></Segment>
                <Segment vertical>
                    <label className="with-input">Amount</label>
                    <div className={transactionStyles.transactionContainer}>
                        <div className={transactionStyles.transactionWrapper}>
                            <NumberInput
                                negative
                                decimal
                                icon="gbp"
                                iconPosition="left"
                                value={this.state.newTransaction.amount}
                                placeholder="Amount"
                                onChange={(value) => this.onChangeAmount(value)}
                            />
                        </div>
                        <span className={transactionStyles.transactionSpan}>
                            Or
                        </span>
                        <div className={transactionStyles.transactionWrapper}>
                            <Select
                                fluid
                                value={this.state.denominationSelection}
                                options={this.denominationOptions()}
                                placeholder="Select amount"
                                onChange={(_, { value }) =>
                                    this.onChangeSelect(value)
                                }
                            />
                        </div>
                    </div>
                    {this.state.errorText !== "" && (
                        <p className={transactionStyles.transactionError}>
                            {this.state.errorText}
                        </p>
                    )}
                </Segment>
                <Segment vertical>
                    <label className="with-input">Notes</label>
                    <Form>
                        <TextArea
                            value={this.state.newTransaction.note}
                            placeholder="Notes..."
                            onChange={this.onChangeNote.bind(this)}
                        />
                    </Form>
                </Segment>
                <Segment vertical>
                    <Checkbox
                        toggle
                        label="Is Invoiceable"
                        checked={this.state.newTransaction.isInvoiceable}
                        onChange={this.onChangeIsInvoiceable.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    <Button onClick={this.cancelNewTransaction.bind(this)}>
                        Cancel
                    </Button>
                    <Button
                        primary
                        className="theme"
                        disabled={this.state.newTransaction.amount === 0}
                        onClick={() =>
                            this.setState({ showConfirmationModal: true })
                        }
                    >
                        Save
                    </Button>
                </Segment>
                {this.renderModal()}
            </>
        );
    }

    eventTransactionsTable() {
        const headers = [
            { key: "date", text: "Date" },
            { key: "amount", text: "Amount" },
            { key: "balance", text: "Balance" },
            { key: "notes", text: "Notes" },
        ];
        const transactions = this.props.transactions.filter(
            (transaction) => transaction.transactionType === "event"
        );

        const data = [
            {
                date: {
                    value: moment(this.props.user.createdAt).format(
                        "DD/MM/YYYY, HH:mm"
                    ),
                    search: this.props.user.createdAt,
                },
                amount: { value: <Label color="blue">£ 0</Label>, search: 0 },
                balance: { value: <Label color="blue">£ 0</Label>, search: 0 },
                notes: { value: "Initial balance", search: "Initial balance" },
            },
        ];

        transactions.forEach((transaction) => {
            data.push({
                date: {
                    value: moment(transaction.createdAt).format(
                        "DD/MM/YYYY, HH:mm"
                    ),
                    search: transaction.createdAt,
                },
                amount: {
                    value: (
                        <Label color={transaction.amount < 0 ? "red" : "green"}>
                            £ {transaction.amount}
                        </Label>
                    ),
                    search: transaction.amount,
                },
                balance: {
                    value: <Label color="blue">£ {transaction.balance}</Label>,
                    search: transaction.balance,
                },
                notes: { value: transaction.note, search: transaction.note },
            });
        });

        return <DataTable headers={headers} data={data} />;
    }

    styledFourBallStatus(status) {
        return (
            <Label
                style={{
                    color: "white",
                    backgroundColor: this.statusColours[status],
                }}
            >
                {status.toUpperCase()}
            </Label>
        );
    }

    viewFourBallCreditButton(credit) {
        return (
            <Link to={`/fourballs/credits/${credit._id}`}>
                <Button primary className="theme">
                    View
                </Button>
            </Link>
        );
    }

    fourBallCreditsTable(clubCourse) {
        const headers = [
            { key: "clubCourse", text: "Club course" },
            { key: "createdAt", text: "Created on" },
            { key: "canBeUsed", text: "Usage status" },
            { key: "paymentStatus", text: "Payment status" },
            { key: "paidAt", text: "Paid on" },
            { key: "type", text: "Credit type" },
            { key: "price", text: "Price" },
            { key: "expiresAt", text: "Expires" },
            { key: "actions", text: "Actions" },
        ];

        const data = this.state.fourBallCredits.map((credit) => {
            return {
                clubCourse: {
                    value: `${credit.fourBallClubCourse.clubCourse.name} at ${credit.fourBallClubCourse.clubCourse.club.name}`,
                },
                createdAt: {
                    value: moment(credit.createdAt).format("DD/MM/YYYY, HH:mm"),
                },
                canBeUsed: {
                    value: credit.canBeUsed ? (
                        this.styledFourBallStatus("unused")
                    ) : credit.usedBy ? (
                        <Link to={`/fourballs/bookings/${credit.usedBy}`}>
                            {this.styledFourBallStatus("used")}
                        </Link>
                    ) : (
                        this.styledFourBallStatus("expired")
                    ),
                },
                paymentStatus: {
                    value: this.styledFourBallStatus(credit.paymentStatus),
                },
                paidAt: {
                    value: credit.paidAt
                        ? moment(credit.paidAt).format("DD/MM/YYYY, HH:mm")
                        : "-",
                },
                type: { value: this.styledFourBallStatus(credit.type) },
                price: { value: "£" + credit.price },
                expiresAt: {
                    value: credit.expiresAt
                        ? moment(credit.expiresAt).format("DD/MM/YYYY, HH:mm")
                        : "-",
                },
                actions: { value: this.viewFourBallCreditButton(credit) },
            };
        });

        return (
            <DataTable
                headers={headers}
                data={data}
                key={clubCourse}
                hideSearch={true}
            />
        );
    }

    clothingTransactionsTable() {
        const headers = [
            { key: "date", text: "Date" },
            { key: "amount", text: "Amount" },
            { key: "balance", text: "Balance" },
            { key: "notes", text: "Notes" },
        ];
        const transactions = this.props.transactions.filter(
            (transaction) => transaction.transactionType === "clothing"
        );

        const data = [
            {
                date: {
                    value: moment(this.props.user.createdAt).format(
                        "DD/MM/YYYY, HH:mm"
                    ),
                    search: this.props.user.createdAt,
                },
                amount: {
                    value: (
                        <Label color="blue" className="self-center">
                            £ 0
                        </Label>
                    ),
                    search: 0,
                },
                balance: { value: <Label color="blue">£ 0</Label>, search: 0 },
                notes: { value: "Initial balance", search: "Initial balance" },
            },
        ];

        transactions.forEach((transaction) => {
            data.push({
                type: {
                    value: transaction.transactionType.toUpperCase(),
                    search: transaction.transactionType,
                },
                date: {
                    value: moment(transaction.createdAt).format(
                        "DD/MM/YYYY, HH:mm"
                    ),
                    search: transaction.createdAt,
                },
                amount: {
                    value: (
                        <Label color={transaction.amount < 0 ? "red" : "green"}>
                            £ {transaction.amount}
                        </Label>
                    ),
                    search: transaction.amount,
                },
                balance: {
                    value: <Label color="blue">£ {transaction.balance}</Label>,
                    search: transaction.balance,
                },
                notes: { value: transaction.note, search: transaction.note },
            });
        });

        return <DataTable headers={headers} data={data} />;
    }

    render() {
        return <>{this.editorOrTable()}</>;
    }
}

function mapStateToProps(state) {
    return {
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Transactions);
