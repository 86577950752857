import React, { Component } from "react";
import { Input, Segment, Select, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import Partial404 from "../errors/partials/404";
import Partial500 from "../errors/partials/500";
import Notification from "../../assets/components/notification";
import NotificationActions from "../../store/actions/notification";
import ValidationActions from "../../store/actions/validation";
import TeeTimeBooking from "../../models/TeeTimeBooking";
import GolfClub from "../../models/GolfClub";
import MemberSearchSelect from "../../assets/components/MemberSearchSelect";
import GolfClubSearchSelect from "../../assets/components/GolfClubSearchSelect";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

class Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            error: null,
            activeChosenClub: null,
            activeChosenClubCourses: [],
            teeTime: new TeeTimeBooking({
                choices: [{}],
                players: 1,
                poa: true,
                date: new Date(),
            }),
        };

        this.bookingFeePerPerson = 2.5;

        this.timeslots = [
            "06:00 - 07:00",
            "07:00 - 08:00",
            "08:00 - 09:00",
            "09:00 - 10:00",
            "10:00 - 11:00",
            "11:00 - 12:00",
            "12:00 - 13:00",
            "13:00 - 14:00",
            "14:00 - 15:00",
            "15:00 - 16:00",
            "16:00 - 17:00",
            "17:00 - 18:00",
            "18:00 - 19:00",
            "19:00 - 20:00",
            "20:00 - 21:00",
            "21:00 - 22:00",
        ];
    }

    displayError() {
        if (this.state.error === 500) {
            return <Partial500 />;
        }

        if (this.state.error === 404) {
            return <Partial404 />;
        }
    }

    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className="has-error">
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleNotifications() {
        if (this.props.notification !== null) {
            return (
                <Notification
                    type={this.props.notification.type}
                    message={this.props.notification.text}
                />
            );
        }
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.props.dispatch(
                ValidationActions.create(error.response.data.errors)
            );
        }
    }

    timeslotsDropdownOptions() {
        return this.timeslots.map((time, index) => {
            return { key: index, text: time, value: time };
        });
    }

    coursesDropdownOptions() {
        return this.state.activeChosenClubCourses.map((course, index) => {
            return { key: index, text: course.name, value: course._id };
        });
    }

    save() {
        this.setState({ saving: true });
        this.state.teeTime
            .store()
            .then((response) => {
                this.setState({ saving: false });
                this.notification("success", "Tee time request saved");
                this.props.dispatch(ValidationActions.remove());

                return this.props.history.push(
                    "/tee-time-requests/" + response._id
                );
            })
            .catch((error) => {
                this.setState({ saving: false });
                this.notification("error", "Failed to save Tee time request");
                this.handleValidationErrors(error);
            });
    }

    DatePickerInput = React.forwardRef((props, ref) => {
        return <Input ref={ref} {...props} />;
    });

    onChangeMember(value) {
        const { teeTime } = this.state;
        teeTime.user = value;
        this.setState({ teeTime });
    }

    onChangeRequestedTimeslot(event, props) {
        const { value } = props;
        const { teeTime } = this.state;
        teeTime.timeslot = value;
    }

    onChangeRequestedDate(selectedDay, modifiers, dayPickerInput) {
        const { teeTime } = this.state;
        selectedDay.setHours(12);
        teeTime.date = selectedDay;
        this.setState({ teeTime });
    }

    onChangeRequestedPlayers(event) {
        const { teeTime } = this.state;
        const value = parseInt(event.target.value) || "";
        teeTime.players = value;
        this.setState({ teeTime });
    }

    async onChangeRequestedClub(value) {
        const { teeTime } = this.state;
        const activeChosenClubCourses = await new GolfClub({
            _id: value,
        }).coursesIndex({ sort: "name" });
        teeTime.choices[0].course =
            activeChosenClubCourses[0]?._id ?? undefined;
        this.setState({
            teeTime,
            activeChosenClub: value,
            activeChosenClubCourses,
        });
    }

    onChangeRequestedCourse(event, props) {
        const { value } = props;
        const { teeTime } = this.state;
        teeTime.choices[0].course = value;
        this.setState({ teeTime });
    }

    body() {
        if (this.state.error) {
            return this.displayError();
        }

        const course = this.state.teeTime.choices[0].course;

        return (
            <div className="container">
                <h2>Create Tee Time Request</h2>
                <Segment vertical>
                    {this.errorsFor("user")}
                    <label className="with-input">Member</label>
                    <MemberSearchSelect
                        onChange={this.onChangeMember.bind(this)}
                        value={this.state.teeTime.user ?? ""}
                        active
                        fluid
                    />
                </Segment>
                <Segment vertical>
                    <label className="with-input">Club</label>
                    <GolfClubSearchSelect
                        value={this.state.activeChosenClub ?? null}
                        onChange={(value) => this.onChangeRequestedClub(value)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("choices")}
                    <label className="with-input">Course</label>
                    <Select
                        search
                        value={course}
                        onChange={this.onChangeRequestedCourse.bind(this)}
                        options={this.coursesDropdownOptions()}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("players")}
                    <label className="with-input">No. of players</label>
                    <Input
                        placeholder="No. of players"
                        value={this.state.teeTime.players}
                        onChange={this.onChangeRequestedPlayers.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("date")}
                    <label className="with-input">Date</label>
                    <DayPickerInput
                        name="test"
                        placeholder="Select date"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        component={this.DatePickerInput}
                        format="DD/MM/YYYY"
                        value={moment(this.state.teeTime.date).format(
                            "DD/MM/YYYY"
                        )}
                        onDayChange={this.onChangeRequestedDate.bind(this)}
                    />
                </Segment>
                <Segment vertical>
                    {this.errorsFor("timeslot")}
                    <label className="with-input">Time slot</label>
                    <Select
                        onChange={this.onChangeRequestedTimeslot.bind(this)}
                        placeholder="Please select a time slot"
                        options={this.timeslotsDropdownOptions()}
                    />
                </Segment>
                <Segment vertical className="text-right">
                    <Button
                        primary
                        className="theme"
                        loading={this.state.saving}
                        onClick={this.save.bind(this)}
                    >
                        Save
                    </Button>
                </Segment>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.handleNotifications()}
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Create);
