import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import { Button, Segment } from "semantic-ui-react";
import styles from "./create.module.scss";
import Header from "../../../assets/components/header";
import Footer from "../../../assets/components/footer";
import Details from "../../../assets/components/user/Details";
import EmailAddresses from "../../../assets/components/user/EmailAddresses";
import Partial404 from "../../errors/partials/404";
import Partial500 from "../../errors/partials/500";
import EntityAdmin from "../../../models/EntityAdmin";
import Notification from "../../../assets/components/notification";
import NotificationActions from "../../../store/actions/notification";
import ValidationActions from "../../../store/actions/validation";

class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      error: null,
      admin: new EntityAdmin(),
    };
  }

  componentWillUnmount() {
    this.props.dispatch(ValidationActions.remove());
    this.props.dispatch(NotificationActions.remove());
  }

  notification(type, text) {
    this.props.dispatch(NotificationActions.create({ type, text }));
  }

  handleNotifications() {
    if (this.props.notification !== null) {
      return (
        <Notification
          type={this.props.notification.type}
          message={this.props.notification.text}
        />
      );
    }
  }

  handleValidationErrors(error) {
    if (error.response.status === 422) {
      this.props.dispatch(ValidationActions.create(error.response.data.errors));
    }
  }

  update(admin) {
    this.setState({ admin });
  }

  save() {
    this.setState({ saving: true });
    this.state.admin
      .store()
      .then((admin) => {
        this.notification("success", "User saved");
        this.props.history.push("/admins/" + admin._id);
      })
      .catch((error) => {
        this.setState({ saving: false });
        this.notification("error", "Failed to save user");
        this.handleValidationErrors(error);
      });
  }

  displayError() {
    if (this.state.error === 500) {
      return <Partial500 />;
    }

    if (this.state.error === 404) {
      return <Partial404 />;
    }
  }

  body() {
    if (this.state.error) {
      return this.displayError();
    }

    return (
      <div className="container">
        <h2>Create a new admin</h2>
        <div className="row">
          <div className="col">
            <Tabs defaultActiveKey="details" id="uncontrolled-tab-example">
              <Tab eventKey="details" title="Details">
                <div className={styles.mainSegment}>
                  <h3>Details</h3>
                  <p>Edit the user's details.</p>
                  <Details
                    user={this.state.admin}
                    update={this.update.bind(this)}
                  />
                  <EmailAddresses
                    update={this.update.bind(this)}
                    user={this.state.admin}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
        <Segment vertical>
          <Button
            size="large"
            floated="right"
            className="theme"
            primary
            onClick={this.save.bind(this)}
            disabled={this.state.saving}
            loading={this.state.saving}
          >
            Save
          </Button>
        </Segment>
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="d-flex main">
          <Header history={this.props.history} />
          {this.handleNotifications()}
          {this.body()}
        </div>
        <Footer />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    notification: state.notification,
    validation: state.validation,
  };
}

export default connect(mapStateToProps)(Create);
