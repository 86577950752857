import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import DataTable from "../../assets/components/dataTable";
import Notification from "../../assets/components/notification";
import NotificationActions from "../../store/actions/notification";
import { Button, Icon, Segment } from "semantic-ui-react";
import Offer from "../../models/Offer";

class OffersIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            offers: [],
            notification: null,
            error: false,
            table: {
                page: 1,
                term: "",
                sort: "createdAt",
                order: "desc",
                limit: 25,
            },
        };

        if (this.props.user.has("MASHIE_ADMIN") === false) {
            this.props.history.push("/members");
        }
    }

    componentWillUnmount() {
        this.props.dispatch(NotificationActions.remove());
    }

    notification(type, text) {
        this.props.dispatch(NotificationActions.create({ type, text }));
    }

    handleNotifications() {
        if (this.props.notification !== null) {
            return (
                <Notification
                    type={this.props.notification.type}
                    message={this.props.notification.text}
                />
            );
        }
    }

    deleteOffer(id) {
        const confirm = window.confirm(
            "Are you sure you want to remove this partner offer?"
        );
        if (confirm === true) {
            const offer = this.state.offers.find((offer) => offer._id === id);
            offer
                .delete()
                .then((response) => {
                    this.notification("success", "Partner offer deleted");
                    this.getOffers(this.state.table);
                })
                .catch((error) => {
                    this.notification(
                        "error",
                        "Failed to delete partner offer"
                    );
                });
        }
    }

    table() {
        const headers = [
            { key: "logo", text: "Logo" },
            { key: "name", text: "Name", searchable: true },
            { key: "actions", text: "Actions" },
        ];

        const data = this.state.offers.map((offer) => {
            return {
                logo: {
                    value: (
                        <img src={offer.logoUrl} className="profile-logo-sm" />
                    ),
                },
                name: { value: offer.name, search: offer.name },
                actions: {
                    value: (
                        <>
                            <Link to={`/offers/${offer._id}`}>
                                <Button primary className="theme">
                                    View
                                </Button>
                            </Link>
                            <Button
                                primary
                                onClick={() => this.deleteOffer(offer._id)}
                                className="red"
                            >
                                Delete
                            </Button>
                        </>
                    ),
                },
            };
        });

        return (
            <DataTable
                loading={this.state.loading}
                headers={headers}
                data={data}
                totalRecords={this.state.totalRecords}
                page={this.state.table.page}
                onChange={this.getOffers.bind(this)}
                searchTerm={this.state.table.term}
                sort={this.state.table.sort}
                order={this.state.table.order}
                limit={this.state.table.limit}
                sortOptions={[
                    { text: "Creation", key: "createdAt" },
                    { text: "Name", key: "name" },
                ]}
                pagination
            />
        );
    }

    getOffers(params) {
        this.setState({ loading: true });
        Offer.index(params)
            .then((data) => {
                this.setState({
                    loading: false,
                    table: params,
                    offers: data.offers,
                    totalRecords: data.total,
                });
            })
            .catch((error) => {
                this.setState({ loading: false, error: true });
                this.notification(
                    "error",
                    "Failed to load entities, please try again"
                );
            });
    }

    componentDidMount() {
        this.getOffers(this.state.table);
    }

    offers() {
        if (this.state.error === true) {
            return (
                <p className="text-center">
                    Something went wrong. Please try again.
                </p>
            );
        }

        if (this.state.offers.length === 0) {
            return (
                <div className="text-center light-grey">
                    <Icon name="user" size="huge" />
                    <h4>No offers found</h4>
                    <Link to="/offers/create">
                        <Button size="large" primary className="theme">
                            Add offer
                        </Button>
                    </Link>
                </div>
            );
        }

        return (
            <>
                {this.state.offers.map((offer, i) => {
                    return (
                        <Segment vertical key={i}>
                            <div className="row">
                                <div className="col-3 col-sm-2 col-lg-1 align-items-center">
                                    <img
                                        src={offer.logoUrl}
                                        className="profile-logo-sm"
                                    />
                                </div>
                                <div className="col-5 col-sm-6 col-lg-7 d-flex align-items-center">
                                    <h4>{offer.name}</h4>
                                </div>
                                <div className="col-4 col-sm-4 col-lg-4 d-flex align-items-center justify-content-end">
                                    <Link to={`/offers/${offer._id}`}>
                                        <Button primary className="theme">
                                            Edit
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </Segment>
                    );
                })}
            </>
        );
    }

    body() {
        return (
            <div className="container-fluid" style={{ overflowX: "scroll" }}>
                <div className="row page-header mt-3">
                    <div className="col-sm-12 col-md-10">
                        <h2>Offers</h2>
                    </div>
                    <div className="col-sm-12 col-md-2 text-right">
                        <Link to="/offers/create">
                            <Button primary className="theme">
                                Add offer
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col">{this.table()}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.handleNotifications()}
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
    };
}

export default connect(mapStateToProps)(OffersIndex);
