import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import { Button, Segment } from "semantic-ui-react";
import styles from "./index.module.scss";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import Notification from "../../assets/components/notification";
import UserStoreActions from "../../store/actions/user";
import NotificationActions from "../../store/actions/notification";
import ValidationActions from "../../store/actions/validation";
import ImageCropper from "../../assets/components/ImageCropper";
import EmailAddresses from "../../assets/components/user/EmailAddresses";
import Details from "../../assets/components/user/Details";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      user: props.user,
    };
  }

  componentWillUnmount() {
    this.props.dispatch(ValidationActions.remove());
    this.props.dispatch(NotificationActions.remove());
  }

  notification(type, text) {
    this.props.dispatch(NotificationActions.create({ type, text }));
  }

  handleNotifications() {
    if (this.props.notification !== null) {
      return (
        <Notification
          type={this.props.notification.type}
          message={this.props.notification.text}
        />
      );
    }
  }

  handleValidationErrors(error) {
    if (error.response.status === 422) {
      this.props.dispatch(ValidationActions.create(error.response.data.errors));
    }
  }

  onChangeProfileImage(base64ImageString) {
    const { user } = this.state;
    user.updateProfileImage(base64ImageString).then((image) => {
      user.profileImage = image;
      this.setState({ user });
    });
  }

  save() {
    this.setState({ saving: true });
    this.state.user
      .updateProfile()
      .then(() => {
        this.setState({ saving: false });
        this.props.dispatch(ValidationActions.remove());
        this.props.dispatch(UserStoreActions.store(this.state.user));
        this.notification("success", "Changes saved");
      })
      .catch((error) => {
        this.setState({ saving: false });
        this.notification("error", "Failed to save user");
        this.handleValidationErrors(error);
      });
  }

  update(user) {
    this.setState({ user });
  }

  userBasic() {
    return (
      <div className={styles.topSegment + " skinny"}>
        <div className="row skinny">
          <div className="col text-center">
            <img
              className="profile-logo-lg"
              src={this.state.user.profileImageUrl}
            />
            <ImageCropper onChange={this.onChangeProfileImage.bind(this)} />
            <h3>
              {this.state.user.firstName} {this.state.user.surname}
            </h3>
            <p>
              {this.state.user.emailAddresses[0]?.email ??
                "Please set an email address"}
            </p>
          </div>
        </div>
      </div>
    );
  }

  userDetailsTab() {
    return (
      <div className={styles.mainSegment}>
        <h3>Profile</h3>
        <p>Edit your details</p>
        <Details update={this.update.bind(this)} user={this.state.user} />
        <EmailAddresses
          update={this.update.bind(this)}
          user={this.state.user}
        />
      </div>
    );
  }

  body() {
    return (
      <div className="container">
        {this.userBasic()}
        <div className="row skinny">
          <div className="col">
            <Tabs defaultActiveKey="profile">
              <Tab eventKey="profile" title="Profile">
                {this.userDetailsTab()}
              </Tab>
            </Tabs>
            <Segment vertical>
              <Button
                size="large"
                floated="right"
                className="theme"
                primary
                onClick={this.save.bind(this)}
                disabled={this.state.saving}
                loading={this.state.saving}
              >
                Save
              </Button>
            </Segment>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="d-flex main">
          <Header history={this.props.history} />
          {this.handleNotifications()}
          {this.body()}
        </div>
        <Footer />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    notification: state.notification,
    validation: state.validation,
  };
}

export default connect(mapStateToProps)(Settings);
