import axios from "axios";
const config = require("../config");

class FourBallCredits {
    constructor(properties) {
        Object.assign(this, properties);
    }

    static async userIndex(id, params = {}) {
        const response = await axios.get(
            config.api_url + "fourball/users/" + id + "/credits",
            { params }
        );
        return response.data.data.map((credit) => new FourBallCredits(credit));
    }

    static async show(id) {
        const response = await axios.get(
            config.api_url + "fourball/credits/" + id
        );
        return new FourBallCredits(response.data.data);
    }

    static async index(params) {
        const response = await axios.get(config.api_url + "fourball/credits", {
            params,
        });
        return {
            credits: response.data.data.map(
                (credit) => new FourBallCredits(credit)
            ),
            total: response.data.total,
        };
    }

    async update() {
        const response = await axios.put(
            config.api_url + "fourball/credits/" + this._id,
            {
                type: this.type,
                price: this.price,
                invoiceNumber: this.invoiceNumber,
                expiresAt: this.expiresAt,
            }
        );
        return new FourBallCredits(response.data.data);
    }

    paid() {
        return axios.post(
            config.api_url + "fourball/credits/" + this._id + "/paid"
        );
    }

    delete() {
        return axios.delete(
            config.api_url + "fourball/credits/" + this._id,
            this
        );
    }

    async store() {
        const response = await axios.post(
            config.api_url + "fourball/credits/",
            {
                user: this.user,
                fourBallClubCourse: this.fourBallClubCourse,
                type: this.type,
                price: this.price,
                expiresAt: this.expiresAt,
            }
        );
        return new FourBallCredits(response.data.data);
    }
}

export default FourBallCredits;
